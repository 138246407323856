import InputField from "components/channel_management/components/connection_settings_form/setting_field/input";

const NON_CHANGEABLE_FIELDS = ["host_email", "host_name"];

export const HopperHomesInputField = (props) => {
  const isChannelExists = !!props.form.values.id;
  const disabled = isChannelExists && NON_CHANGEABLE_FIELDS.includes(props.field);

  return <InputField disabled={disabled} {...props} />;
};
