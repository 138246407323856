export const buildUnknownMappingOptions = ({ mappingsAsArray, mappingOptions, mappingSettings }) => {
  const mappingOptionsById = mappingOptions.rooms.reduce((roomAcc, room) => {
    roomAcc[room.id] = { ...room };
    roomAcc[room.id].rates = room.rates.reduce((rateAcc, rate) => {
      rateAcc[rate.id] = rate;
      return rateAcc;
    }, {});
    return roomAcc;
  }, {});

  const unknownMappingOptionsById = {};

  Object.entries(mappingSettings.rooms).forEach(([externalRoomTypeCode, roomTypeId]) => {
    if (roomTypeId && !mappingOptionsById[externalRoomTypeCode]) {
      unknownMappingOptionsById[externalRoomTypeCode] = {
        isInvalid: true,
        isRemoved: true,
        id: externalRoomTypeCode,
        title: `${externalRoomTypeCode}`,
        rates: {},
      };
    }
  });

  mappingsAsArray.forEach(({ settings }) => {
    const { room_type_code, rate_plan_code, occupancy } = settings;
    const isOccBasedMapping = typeof occupancy !== "undefined";

    if (!mappingOptionsById[room_type_code]) {
      unknownMappingOptionsById[room_type_code] = {
        isInvalid: true,
        isRemoved: true,
        id: room_type_code,
        title: `${room_type_code}`,
        rates: {},
      };
    }

    if (mappingOptionsById[room_type_code]) {
      if (
        !mappingOptionsById[room_type_code].rates[rate_plan_code]
        || (isOccBasedMapping && !mappingOptionsById[room_type_code].rates[rate_plan_code].occupancies.includes(occupancy))
      ) {
        unknownMappingOptionsById[room_type_code] = unknownMappingOptionsById[room_type_code] || {
          ...mappingOptionsById[room_type_code],
          isInvalid: true,
          rates: {},
        };

        const unknownRate = {
          isInvalid: true,
          isRemoved: true,
          id: rate_plan_code,
          title: `${rate_plan_code}`,
        };

        if (isOccBasedMapping) {
          unknownRate.occupancy = occupancy;
        }

        unknownMappingOptionsById[room_type_code].rates[rate_plan_code] = unknownRate;
      }
    } else if (unknownMappingOptionsById[room_type_code]) {
      const unknownRate = {
        isInvalid: true,
        isRemoved: true,
        id: rate_plan_code,
        title: `${rate_plan_code}`,
      };

      if (isOccBasedMapping) {
        unknownRate.occupancy = occupancy;
      }

      unknownMappingOptionsById[room_type_code].rates[rate_plan_code] = unknownRate;
    }
  });

  const unknownRoomsWithRates = Object.values(unknownMappingOptionsById).map((room) => {
    if (room.rates) {
      room.rates = Object.values(room.rates);
    }

    return room;
  });

  return {
    rooms: unknownRoomsWithRates,
  };
};
