export const buildMappingsPerExternalRoom = (mappingsAsArray) => {
  return mappingsAsArray.reduce((acc, mapping) => {
    const { room_type_code, rate_plan_code, occupancy } = mapping.settings;

    acc[room_type_code] = acc[room_type_code] || {};
    acc[room_type_code][rate_plan_code] = acc[room_type_code][rate_plan_code] || {};

    if (typeof occupancy !== "undefined") {
      acc[room_type_code][rate_plan_code][`occ_${occupancy}`] = mapping;
    } else {
      acc[room_type_code][rate_plan_code] = mapping;
    }

    return acc;
  }, {});
};
