import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import MappingPopover from "components/mapping/mapping_popover";
import RatePlanTitle from "components/rates/title";

import MappingRow from "./mapping_row";
import RatePlanTitleWithId from "./title_with_id";

import styles from "./reverse_mapping_row.module.css";

class ReverseMappingRow extends Component {
  state = {
    loading: false,
  };

  renderRatePlan = (rate, key, className) => {
    const { ratesTree } = this.props;

    const isPropertyDisplayed = ratesTree.length > 1;

    const parentProperty = ratesTree.find((property) => property.id === rate.property_id);
    const parentRoom = parentProperty.children.find((room) => room.id === rate.room_type_id);

    const rateParents = `${isPropertyDisplayed ? `${parentProperty.title}, ` : ""}${
      parentRoom?.title || "unknown room"
    }, `;

    return (
      <span key={key} className={className}>
        {rateParents}
        <RatePlanTitle rate={rate} />
      </span>
    );
  };

  renderMapping = (mappingDefinition, mappedRates, isProcessing) => {
    const { t, isParentProcessing, handleOpenResolveConflictDialog } = this.props;
    const { loading } = this.state;
    const isMappingConflict = this.checkMappedRatesConflicts();
    const isMapped = Boolean(mappedRates.length);

    if (mappedRates.length > 1 && !isMappingConflict) {
      return (
        <span>
          {mappedRates.map((rate, i) => this.renderRatePlan(rate, i, styles.mappingItemRateTitle))}
        </span>
      );
    }

    const rateText = !isMapped
      ? t("channels_page:form:not_mapped")
      : mappedRates.map((rate, i) => this.renderRatePlan(rate, i));

    if (isMapped) {
      return (<span>{rateText}</span>);
    }

    const isDisabled = loading || isProcessing || isParentProcessing;

    const className = classNames(styles.link, {
      [styles.unmapped_link]: !isMapped,
      [styles.disabled_link]: isDisabled,
    });

    return (
      <span>
        <a // eslint-disable-line jsx-a11y/anchor-is-valid
          data-cy="create_mapping_link"
          className={className}
          onClick={() => {
            if (isDisabled) {
              return false;
            }

            if (isMappingConflict) {
              handleOpenResolveConflictDialog(mappingDefinition, mappedRates);
            } else {
              this.handleMappingClick();
            }

            return false;
          }}
        >
          {rateText}
        </a>
      </span>
    );
  };

  checkMappedRatesConflicts = () => {
    const { mapping } = this.props;
    const { mappedRates } = mapping;

    const [firstRate, ...otherRates] = mappedRates;

    const isMappedRatesHasSameProps = otherRates.every((rate) => {
      const isRoomMatches = rate.room_type_id === firstRate.room_type_id;
      const isParentRateMatches = rate.parent_rate_plan_id === firstRate.parent_rate_plan_id;
      const isPropertyMatches = rate.property_id === firstRate.property_id;

      return isRoomMatches && isParentRateMatches && isPropertyMatches;
    });

    return !isMappedRatesHasSameProps;
  };

  renderExternalRateTitle = () => {
    const { mapping } = this.props;

    return <RatePlanTitleWithId rate={mapping} />;
  };

  deleteMapping = (mappingDefinition) => {
    const { handleDeleteMapping } = this.props;

    this.setState({ loading: true });
    return handleDeleteMapping(mappingDefinition).finally(() => this.setState({ loading: false }));
  };

  handleDeleteClick = () => {
    const { mapping } = this.props;

    this.deleteMapping(mapping.mappingDefinition);
  };

  handlePopupDeleteClick = () => {
    const { mappingPopupData, handleClosePopup } = this.props;

    this.deleteMapping(mappingPopupData.mappingDefinition);
    handleClosePopup();
  };

  handleApplyConflictResolve = () => {
    const { handleApplyConflictResolve } = this.props;

    this.setState({ loading: true });
    return handleApplyConflictResolve().finally(() => this.setState({ loading: false }));
  };

  handleApplyMapping = () => {
    const { handleApplyMapping } = this.props;

    this.setState({ loading: true });
    return handleApplyMapping().finally(() => this.setState({ loading: false }));
  };

  handleMappingClick = () => {
    const { mapping, handleOpenMappingDialog } = this.props;

    const { mappedRates, mappingDefinition } = mapping;
    const [mappedRate = null] = mappedRates;

    handleOpenMappingDialog(mappingDefinition, mappedRate);
  };

  renderDeletedRate = () => {
    const { mapping, t } = this.props;
    const { mappedRates } = mapping;
    const rates = mappedRates.map((r) => r.title).join(", ");
    const rate = mappedRates.length !== 0 ? rates : t("channels_page:form:not_mapped");

    return <span>{rate}</span>;
  };

  handlePrimaryOccToggle = () => {
    const { mapping, onPrimaryOccToggle } = this.props;

    this.setState({ loading: true });

    const toggledMappingInfo = mapping.mappedRates[0].mapping;

    const mappingId = toggledMappingInfo.mappingId;

    const toggledMapping = {
      rate_plan_id: toggledMappingInfo.ratePlanId,
      settings: {
        ...toggledMappingInfo.mapping,
      },
    };

    onPrimaryOccToggle(mappingId, toggledMapping)
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;
    const {
      mapping,
      mappingPopupData,
      ratesTree,
      isParentProcessing,
      handleRateChange,
      handleClosePopup,
      handleConflictFormChange,
    } = this.props;
    const { isDeleted, isParentDeleted, mappedRates, mappingKey, mappingDefinition } = mapping;

    const isProcessing = mapping.isProcessing;

    const isDeletedOrParentDeleted = isDeleted || isParentDeleted;
    const isEditMappingFormOpen = !isDeletedOrParentDeleted && mappingPopupData.visible === mappingKey;
    const rates = mappedRates.map((r) => r.title).join(", ");
    const isMappingConflict = this.checkMappedRatesConflicts();
    const isMapped = Boolean(rates.length);
    const isMappingError = isDeletedOrParentDeleted || isMappingConflict;

    const isPrimary = mapping?.mappedRates[0]?.mapping?.mapping?.primary_occ;

    return (
      <MappingRow
        type={mapping.level === 0 ? "room" : "rate"}
        title={this.renderExternalRateTitle()}
        isMapped={isMapped}
        loading={loading || isProcessing}
        disabled={isParentProcessing}
        isError={isMappingError}
        isPrimary={isPrimary}
        onPrimaryOccToggle={this.handlePrimaryOccToggle}
        action={
          <MappingPopover
            visible={isEditMappingFormOpen}
            mappingPopupData={mappingPopupData}
            ratesTree={ratesTree}
            deleteEnabled={isMapped}
            handleRateChange={handleRateChange}
            handleApplyMapping={this.handleApplyMapping}
            handleClosePopup={handleClosePopup}
            handlePopupDeleteClick={this.handlePopupDeleteClick}
            handleApplyConflictResolve={this.handleApplyConflictResolve}
            handleConflictFormChange={handleConflictFormChange}
          >
            {isDeletedOrParentDeleted
              ? this.renderDeletedRate()
              : this.renderMapping(mappingDefinition, mappedRates, isProcessing)}
          </MappingPopover>
        }
        onDelete={this.handleDeleteClick}
      />
    );
  }
}

export default withTranslation()(ReverseMappingRow);
