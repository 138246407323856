import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { channelsApiClient } from "store/api_clients/channels";

import { ChannexDrawerV2 } from "drawers/channex_drawer_v2";

import Loading from "components/loading";
import { showSuccessMessage } from "components/toasts/messages";

import { DefaultRulesForm } from "./default_rules_form";

const useListingDefaultRules = (channelId, listingId) => {
  return useQuery({
    queryKey: ["listing_default_rules", channelId, listingId],
    queryFn: () => channelsApiClient.getDefaultRules(channelId, listingId),
  });
};

const useUpdateListingDefaultRules = (channelId, listingId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => channelsApiClient.updateDefaultRules(channelId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["listing_default_rules", channelId, listingId] });
      showSuccessMessage();
    },
  });
};

export const DefaultRulesDrawer = ({ visible, listing, onClose }) => {
  const { t } = useTranslation();
  const title = t("hopper_homes:headings:default_rules");

  const { channelId } = useParams();
  const { listingId } = listing;
  const defaultRulesQuery = useListingDefaultRules(channelId, listingId);
  const updateDefaultRulesMutation = useUpdateListingDefaultRules(channelId, listingId);

  return (
    <ChannexDrawerV2 title={title} visible={visible} onClose={onClose}>
      {defaultRulesQuery.isLoading && <Loading />}
      {defaultRulesQuery.isLoading || (
        <DefaultRulesForm
          defaultValue={defaultRulesQuery.data}
          isLoading={updateDefaultRulesMutation.isLoading}
          onSubmit={(values) => {
            const params = { listingId, ...values };
            return updateDefaultRulesMutation.mutateAsync(params, {
              onSuccess: onClose,
            });
          }}
        />
      )}
    </ChannexDrawerV2>
  );
};
