import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

import { httpClient } from "./http_client";

const convertListingToApi = (params) => {
  const normalizedParams = {
    ...params,
  };

  if (normalizedParams.photos) {
    normalizedParams.photos = normalizedParams.photos.map(({ url, ...photo }) => ({
      ...photo,
      originalUrl: url,
    }));
  }

  return normalizedParams;
};

const parseListingFromApi = (listing) => {
  const normalizedListing = {
    ...listing,
  };

  if (normalizedListing.photos) {
    normalizedListing.photos = normalizedListing.photos.map(({ originalUrl, ...photo }) => ({
      ...photo,
      url: originalUrl,
    }));
  }

  return normalizedListing;
};

export const channelsApiClient = {
  createHost: async (params) => {
    const body = convertToSnakeCase(params);
    const path = "channels/create_host";

    const response = await httpClient.send("POST", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  createListing: async (channelId, params) => {
    const body = convertToSnakeCase(convertListingToApi(params));
    const path = "channels/{{CHANNEL_ID}}/action/create_listing".replaceAll("{{CHANNEL_ID}}", channelId);

    const response = await httpClient.send("POST", path, body);

    return parseListingFromApi(convertToCamelCase(response.data.listing));
  },

  updateListing: async (channelId, params) => {
    const body = convertToSnakeCase(convertListingToApi(params));
    const path = "channels/{{CHANNEL_ID}}/action/update_listing".replaceAll("{{CHANNEL_ID}}", channelId);

    const response = await httpClient.send("PUT", path, body);

    return parseListingFromApi(convertToCamelCase(response.data.listing));
  },

  listings: async (channelId) => {
    const path = "channels/{{CHANNEL_ID}}/action/all_listings".replaceAll("{{CHANNEL_ID}}", channelId);
    const response = await httpClient.send("GET", path);

    return convertToCamelCase(response.data?.listings || []).map(parseListingFromApi);
  },

  changeListingStatus: async (channelId, params) => {
    const body = convertToSnakeCase(params);
    const path = "channels/{{CHANNEL_ID}}/action/change_listing_status".replaceAll("{{CHANNEL_ID}}", channelId);

    const response = await httpClient.send("POST", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },

  amenities: async (channelId) => {
    const path = "channels/{{CHANNEL_ID}}/action/amenities".replaceAll("{{CHANNEL_ID}}", channelId);

    const response = await httpClient.send("GET", path);

    return response.data?.amenities || [];
  },

  getDefaultRules: async (channelId, listingId) => {
    const path = "channels/{{CHANNEL_ID}}/action/get_default_rules?listing_id={{LISTING_ID}}".replaceAll("{{CHANNEL_ID}}", channelId).replaceAll("{{LISTING_ID}}", listingId);

    const response = await httpClient.send("GET", path);

    return convertToCamelCase(response.data.rules);
  },

  updateDefaultRules: async (channelId, params) => {
    const path = "channels/{{CHANNEL_ID}}/action/update_default_rules".replaceAll("{{CHANNEL_ID}}", channelId);
    const body = convertToSnakeCase(params);

    const response = await httpClient.send("PUT", path, body);

    return convertToCamelCase(response.data);
  },

  getDefaultPricing: async (channelId, listingId) => {
    const path = "channels/{{CHANNEL_ID}}/action/get_default_pricing?listing_id={{LISTING_ID}}".replaceAll("{{CHANNEL_ID}}", channelId).replaceAll("{{LISTING_ID}}", listingId);

    const response = await httpClient.send("GET", path);

    return convertToCamelCase(response.data.pricing);
  },

  updateDefaultPricing: async (channelId, params) => {
    const path = "channels/{{CHANNEL_ID}}/action/update_default_pricing".replaceAll("{{CHANNEL_ID}}", channelId);
    const body = convertToSnakeCase(params);

    const response = await httpClient.send("PUT", path, body);

    return convertToCamelCase(response.data);
  },

  getPricingSettings: async (channelId, listingId) => {
    const path = "channels/{{CHANNEL_ID}}/action/get_pricing_settings?listing_id={{LISTING_ID}}".replaceAll("{{CHANNEL_ID}}", channelId).replaceAll("{{LISTING_ID}}", listingId);

    const response = await httpClient.send("GET", path);

    return convertToCamelCase(response.data.pricing);
  },

  updatePricingSettings: async (channelId, params) => {
    const path = "channels/{{CHANNEL_ID}}/action/update_pricing_settings".replaceAll("{{CHANNEL_ID}}", channelId);
    const body = convertToSnakeCase(params);

    const response = await httpClient.send("PUT", path, body);

    return convertToCamelCase(response.data);
  },

  getChannelAdapterByCode: async (channelCode) => {
    const path = "/channels/adapter?code={{CHANNEL_CODE}}".replaceAll("{{CHANNEL_CODE}}", channelCode);
    const response = await httpClient.send("GET", path);

    return response.data;
  },
};
