import alphabetSort from "utils/alphabet_sort";
import { buildMultiOccupancyRatesList } from "utils/build_multioccupancy_rates_list";

import { buildRatePlansByRoomTypeId } from "../../_common/build_rate_plans_by_room_type_id";
import { buildRoomTypeOptionsByExternalRoomId } from "../../_common/build_room_type_options_by_external_room_id";

const buildMappingItemsPerRoomAndRate = ({
  rooms,
  roomTypeOptionsByExternalRoomId,
  externalRoomsMapping,
  mappingsPerExternalRoom,
  ratePlanByRoomTypeId,
  t,
}) => {
  return [...(rooms || [])]
    .sort(alphabetSort("title"))
    .map((room) => {
      const { rates } = room;

      const sortedRates = Array.isArray(rates) ? rates.sort(alphabetSort("title")) : [];

      return { ...room, rates: sortedRates };
    })
    .reduce((acc, room) => {
      const roomMappingItem = {
        type: "room",
        isInvalid: room.isInvalid,
        isRemoved: room.isRemoved,
        externalRoom: {
          id: room.id,
          title: room.title,
        },
        roomTypes: roomTypeOptionsByExternalRoomId[room.id],
      };

      const roomTypeId = externalRoomsMapping[room.id];
      if (roomTypeId) {
        roomMappingItem.mapping = {
          roomTypeId,
        };
      }

      acc.push(roomMappingItem);

      const isOccBasedMapping = room.rates.some((rate) => typeof rate.occupancies !== "undefined");
      const roomRates = isOccBasedMapping
        ? buildMultiOccupancyRatesList(room.rates, t)
        : room.rates;

      roomRates.forEach((rate) => {
        const parentRoomId = room.id;

        const rateMappingItem = {
          type: "rate",
          isInvalid: rate.isInvalid,
          isRemoved: rate.isRemoved,
          externalRate: {
            ...rate,
            roomTypeCode: parentRoomId,
            isParentRoomMapped: !!roomTypeId,
          },
          ratePlans: [],
        };

        const rateMapping = isOccBasedMapping
          ? mappingsPerExternalRoom?.[room.id]?.[rate.id]?.[`occ_${rate.occupancy}`]
          : mappingsPerExternalRoom?.[room.id]?.[rate.id];

        if (rateMapping) {
          rateMappingItem.mapping = rateMapping;
        }

        const mappedRoomTypeId = externalRoomsMapping[parentRoomId];
        if (mappedRoomTypeId) {
          const mappableRoomRatePlans = ratePlanByRoomTypeId[mappedRoomTypeId] || [];
          rateMappingItem.ratePlans = mappableRoomRatePlans;
        }

        acc.push(rateMappingItem);
      });

      return acc;
    }, []);
};

export const buildMappingItems = ({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t }) => {
  const externalRoomsMapping = mappingSettings.rooms;
  const roomTypeOptionsByExternalRoomId = buildRoomTypeOptionsByExternalRoomId({ mappingOptions, unknownMappingOptions, roomTypes, externalRoomsMapping });
  const ratePlanByRoomTypeId = buildRatePlansByRoomTypeId({ ratePlans });

  const invalidItems = buildMappingItemsPerRoomAndRate({ rooms: unknownMappingOptions.rooms, roomTypeOptionsByExternalRoomId, externalRoomsMapping, mappingsPerExternalRoom, ratePlanByRoomTypeId, t });
  const items = buildMappingItemsPerRoomAndRate({ rooms: mappingOptions.rooms, roomTypeOptionsByExternalRoomId, externalRoomsMapping, mappingsPerExternalRoom, ratePlanByRoomTypeId, t });

  return [
    ...invalidItems,
    ...items,
  ];
};
