import _ from "lodash";

import transformMappingsToArray from "../airbnb_mapping/utils/transform_mappings_to_array";

const getAllMappedOccupanciesForListing = (listingId, mappingsAsArray) => {
  const occupancies = mappingsAsArray
    .filter(({ mapping }) => mapping.listing_id === listingId && mapping.occupancy)
    .map(({ mapping }) => mapping.occupancy);

  return _.uniq(occupancies);
};

const addDeletedOptionsInMappings = (mappingSchema, mappings, mappingOptions) => {
  if (!mappingOptions) {
    return mappingOptions;
  }

  const newMappingOptions = _.cloneDeep(mappingOptions);
  const mappingsAsArray = transformMappingsToArray(mappings);

  mappingsAsArray.forEach(({ mapping }) => {
    Object.entries(mapping).forEach(([paramName, value]) => {
      const optionName = `${paramName}_dictionary`;

      if (!newMappingOptions[optionName]) {
        return;
      }

      const isExists = newMappingOptions[optionName].values.find((v) => v.id === value);

      if (isExists) {
        return;
      }

      const settingTitle = mappingSchema[paramName].title;
      newMappingOptions[optionName].values.push({
        id: value,
        title: `${settingTitle}: ${value} (deleted)`,
        isDeleted: true,
        // TODO: hack to display mapped rate plans with occupancies for deleted listing (only mapped occupancies need to be shown),
        // problem is that we don;t know exact occupancy for deleted listing, but to display
        // deleted mappings for rate params we need to have occupancies that were used for mapping
        occupancies: getAllMappedOccupanciesForListing(mapping.listing_id, mappingsAsArray),
      });
    });
  });

  return newMappingOptions;
};

export default addDeletedOptionsInMappings;
