export const FooterContainer = ({ children }) => (
  <div
    style={{
      padding: "10px 16px",
      borderTop: "1px solid #e8e8e8",
    }}
  >
    {children}
  </div>
);
