import { useTranslation } from "react-i18next";

import { ChannexDrawerV2 } from "drawers/channex_drawer_v2";

import { TaxForm } from "./tax_form";

export const EditDrawer = ({ visible, tax, errors, setValue, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const title = tax?.name
    ? t("common:headings:tax_create")
    : t("common:headings:tax_edit");

  return (
    <ChannexDrawerV2 title={title} visible={visible} onClose={onClose}>
      <TaxForm
        initialValue={tax}
        errors={errors}
        setValue={setValue}
        onSubmit={(values) => {
          onSubmit(values);
          onClose();
        }}
      />
    </ChannexDrawerV2>
  );
};
