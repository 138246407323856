import React, { Component } from "react";
import { Form, Space } from "antd";
import classNames from "classnames";

import getFieldLayout from "../utils/get_field_layout";

import styles from "./styles.module.css";

const FormItem = Form.Item;

class InputGroup extends Component {
  render() {
    const {
      label,
      view,
      children,
      direction,
      className,
      noBottomMargin,
      noLabel,
      ...rest
    } = this.props;

    const formItemLayout = getFieldLayout(view);
    const formItemClassName = classNames(styles.formItem, className, {
      [styles.noBottomMargin]: noBottomMargin,
      [styles.noLabel]: noLabel,
    });

    return (
      <FormItem
        className={formItemClassName}
        label={noLabel ? <span /> : label}
        {...formItemLayout}
        {...rest}
      >
        <Space.Compact block direction={direction}>
          {children}
        </Space.Compact>
      </FormItem>
    );
  }
}

export default InputGroup;
