import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import InputSearch from "components/forms/items/input_search";
import Layouts from "components/layouts";

import EventEmitter from "utils/event_emitter";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import { ListingBookingSettingsDrawer } from "./listing_booking_settings_drawer/listing_booking_settings_drawer";
import { ListingCheckoutTasksDrawer } from "./listing_checkout_tasks_drawer/listing_checkout_tasks_drawer";
import convertMinNightsValue from "./utils/convert_min_nights_value";
import ListingAvailabilityDrawer from "./listing_availability_drawer";
import ListingPriceDrawer from "./listing_price_drawer";
import ListingRow from "./listing_row";
import NoListingPlaceholder from "./no_listing_placeholder";

const { Channels, subscribe } = store;

class AirBnbListing extends Component {
  state = {
    loading: false,
    searchQuery: "",
    filteredListings: [],
    listings: [],
    activeListing: {},
    isPriceDrawerOpen: false,
    isBookingSettingsDrawerOpen: false,
    isAvailabilityDrawerOpen: false,
    isCheckoutTasksDrawerOpen: false,
  };

  listingUpdateSubscription = null;

  componentDidMount() {
    this.setListings();
    this.subscribeToListingUpdates();
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, mappingOptions } = this.props;
    const { listings } = this.state;
    const isRatesChanged = form.values.rate_plans !== prevProps.form.values.rate_plans;
    const isMappingOptionsChanged = prevProps.mappingOptions !== mappingOptions;
    const isListingsChanged = prevState.listings !== listings;

    if (isRatesChanged || isMappingOptionsChanged) {
      this.setListings();
    }

    if (isListingsChanged) {
      this.resetSearch();
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromListingUpdates();
  }

  subscribeToListingUpdates = () => {
    this.listingUpdateSubscription = subscribe("channel_rate_plan:settings_changed", (payload) => {
      const { id: channelRatePlanId } = payload;

      Channels.airbnbGetChannelRatePlan(channelRatePlanId).then((response) => {
        const { listings, activeListing } = this.state;
        const { id, settings } = response.attributes;

        const formattedListing = this.formatListingProperties(settings);

        const updatedListings = listings.map((listing) => {
          if (listing.id !== id) {
            return listing;
          }

          return { ...listing, ...formattedListing };
        });

        const updatedActiveListing = activeListing.id === id ? { ...activeListing, ...formattedListing } : activeListing;

        this.setState({ listings: updatedListings, activeListing: updatedActiveListing });
      });
    });
  };

  unsubscribeFromListingUpdates = () => {
    const { listingUpdateSubscription } = this;

    if (listingUpdateSubscription && listingUpdateSubscription.remove) {
      listingUpdateSubscription.remove();
    }
  };

  handlePublishClick = (listingId) => {
    const { form } = this.props;
    const channelId = form.values.id;

    return Channels.airbnbPublishListing(channelId, listingId)
      .then((response) => this.toggleListingPublishState(listingId, response))
      .catch(this.handleFailedAction);
  };

  handleUnpublishClick = (listingId) => {
    const { form } = this.props;
    const channelId = form.values.id;

    return Channels.airbnbUnpublishListing(channelId, listingId)
      .then((response) => this.toggleListingPublishState(listingId, response))
      .catch(this.handleFailedAction);
  };

  handleFailedAction = (error) => {
    const { details = {} } = error.errors;

    if (details.error === "invalid_token") {
      EventEmitter.trigger("channel_management:invalid_token", details);
    }

    showErrorMessageFromResponse(error);
  };

  toggleListingPublishState = (listingId, response) => {
    const { listings } = this.state;
    const { published } = response;

    const updatedListings = listings.map((listing) => {
      if (listing.listing_id === listingId) {
        return { ...listing, published };
      }

      return listing;
    });

    this.setState({ listings: updatedListings });
  };

  getListingById = (id) => {
    const { listings } = this.state;

    return listings.find(({ listing_id }) => listing_id === id);
  };

  handlePriceClick = (id) => {
    const activeListing = this.getListingById(id);

    this.setState({ activeListing, isPriceDrawerOpen: true });
  };

  handleSyncClick = (listingId) => {
    const { id } = this.props.form.values;

    return Channels.airbnbSyncListing(id, listingId).catch((error) => {
      this.handleFailedAction(error);

      throw error;
    });
  };

  handlePullFutureReservationsClick = (listingId) => {
    const { id } = this.props.form.values;

    return Channels.airbnbPullFutureReservationsPerListing(id, listingId).catch((error) => {
      this.handleFailedAction(error);

      throw error;
    });
  };

  handlePricingUpdate = (pricing_settings) => {
    this.updateActiveListing({ pricing_settings });
  };

  handleAvailabilityClick = (id) => {
    const activeListing = this.getListingById(id);

    this.setState({ activeListing, isAvailabilityDrawerOpen: true });
  };

  handleBookingSettingsClick = (id) => {
    const activeListing = this.getListingById(id);

    this.setState({ activeListing, isBookingSettingsDrawerOpen: true });
  };

  handleBookingSettingsUpdate = (bookingSetting) => {
    this.updateActiveListing({ booking_setting: bookingSetting });
  };

  handleAvailabilityUpdate = (availability_rule) => {
    this.updateActiveListing({ availability_rule });
  };

  handleCheckoutTasksClick = (id) => {
    const activeListing = this.getListingById(id);

    this.setState({ activeListing, isCheckoutTasksDrawerOpen: true });
  };

  updateActiveListing = (updatedData) => {
    const { activeListing, listings } = this.state;
    const updatedListing = { ...activeListing, ...updatedData };

    const updatedListings = listings.map((listing) => {
      if (listing.listing_id === updatedListing.listing_id) {
        return updatedListing;
      }

      return listing;
    });

    this.setState({ listings: updatedListings, activeListing: updatedListing });
  };

  toggleDrawerVisibility = (name) => () => this.setState(({ [name]: isVisible }) => ({ [name]: !isVisible }));

  setListings = () => {
    const { form, mappingOptions } = this.props;
    const { rate_plans } = form.values;

    if (!Array.isArray(rate_plans) || !mappingOptions) {
      return this.setState({ listings: [] });
    }

    const { values = [] } = mappingOptions.listing_id_dictionary || {};

    const ratePlansWithTopLevelListingMappings = rate_plans.filter(({ settings }) => {
      // for one listing can be mapped several airbnb rate plans, but we need only top level mappings info for listing
      // top level mapping have listing_id only and no airbnb_rate_plan_id
      return settings.listing_id && !settings.airbnb_rate_plan_id;
    });

    const listings = ratePlansWithTopLevelListingMappings.map(({ id, settings }) => {
      const { listing_id } = settings;

      const mapping = values.find((listing) => listing.id === listing_id);
      const title = mapping ? mapping.title : "";

      const formattedListing = this.formatListingProperties(settings);

      return { ...formattedListing, title, id };
    });

    return this.setState({ listings });
  };

  formatListingProperties = (listing) => {
    const { availability_rule = {}, ...restListing } = listing;
    const { day_of_week_min_nights: daysOfWeekMinNights } = availability_rule;

    const updatedAvailability = { ...availability_rule };

    if (Array.isArray(daysOfWeekMinNights)) {
      updatedAvailability.day_of_week_min_nights = convertMinNightsValue(daysOfWeekMinNights);
    }

    return { ...restListing, availability_rule: updatedAvailability };
  };

  resetSearch = () => {
    const { listings } = this.state;

    this.setState({ searchQuery: "", filteredListings: [...listings] });
  };

  onSearchChange = (searchQuery) => {
    const { listings } = this.state;
    const formattedQuery = searchQuery.trim().toLowerCase();

    const filteredListings = listings.filter(({ title, listing_id }) => {
      const isTitleMatch = title.toLowerCase().includes(formattedQuery);
      const isListingIdMatch = String(listing_id).toLowerCase().includes(formattedQuery);

      return isTitleMatch || isListingIdMatch;
    });

    this.setState({ searchQuery, filteredListings });
  };

  handleListingRefresh = () => {
    const { onRefresh } = this.props;

    this.setState({ loading: true });

    return onRefresh().finally(() => this.setState({ loading: false }));
  };

  handleListingReload = (listingId) => {
    const { form } = this.props;
    const channelId = form.values.id;

    return Channels.airbnbReloadListing(channelId, listingId)
      .catch(this.handleFailedAction);
  };

  render() {
    const { form } = this.props;
    const {
      loading,
      isPriceDrawerOpen,
      isAvailabilityDrawerOpen,
      isBookingSettingsDrawerOpen,
      isCheckoutTasksDrawerOpen,
      activeListing,
      listings,
      searchQuery,
      filteredListings,
    } = this.state;

    const channelId = form.values.id;

    if (!listings.length) {
      return <NoListingPlaceholder />;
    }

    return (
      <Layouts.WithToolbar
        loading={loading}
        toolbar={
          <InputSearch
            total={listings.length}
            value={searchQuery}
            onChange={this.onSearchChange}
          />
        }
      >
        {filteredListings.map((listing) => (
          <ListingRow
            key={listing.listing_id}
            listing={listing}
            onPublishClick={this.handlePublishClick}
            onUnpublishClick={this.handleUnpublishClick}
            onPriceClick={this.handlePriceClick}
            onAvailabilityClick={this.handleAvailabilityClick}
            onSyncClick={this.handleSyncClick}
            onBookingSettingsClick={this.handleBookingSettingsClick}
            onPullFutureReservationsClick={this.handlePullFutureReservationsClick}
            onReloadClick={this.handleListingReload}
            onCheckoutTasksClick={this.handleCheckoutTasksClick}
          />
        ))}
        <ListingPriceDrawer
          listing={activeListing}
          channelId={channelId}
          visible={isPriceDrawerOpen}
          onPricingUpdate={this.handlePricingUpdate}
          onClose={this.toggleDrawerVisibility("isPriceDrawerOpen")}
        />
        <ListingAvailabilityDrawer
          listing={activeListing}
          channelId={channelId}
          visible={isAvailabilityDrawerOpen}
          onAvailabilityUpdate={this.handleAvailabilityUpdate}
          onClose={this.toggleDrawerVisibility("isAvailabilityDrawerOpen")}
        />
        <ListingBookingSettingsDrawer
          listing={activeListing}
          channelId={channelId}
          visible={isBookingSettingsDrawerOpen}
          onAvailabilityUpdate={this.handleBookingSettingsUpdate}
          onClose={this.toggleDrawerVisibility("isBookingSettingsDrawerOpen")}
        />
        <ListingCheckoutTasksDrawer
          listing={activeListing}
          channelId={channelId}
          visible={isCheckoutTasksDrawerOpen}
          onClose={this.toggleDrawerVisibility("isCheckoutTasksDrawerOpen")}
        />
      </Layouts.WithToolbar>
    );
  }
}

export default withTranslation()(AirBnbListing);
