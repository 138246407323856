import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Select } from "antd";

import eventTypeOptions from "config/constants/event_type_options";

import style from "./title.module.css";

const SELECT_MODE = "multiple";
const ALL_EVENTS_OPTION = eventTypeOptions[0];
const LIST_HEIGHT = 335;

const generateLabel = (t, selectedFilters) => {
  if (selectedFilters.length === 1) {
    return t(`live_feed_events_widget:events:${selectedFilters[0]}`);
  }

  return `${selectedFilters?.length} ${t("live_feed_events_widget:more_events_placeholder")}`;
};

function Title({ handleFilterChange, eventTypes }) {
  const { t } = useTranslation();
  const placeholderForSelect = t("live_feed_events_widget:placeholder_select");

  const optionsList = useMemo(() => {
    return eventTypeOptions.map((item) => (
      <Select.Option key={item} value={item}>
        {t(`live_feed_events_widget:events:${item}`)}
      </Select.Option>
    ));
  }, [t]);

  const maxTagPlaceholder = generateLabel(t, eventTypes);

  const onSelect = useCallback(
    (val) => {
      let values = eventTypes;
      const allValues = eventTypes.length === eventTypeOptions.filter((i) => i !== ALL_EVENTS_OPTION).length - 1;

      if (val === ALL_EVENTS_OPTION || allValues) {
        values = [ALL_EVENTS_OPTION];
      } else {
        values = values.concat(val).filter((i) => i !== ALL_EVENTS_OPTION);
      }

      return handleFilterChange(values);
    },
    [handleFilterChange, eventTypes],
  );

  const onDeselect = useCallback(
    (val) => {
      let values = eventTypeOptions;
      if (val === ALL_EVENTS_OPTION) {
        values = [ALL_EVENTS_OPTION];
      } else {
        values = eventTypes.filter((i) => i !== val && i !== ALL_EVENTS_OPTION);
      }

      return handleFilterChange(values);
    },
    [handleFilterChange, eventTypes],
  );

  return (
    <div className={style.widgetHeader}>
      <div className={style.widgetHeader__title}>{t("live_feed_events_widget:header")}</div>
      <div className={style.selectWrapper}>
        <p className={style.label}>{t("live_feed_events_widget:filter_label")}:</p>

        <Select
          mode={SELECT_MODE}
          showSearch={false}
          virtual={false}
          value={eventTypes}
          placeholder={placeholderForSelect}
          maxTagCount={0}
          className={style.select}
          popupClassName={style.selectDropDown}
          maxTagPlaceholder={maxTagPlaceholder}
          onSelect={onSelect}
          listHeight={LIST_HEIGHT}
          onDeselect={onDeselect}
        >
          {optionsList}
        </Select>
      </div>
    </div>
  );
}

Title.propTypes = {
  eventTypes: PropTypes.array,
  handleFilterChange: PropTypes.func,
};

export default Title;
