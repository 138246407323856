import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { channelsApiClient } from "store/api_clients/channels";

import { ChannexDrawerV2 } from "drawers/channex_drawer_v2";

import { ListingForm } from "./listing_form";

export const ManageListingDrawer = ({ listing, visible, onClose }) => {
  const { t } = useTranslation();

  const title = listing ? t("listings:headings:update_listing") : t("listings:headings:create_listing");

  const { channelId } = useParams();
  const createListingMutation = useMutation((values) => channelsApiClient.createListing(channelId, values));
  const updateListingMutation = useMutation((values) => channelsApiClient.updateListing(channelId, values));
  const queryClient = useQueryClient();

  return (
    <ChannexDrawerV2 title={title} visible={visible} onClose={onClose}>
      <ListingForm
        channelId={channelId}
        listing={listing}
        isLoading={createListingMutation.isLoading || updateListingMutation.isLoading}
        onSubmit={(values) => {
          const action = values.listingId ? updateListingMutation.mutateAsync : createListingMutation.mutateAsync;

          return action(values, {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: ["listings", channelId] });

              // current api doesn't return the created/updated listing, so we pass the form values
              onClose(values);
            },
          });
        }}
      />
    </ChannexDrawerV2>
  );
};
