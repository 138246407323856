const buildMappingItemForListing = (listing, properties, roomTypes, ratePlans, mappingsPerListingId) => {
  const listingMappingItem = {
    listing,
    properties,
    roomTypes,
    ratePlans,
    isInvalid: listing.isInvalid,
    isRemoved: listing.isRemoved,
  };

  const listingMapping = mappingsPerListingId?.[listing.id];

  if (listingMapping) {
    listingMappingItem.mapping = listingMapping;
  }

  return listingMappingItem;
};

export const buildMappingItems = ({ mappingOptions, unknownMappingOptions, mappingsPerListingId, properties, roomTypes, ratePlans }) => {
  const unknown = (unknownMappingOptions?.listing_id_dictionary?.values || [])
    // .sort(alphabetSort("title"))
    .map((listing) => buildMappingItemForListing(listing, properties, roomTypes, ratePlans, mappingsPerListingId));

  const items = ([...(mappingOptions?.listing_id_dictionary?.values || [])])
    // .sort(alphabetSort("title"))
    .map((listing) => buildMappingItemForListing(listing, properties, roomTypes, ratePlans, mappingsPerListingId));

  return [
    ...unknown,
    ...items,
  ];
};
