import React, { useCallback, useMemo } from "react";

import MappingRow from "components/mapping/mapping_row";
import RoomTitle from "components/rooms/room_title";

import MappingSelect from "./mapping_select";
import RoomRatesMapping from "./room_rates_mapping";

import roomStyles from "./room_mapping.module.css";

const RoomMapping = ({
  room,
  roomTypes,
  mappingSettings,
  unmappedRooms,
  mappings,
  automapper,
  ratePlans,
  isOccupancyBased,
  onChangeMapping,
  onChangeSettings,
  onAddMapping,
}) => {
  const activeRoom = mappingSettings.rooms?.[room.id];

  const roomOptions = useMemo(() => {
    const currentRoomOption = roomTypes.find((roomType) => roomType.id === activeRoom);

    const rawRoomOptions = currentRoomOption
      ? [currentRoomOption, ...unmappedRooms]
      : unmappedRooms;

    return rawRoomOptions.map((roomOption) => ({
      ...roomOption,
      occupancy: roomOption.default_occupancy,
    }));
  }, [activeRoom, roomTypes, unmappedRooms]);

  const clearRoomRelatedRates = useCallback((roomId) => {
    const updatedMappings = Object.keys(mappings).reduce((acc, mappingId) => {
      const mappingArray = mappings[mappingId] || [];
      acc[mappingId] = mappingArray.map((mapping) => (mapping && mapping.room_type_code === roomId ? null : mapping));
      return acc;
    }, {});

    onChangeMapping(updatedMappings);
  }, [mappings, onChangeMapping]);

  const handleRoomMappingChange = useCallback((roomTypeId) => {
    const roomsSettings = {
      ...(mappingSettings.rooms || {}),
      [room.id]: roomTypeId,
    };

    if (roomsSettings[room.id] !== mappingSettings.rooms?.[room.id]) {
      clearRoomRelatedRates(room.id);
    }

    onChangeSettings({
      ...mappingSettings,
      rooms: roomsSettings,
    });
  }, [room.id, mappingSettings, clearRoomRelatedRates, onChangeSettings]);

  const handleRoomMappingDelete = useCallback(() => {
    const rooms = {
      ...(mappingSettings.rooms || {}),
      [room.id]: null,
    };

    onChangeSettings({
      ...mappingSettings,
      rooms,
    });

    clearRoomRelatedRates(room.id);
  }, [room.id, mappingSettings, clearRoomRelatedRates, onChangeSettings]);

  const roomTypeMapping = useMemo(() => (
    <MappingRow
      isMapped={activeRoom}
      title={<RoomTitle room={room} />}
      action={<MappingSelect
        value={activeRoom}
        options={roomOptions}
        onChange={handleRoomMappingChange}
      />}
      onDelete={handleRoomMappingDelete}
    />
  ), [activeRoom, room, roomOptions, handleRoomMappingDelete, handleRoomMappingChange]);

  return (
    <div className={roomStyles.roomRowContainer}>
      {roomTypeMapping}
      <RoomRatesMapping
        room={room}
        ratePlans={ratePlans}
        mappings={mappings}
        isOccupancyBased={isOccupancyBased}
        mappingSettings={mappingSettings}
        onChangeMapping={onChangeMapping}
        onAddMapping={onAddMapping}
        automapper={automapper}
      />
    </div>
  );
};

export default RoomMapping;
