import _ from "lodash";

import { getAntdFieldProps } from "./antd_form_field";
import { getHookFormInputProps } from "./hook_form_input";

export const parseProps = (props) => {
  const hookFormInputProps = getHookFormInputProps(props);
  const antdFormFieldProps = getAntdFieldProps(props);

  const sharedProps = {
    name: props.name,
  };

  const inputProps = _.omit(props, [...Object.keys(hookFormInputProps), ...Object.keys(antdFormFieldProps)]);

  return {
    sharedProps,
    hookFormInputProps,
    antdFormFieldProps,
    inputProps,
  };
};
