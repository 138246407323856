import _ from "lodash";

export const nullifyEmptyValues = (obj, nullifyFields, parentField) => {
  if (_.isPlainObject(obj)) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const currentField = parentField ? `${parentField}.${key}` : key;

      return {
        ...acc,
        [key]: nullifyEmptyValues(value, nullifyFields, currentField),
      };
    }, {});
  }

  if (Array.isArray(obj)) {
    return obj.map((item, index) => {
      const currentField = parentField ? `${parentField}.${index}` : index;

      return nullifyEmptyValues(item, nullifyFields, currentField);
    });
  }

  if (obj === "" || obj === undefined) {
    return null;
  }

  return obj;
};
