import { Flex } from "antd";

import styles from "./styles.module.css";

export const InlineCheckboxLabel = ({ label, children }) => {
  return (
    <label>
      <Flex>
        {children}
        <div className={styles.labelText}>{label}</div>
      </Flex>
    </label>
  );
};
