export default function classifyApiErrors(errors, formFieldNames) {
  const formErrors = {};
  const globalErrors = {};

  Object.entries(errors).forEach(([key, value]) => {
    const fullMatch = formFieldNames.includes(key);

    // This is a subform match, e.g. "address.street" or "address.0.city" should be matched to "address"
    // address is a name for a subform, so we should match all fields that start with "address.", this
    // probably it would be better
    const subFormName = key.split(".")[0];
    const subFormMatch = formFieldNames.includes(subFormName);

    if (fullMatch || subFormMatch) {
      formErrors[key] = value;
    } else {
      globalErrors[key] = value;
    }
  });

  return { formErrors, globalErrors };
}
