import { forwardRef } from "react";
import { Select as AntSelect } from "antd";

import { AntdFormField } from "./_base/antd_form_field";
import { HookFormInput } from "./_base/hook_form_input";
import { parseProps } from "./_base/parse_props";

export const Select = forwardRef((props, ref) => {
  const {
    showSearch = true,
  } = props;

  return (
    <AntSelect
      showSearch={showSearch}
      {...props}
      ref={ref}
    />
  );
});

export const FormSelect = forwardRef((props, ref) => {
  const { sharedProps, hookFormInputProps, antdFormFieldProps, inputProps } = parseProps(props);

  return (
    <HookFormInput
      {...hookFormInputProps}
      {...sharedProps}
      render={({ input, field }) => (
        <AntdFormField
          {...sharedProps}
          {...field}
          {...antdFormFieldProps}
        >
          <Select
            {...inputProps}
            {...input}
            ref={ref}
            onChange={(e) => {
              input.onChange(e === undefined ? null : e);
            }}
          />
        </AntdFormField>
      )}
    />
  );
});
