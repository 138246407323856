import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  additionalRequests: yup.object().shape({
    isPresent: yup.boolean(),
    taskDetail: yup.string().trim()
      .when("isPresent", {
        is: true,
        then: () => yup.string().required(errorMessages.required),
      }),
  }),
});
