import alphabetSort from "utils/alphabet_sort";

export default [
  { representation: "Abode Connect", value: "AbodeConnect" },
  { representation: "Advertising Online", value: "AdvertisingOnline" },
  { representation: "Agoda", value: "Agoda" },
  { representation: "Airbnb", value: "AirBNB" },
  { representation: "Bakuun", value: "Bakuun" },
  { representation: "Booking.com", value: "BookingCom" },
  { representation: "CTrip", value: "CTrip" },
  { representation: "CultBooking", value: "CultBooking" },
  { representation: "Despegar", value: "Despegar" },
  { representation: "Expedia", value: "Expedia" },
  { representation: "Google Hotel Search", value: "GoogleHotelARI" },
  { representation: "Hey Iceland", value: "HeyIceland" },
  { representation: "Hostelworld", value: "Hostelworld" },
  { representation: "Hotelbeds", value: "Hotelbeds" },
  { representation: "HyperGuest", value: "HyperGuest" },
  { representation: "Inntopia", value: "Inntopia" },
  { representation: "LocalOTA", value: "LocalOTA" },
  { representation: "MemberButton", value: "MemberButton" },
  { representation: "Helping Hotels", value: "HelpingHotels" },
  { representation: "Open Channel", value: "OpenChannel" },
  { representation: "Open Shopping API", value: "OpenShopping" },
  { representation: "OpenGDS", value: "OpenGDS" },
  { representation: "Reserva", value: "Reserva" },
  { representation: "ReservaCars", value: "ReservaCars" },
  { representation: "RoomCloud", value: "RoomCloud" },
  { representation: "Spot2Nite", value: "Spot2Nite" },
  { representation: "The Square", value: "TheSquare" },
  { representation: "Traveloka", value: "Traveloka" },
  { representation: "Travia", value: "Travia" },
  { representation: "Webrooms", value: "Webrooms" },
  { representation: "Wubook", value: "Wubook" },
  { representation: "stayforRewards", value: "stayforRewards" },
  { representation: "VRBO / Homeaway", value: "VRBO" },
  { representation: "Channex Express", value: "ChannexExpress" },
  { representation: "Tripla", value: "Tripla" },
  { representation: "OTASync", value: "OTASync" },
  { representation: "Mitchell Corp", value: "MitchellCorp" },
  { representation: "Tiket.com", value: "TiketCom" },
  { representation: "MG Bedbank", value: "MGBedbank" },
  { representation: "TravelGateX", value: "Travelgatex" },
  { representation: "Feratel", value: "Feratel" },
  { representation: "Make My Trip", value: "Goibibo" },
  { representation: "Dream Ireland", value: "DreamIreland" },
  { representation: "HRS", value: "HRS" },
  { representation: "OmniHotelier", value: "OmniHotelier" },
  { representation: "GuestPro", value: "Guestaps" },
  { representation: "Atlantis ORS", value: "AtlantisORS" },
  { representation: "Hipcamp", value: "Hipcamp" },
  { representation: "ACE Booking Engine", value: "ACEBookingEngine" },
  { representation: "Wihp", value: "Wihp" },
  { representation: "PriceTravel", value: "PriceTravel" },
  { representation: "HRS Australia / Lido", value: "Lido" },
  { representation: "The Hotel Network", value: "HotelNetwork" },
  { representation: "Getaroom", value: "Getaroom" },
  { representation: "PegiPegi", value: "PegiPegi" },
  { representation: "Emerging Travel Group", value: "Ostrovok" },
  { representation: "Hotel Point", value: "HotelPoint" },
  { representation: "Szallas", value: "Szallas" },
  { representation: "Mr & Mrs Smith", value: "MrAndMrsSmith" },
  { representation: "Wink", value: "Wink" },
  { representation: "Webready", value: "Webready" },
  { representation: "Hookusbookus", value: "Hookusbookus" },
  { representation: "Bookeasy", value: "Bookeasy" },
  { representation: "Hopper", value: "Hopper" },
  { representation: "Hopper Homes", value: "HopperHomes" },
  { representation: "RoomBeast", value: "RoomBeast" },
  { representation: "Reconline", value: "Reconline" },
  { representation: "Switzerland Travel Centre", value: "STC" },
  { representation: "Roibos", value: "Roibos" },
  { representation: "BookOutdoors", value: "BookOutdoors" },
  { representation: "Hostel Hop", value: "HostelHop" },
  { representation: "Hoterip", value: "Hoterip" },
  { representation: "DidaTravel", value: "DidaTravel" },
  { representation: "HotelTonight", value: "HotelTonight" },
  { representation: "The TPM Group", value: "TheTPMGroup" },
  { representation: "SleepRest", value: "SleepRest" },
  { representation: "Moverii", value: "Moverii" },
  { representation: "TabletHotels", value: "TabletHotels" },
  { representation: "seminyak.villas", value: "SeminyakVillas" },
  { representation: "Sissae Living", value: "SissaeLiving" },
  { representation: "B2B Global", value: "B2BGlobal" },
  { representation: "RVPARKGURU", value: "RVPARKGURU" },
  { representation: "CampingVision", value: "Camping Vision" },
  { representation: "Bookola", value: "Bookola" },
  { representation: "DespegarV2", value: "DespegarV2" },
]
  .sort(alphabetSort("representation"));
