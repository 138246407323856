import React, { Component } from "react";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Col, Flex } from "antd";

import FormMessage from "components/forms/message/form_message";

import ConnectionSettingsForm from "../connection_settings_form";

import styles from "./connection_settings_components.module.css";

class ConnectionSettings extends Component {
  render() {
    const {
      t,
      settingsSchema,
      connectionStatus,
      connectionErrors,
      mappingSchema,
      form,
      namespace,
      focusField,
      handleFieldChange,
      showTestConnectionAction,
      onTestConnectionClick,
    } = this.props;

    return (
      <div>
        <legend>{t("channels_page:form:connection_settings")}</legend>
        {!settingsSchema && (
          <FormMessage message={t("channels_page:form:choose_channel")} />
        )}
        {settingsSchema && (
          <div>
            <ConnectionSettingsForm
              settingsSchema={settingsSchema}
              mappingSchema={mappingSchema}
              form={form}
              namespace={namespace}
              onChange={handleFieldChange}
              focusField={focusField}
            />
            {showTestConnectionAction && (
              <Flex data-cy="connection">
                <Col sm={8} />
                <Col sm={16} xs={24}>
                  <Flex justify="space-between">
                    {connectionStatus === null && (
                      <div className={styles.status} />
                    )}
                    {connectionStatus && connectionStatus === "loading" && (
                      <div className={styles.status}>
                        <LoadingOutlined />
                        &nbsp;
                        {t("channels_page:connection_test:testing")}
                      </div>
                    )}
                    {connectionStatus && connectionStatus === "success" && (
                      <div className={styles.status} data-cy="successful_connection">
                        <CheckCircleOutlined
                          className={styles.status__successIcon}
                        />
                        &nbsp;
                        {t("channels_page:connection_test:success")}
                      </div>
                    )}
                    {connectionStatus && connectionStatus === "error" && (
                      <div className={styles.status} data-cy="incorrect_connection">
                        <WarningOutlined
                          className={styles.status__warningIcon}
                        />
                        &nbsp;
                        {t("channels_page:connection_test:incorrect")}
                      </div>
                    )}
                    <div className={styles.status__checkButtonContainer}>
                      <Button onClick={onTestConnectionClick} data-cy="test_connection">
                        {t("channels_page:connection_test:test_connection")}
                      </Button>
                    </div>
                  </Flex>
                  {connectionErrors && (
                    <div className={styles.status__errorMessage} data-cy="error">
                      {t(`channels_page:connection_test:errors:${connectionErrors}`)}
                    </div>
                  )}
                </Col>
              </Flex>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ConnectionSettings;
