import React from "react";

export const Container = ({ children, itemHeight }) => {
  const offset = Math.ceil(itemHeight / 4);

  return (
    <div
      style={{
        flex: `0 0 calc(30% - ${offset}px)`,
        placeContent: "flex-end",
        paddingTop: "24px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
};
