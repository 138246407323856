import React from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormSelectRaw from "components/forms/inputs/form_select";

export default function FormSelect({ name, control, grouped, ...inputProps }) {
  const input = (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormSelectRaw
          grouped={grouped}
          {...field}
          {...inputProps}
        />
      )}
    />
  );

  if (grouped) {
    return input;
  }

  return (
    <Row>
      <Col span={24}>
        {input}
      </Col>
    </Row>
  );
}
