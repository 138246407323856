import { Spin } from "antd";

import { Container } from "./container";

export const Loading = () => {
  return (
    <Container itemHeight={30}>
      <Spin spinning />
    </Container>
  );
};
