import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import classNames from "classnames";

import Loading from "components/loading";

import { buildTimelineItem } from "./build_timeline_item";

import styles from "./styles.module.css";

export function BookingEventsTimeline({ events, hasMore, isLoading, isLoadingMore, onLoadMore }) {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  const loadMoreClassName = classNames({
    [`${styles.loadMore}`]: isLoadingMore === false,
    [`${styles.loadMore__active}`]: isLoadingMore === true,
  });

  return (
    <div>
      <Timeline
        className={styles.bookingEvents}
        mode="left"
        items={events.map(buildTimelineItem)}
      />
      {hasMore && (
        <a onClick={onLoadMore} className={loadMoreClassName} disabled={isLoadingMore}>
          {isLoadingMore && <><LoadingOutlined className={styles.icon} />&nbsp;</>}
          {t("bookings_page:booking_view_dialog:load_more")}
        </a>
      )}
    </div>
  );
}
