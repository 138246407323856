import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { buildMappingsPerExternalRoom } from "../../_common/build_mappings_per_external_room";
import { buildUnknownMappingOptions } from "../../_common/build_unknown_mapping_options";
import { transformMappingsToArray } from "../../_common/utils/transform_mappings_to_array";
import { transformMappingsToObject } from "../../_common/utils/transform_mappings_to_object";

import { buildMappingItems } from "./build_mapping_items";

export const useMultiOccupancyExtraValuesMapping = (sourceData) => {
  const {
    mappings,
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  } = sourceData;

  // in channel management for failed requests mappingOptions is set to null
  const mappingOptions = useMemo(() => sourceData.mappingOptions || {}, [sourceData.mappingOptions]);

  mappingSettings.rooms = mappingSettings.rooms || {};
  mappingOptions.rooms = mappingOptions.rooms || [];

  const { t } = useTranslation();
  const mappingsAsArray = useMemo(() => transformMappingsToArray(mappings), [mappings]);
  const mappingsPerExternalRoom = useMemo(() => buildMappingsPerExternalRoom(mappingsAsArray), [mappingsAsArray]);

  const unknownMappingOptions = useMemo(
    () => buildUnknownMappingOptions({ mappingsAsArray, mappingOptions, mappingSettings }),
    [mappingsAsArray, mappingOptions, mappingSettings],
  );

  const mappingItems = useMemo(
    () => buildMappingItems({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t }),
    [mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t],
  );

  const handleRoomMappingChange = useCallback(({ externalRoom, mapping: newMapping, prevMapping }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: newMapping.roomTypeId,
      },
    };

    // if mapping for room was changed we need to remove all rate mappings for that room
    if (prevMapping) {
      const newMappings = mappingsAsArray.filter((mapping) => {
        return mapping.settings.room_type_code !== externalRoom.id;
      });

      if (newMappings.length !== mappingsAsArray.length) {
        onChangeMapping(transformMappingsToObject(newMappings));
      }
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeMapping, onChangeSettings]);

  const handleRoomMappingDelete = useCallback(({ externalRoom }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: null,
      },
    };

    // remove all rate mapping for that room
    const newMappings = mappingsAsArray.filter((mapping) => {
      return mapping.settings.room_type_code !== externalRoom.id;
    });

    if (newMappings.length !== mappingsAsArray.length) {
      onChangeMapping(transformMappingsToObject(newMappings));
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeSettings, onChangeMapping]);

  const handlePrimaryOccChange = useCallback(({ mapping: newPrimaryOccMapping }) => {
    // room with several occupancies can have only one primary occupancy
    const newMappings = mappingsAsArray.map((mapping) => {
      // reset primary_occ for all rate plans in room
      if (newPrimaryOccMapping.settings.rate_plan_code === mapping.settings.rate_plan_code && newPrimaryOccMapping.settings.room_type_code === mapping.settings.room_type_code) {
        mapping.settings.primary_occ = false;
      }

      // set specified occupancy as primary
      if (newPrimaryOccMapping.settings.rate_plan_code === mapping.settings.rate_plan_code && newPrimaryOccMapping.settings.room_type_code === mapping.settings.room_type_code && newPrimaryOccMapping.settings.occupancy === mapping.settings.occupancy) {
        mapping.settings.primary_occ = true;
      }

      return mapping;
    });

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, onChangeMapping]);

  const handleRateMappingChange = useCallback(({ mapping: newMapping, prevMapping }) => {
    let newMappings = mappingsAsArray;
    const isOccBasedMapping = typeof newMapping.settings.occupancy !== "undefined";

    if (prevMapping) {
      newMappings = mappingsAsArray.map((mapping) => {
        const isRoomAndRateMatch = mapping.settings.rate_plan_code === newMapping.settings.rate_plan_code && mapping.settings.room_type_code === newMapping.settings.room_type_code;
        const isOccBasedMappingMatch = isOccBasedMapping && mapping.settings.occupancy === newMapping.settings.occupancy;

        if (isRoomAndRateMatch && (isOccBasedMapping ? isOccBasedMappingMatch : true)) {
          return newMapping;
        }

        return mapping;
      });
    } else {
      if (isOccBasedMapping) {
        const externalRateMappingsForAllOccupancies = Object.values(
          mappingsPerExternalRoom?.[newMapping.settings.room_type_code]?.[newMapping.settings.rate_plan_code] || {},
        );
        const isPrimaryOccExistsInRatesMappings = externalRateMappingsForAllOccupancies.find((ms) => ms.settings.primary_occ);

        if (!isPrimaryOccExistsInRatesMappings) {
          newMapping.settings.primary_occ = true;
        }
      }

      newMappings.push(newMapping);
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingsPerExternalRoom, onChangeMapping]);

  const handleRateMappingDelete = useCallback(({ mapping }) => {
    const mappingItemSettings = mapping.settings;
    const isOccBasedMapping = typeof mappingItemSettings.occupancy !== "undefined";

    let newMappings = mappingsAsArray;
    let needToUpdatePrimaryOcc = false;

    if (isOccBasedMapping && mappingItemSettings.primary_occ) {
      const externalRateMappingsForAllOccupancies = Object.values(
        mappingsPerExternalRoom?.[mappingItemSettings.room_type_code]?.[mappingItemSettings.rate_plan_code] || {},
      );

      if (externalRateMappingsForAllOccupancies.length > 1) {
        needToUpdatePrimaryOcc = true;
      }
    }

    newMappings = newMappings.filter(({ settings }) => {
      const isRoomAndRateMatch = settings.rate_plan_code === mappingItemSettings.rate_plan_code && settings.room_type_code === mappingItemSettings.room_type_code;
      const isOccBasedMappingMatch = isOccBasedMapping && settings.occupancy === mappingItemSettings.occupancy;

      return !(isRoomAndRateMatch && (isOccBasedMapping ? isOccBasedMappingMatch : true));
    });

    if (needToUpdatePrimaryOcc) {
      for (const newMapping of newMappings) {
        if (newMapping.settings.room_type_code === mappingItemSettings.room_type_code && newMapping.settings.rate_plan_code === mappingItemSettings.rate_plan_code) {
          newMapping.settings.primary_occ = true;
          break;
        }
      }
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);

    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingsPerExternalRoom, onChangeMapping]);

  return {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handlePrimaryOccChange,
    handleRateMappingChange,
    handleRateMappingDelete,
  };
};
