import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormInputRaw from "components/forms/inputs/form_input";

export default forwardRef(({ name, label, control, errors, grouped, ...inputProps }, ref) => {
  const input = (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormInputRaw
          inputRef={ref}
          hookForm
          label={label}
          errors={errors}
          grouped={grouped}
          {...inputProps}
          {...field}
        />
      )}
    />
  );

  if (grouped) {
    return input;
  }

  return (
    <Row>
      <Col span={24}>
        {input}
      </Col>
    </Row>
  );
});
