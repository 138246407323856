import React, { memo } from "react";
import classNames from "classnames";

import { MappingDeleteButton } from "components/mapping/mapping_row/mapping_delete_button";
import RateTitleWithId from "components/rates/title_with_id/title_with_id";

import { MappingRow } from "../../_common/mapping_row";

import { MappingSelect } from "./mapping_select";
import { PrimaryToggle } from "./primary_toggle";

import styles from "../styles.module.css";

export const RateMappingRow = memo(({ mappingItem, onPrimaryRateChange, onChange, onDelete }) => {
  const { externalRate, mapping, ratePlans, isInvalid } = mappingItem;

  const isReadonly = externalRate.readonly;
  const isParentRoomMapped = externalRate.isParentRoomMapped || false;

  const showToggleColumn = mapping?.settings?.rate_plan_code;
  const isPrimary = mapping?.settings?.is_primary;

  const rateToggleClass = (!isPrimary && !isReadonly) && styles.primarySelect;

  return (
    <MappingRow
      className={styles.row}
      isError={isInvalid}
      showMapping={isParentRoomMapped}
      source={(
        <div className={classNames(styles.column__withActions, styles.column__rate)}>
          <div className={styles.columnContent}>
            <RateTitleWithId rate={externalRate} showReadOnly />
          </div>
          <div className={classNames(styles.columnActions, rateToggleClass)}>
            <PrimaryToggle
              visible={showToggleColumn}
              isPrimary={isPrimary}
              isReadonly={isReadonly}
              onClick={() => {
                if (isPrimary) {
                  return false;
                }

                onPrimaryRateChange({ mapping });
              }}
            />
          </div>
        </div>
      )}
      target={(
        <div className={styles.column__withActions}>
          <div className={styles.columnContent}>
            <MappingSelect
              isInvalid={isInvalid}
              value={mapping?.ratePlanId}
              options={ratePlans}
              onChange={(ratePlanId) => {
                const settings = {
                  room_type_code: externalRate.roomTypeCode,
                  rate_plan_code: externalRate.id,
                  is_primary: false,
                };

                // if we are changing existing mapping we should keep is_primary
                if (mapping) {
                  settings.is_primary = mapping.settings.is_primary;
                }

                if (typeof externalRate.readonly !== "undefined") {
                  settings.readonly = externalRate.readonly;
                }

                const newMapping = {
                  ratePlanId,
                  settings,
                };

                onChange({ externalRate, mapping: newMapping, prevMapping: mapping });
              }}
            />
          </div>
          <div className={styles.columnActions}>
            <MappingDeleteButton
              visible={!!mapping}
              onClick={() => onDelete({ mapping })}
            />
          </div>
        </div>
      )}
    />
  );
});
