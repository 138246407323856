import React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

import styles from "./styles.module.css";

export default function GlobalErrors({ errors, hookForm = false }) {
  const { t } = useTranslation();

  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }

  const errorObject = hookForm
    ? errors?.root?.global?.errors
    : errors;

  const errorMessages = Object.values(errorObject || {}).reduce((acc, error) => {
    if (Array.isArray(error)) {
      return [...acc, ...error];
    }

    return [...acc, error];
  }, []);

  if (errorMessages.length === 0) {
    return null;
  }

  const errorMessage = errorMessages.map((message, i) => (
    <div key={i}>{message}</div>
  ));

  return (
    <Alert
      className={styles.alert}
      message={t("general:validation_error")}
      description={errorMessage}
      type="error"
    />
  );
}
