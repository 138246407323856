import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Select } from "antd";
import classNames from "classnames";

import RatePlanTitle from "components/rates/title";

import selectStyles from "./mapping_select.module.css";

class MappingSelectComponent extends Component {
  state = {
    isOpen: false,
    sortedOptions: [],
  };

  componentDidMount() {
    this.setSortedOptions();
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;

    if (prevProps.options !== options) {
      this.setSortedOptions();
    }
  }

  setSortedOptions = () => {
    const { options = [] } = this.props;
    const sortedOptions = [...options];

    sortedOptions.sort((rateA, rateB) => {
      return rateA.title.localeCompare(rateB.title);
    });

    this.setState({ sortedOptions });
  };

  renderValue = () => {
    const { t, options, value } = this.props;
    const placeholder = t("channels_page:form:not_mapped");

    const selectedOption = options.find((rt) => rt.id === value);

    if (!selectedOption) {
      return placeholder;
    }

    return <RatePlanTitle className={selectStyles.rateTitle} rate={selectedOption} />;
  };

  getOptions = () => {
    const { sortedOptions } = this.state;

    return sortedOptions.map((option) => (
      <Select.Option key={option.id} label={option.title}>
        <RatePlanTitle className={selectStyles.rateTitle} rate={option} />
      </Select.Option>
    ));
  };

  toggleSelect = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  handleSelect = (value) => {
    const { onChange } = this.props;

    onChange(value);
    this.toggleSelect();
  };

  handleBlur = () => this.toggleSelect();

  render() {
    const { t, value, isInvalid } = this.props;
    const { isOpen } = this.state;

    const isDanger = !value;
    const buttonClass = classNames(selectStyles.button, {
      [selectStyles.transparent]: isOpen,
    });

    return (
      <div className={selectStyles.container}>
        <Button
          type="link"
          className={buttonClass}
          onClick={this.toggleSelect}
          danger={isDanger}
          disabled={isInvalid}
        >
          {this.renderValue()}
        </Button>
        {isOpen && (
          <Select
            className={selectStyles.select}
            placeholder={t("channels_page:form:not_mapped")}
            value={value}
            defaultOpen
            autoFocus
            size="small"
            showSearch
            onSelect={this.handleSelect}
            onBlur={this.handleBlur}
            filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
          >
            {this.getOptions()}
          </Select>
        )}
      </div>
    );
  }
}

export const MappingSelect = withTranslation()(MappingSelectComponent);
