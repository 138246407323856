import React from "react";
import { Col, Row } from "antd";

import { FormWeekdaysV2, THREE_CHAR_VALUE_MODE, THREE_CHAR_WEEKDAY_VALUES, WEEKDAY_VALUES } from "components/forms/inputs/form_weekdays_v2";

import { buildHookFormInput } from "../_build_hook_form_input";

export { WEEKDAY_VALUES, THREE_CHAR_WEEKDAY_VALUES, THREE_CHAR_VALUE_MODE };

const FormWeekdaysHookForm = buildHookFormInput(FormWeekdaysV2);

export const FormWeekdays = ({ grouped, ...rest }) => {
  const input = <FormWeekdaysHookForm grouped={grouped} {...rest} />;

  if (grouped) {
    return input;
  }

  return (
    <Row>
      <Col span={24}>
        {input}
      </Col>
    </Row>
  );
};
