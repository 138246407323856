import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  IssuesCloseOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import { bookingDate } from "utils/dates";

import ExportModal from "./export_modal/export_modal";
import AdvancedSearch from "./advanced_search";

import bookingPageStyles from "./bookings_page.module.css";
import styles from "styles/dashboard.module.css";

const { Bookings } = store;

class BookingsPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    columnsToHide: 0,
    tableQuery: "",
    tableFilterQuery: "",
    advancedSearchFilter: {},
    isExportModalOpen: false,
  };

  tableRef = React.createRef();

  actions = () => {
    const { t, routes } = this.props;

    return (text, record) => (
      <span>
        <Link to={pathBuilder(routes.userAppRoutes.bookings.view, { bookingId: record.id })}>
          {t("general:action:view")}
        </Link>
      </span>
    );
  };

  componentDidUpdate(prevProps) {
    const { activeGroup, activeProperty } = this.props;

    if (activeGroup !== prevProps.activeGroup || activeProperty !== prevProps.activeProperty) {
      this.tableRef.current.resetTable();
    }
  }

  loadData = (query, pagination, order) => {
    const { activeGroup, activeProperty } = this.props;
    const { advancedSearchFilter } = this.state;
    const filter = { ...advancedSearchFilter, q: query || "" };

    this.setState({ tableFilterQuery: query });

    if (activeGroup) {
      filter.group_id = activeGroup;
    }
    if (activeProperty) {
      filter.property_id = activeProperty;
    }

    return Bookings.list(filter, pagination, order);
  };

  is_mapped(model) {
    return model.rooms.reduce((acc, room) => {
      return acc && Boolean(room.rate_plan_id);
    }, true);
  }

  columns = () => {
    const { t, propertyTitles } = this.props;

    let dataColumns = [
      {
        title: t("bookings_page:columns:status"),
        dataIndex: "status",
        key: "status",
        render: (value, record) => (
          <div className={styles.bookings_page_container}>
            <span className={[styles[`dashboard__icons__${record.status}`]].join(" ")}>
              {record.status === "new" && <CheckCircleOutlined />}
              {record.status === "modified" && <IssuesCloseOutlined />}
              {record.status === "cancelled" && <CloseCircleOutlined />}
              {this.is_mapped(record) === false && (
                <span className={[styles.dashboard__icons__unallocated].join(" ")}>
                  <WarningOutlined />
                </span>
              )}
              &nbsp;
              <span className={styles.status_title}>
                {t(`bookings_page:booking_view_dialog:status:${value}`)}
              </span>
            </span>
          </div>
        ),
      },
      {
        title: t("bookings_page:columns:unique_id"),
        dataIndex: "unique_id",
        key: "unique_id",
        sorter: true,
      },
      {
        title: t("bookings_page:columns:property"),
        dataIndex: "property_id",
        key: "property",
        render: (value) => {
          return propertyTitles[value];
        },
      },
      {
        title: t("bookings_page:columns:customer"),
        dataIndex: "customer",
        key: "customer",
        render: (value) => {
          if (value.name === null) {
            return value.surname;
          }

          return `${value.name} ${value.surname}`;
        },
      },
      {
        className: bookingPageStyles.columnDates,
        title: t("bookings_page:columns:dates"),
        dataIndex: "arrival_date",
        key: "arrival_date",
        render: (_value, record) => {
          return (
            <>
              {bookingDate(record.arrival_date, t("formats:date_with_short_month"))}
              &nbsp; &rarr; &nbsp;
              {bookingDate(record.departure_date, t("formats:date_with_short_month"))}
            </>
          );
        },
      },
      {
        className: bookingPageStyles.columnRooms,
        title: t("bookings_page:columns:rooms"),
        dataIndex: "rooms",
        key: "rooms",
        render: (_value, record) => {
          return record.rooms.length;
        },
      },
      {
        className: bookingPageStyles.columnAcknowledged,
        title: t("bookings_page:columns:acknowledged"),
        dataIndex: "acknowledge_status",
        key: "acknowledge_status",
        render: (_value, record) => {
          switch (record.acknowledge_status) {
            case "acknowledged":
              return <CheckCircleOutlined className={styles.acked} />;
            case "not_acknowledged":
              return <CloseCircleOutlined className={styles.unacked} />;
            default:
              return <QuestionCircleOutlined className={styles.pending} />;
          }
        },
      },
      {
        className: bookingPageStyles.columnTotal,
        title: t("bookings_page:columns:total"),
        dataIndex: "amount",
        align: "right",
        key: "amount",
        render: (_value, record) => {
          return (
            <>
              {record.amount}&nbsp;{record.currency}
            </>
          );
        },
      },
    ];

    const actionColumns = [
      {
        className: bookingPageStyles.columnActions,
        title: t("bookings_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions(),
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("bookings_page:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { tableQuery } = this.state;
    const { routes } = this.props;

    const basePath = pathBuilder(routes.userAppRoutes.bookings);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  advancedSearchApply = (advancedSearchFilter) => {
    this.setState({
      advancedSearchFilter,
    });
  };

  handleOpenExportClick = () => {
    this.setState({ isExportModalOpen: true });
  };

  handleExportModalClose = () => {
    this.setState({ isExportModalOpen: false });
  };

  render() {
    const { data, activeProperty } = this.props;
    const { isExportModalOpen, advancedSearchFilter, tableFilterQuery } = this.state;
    const closePath = this.getClosePath();

    return (
      <Page.Main>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable
          data={data}
          componentRef={this.tableRef}
          emptyMessage={this.emptyMessage()}
          showCreateMessage={false}
          onTablePramsChange={this.handleTableQueryChange}
          columns={this.columns}
          loadData={this.loadData}
          advancedSearch={AdvancedSearch}
          advancedSearchFilter={advancedSearchFilter}
          advancedSearchApply={this.advancedSearchApply}
          actions={(
            activeProperty && (
              <Button icon={<DownloadOutlined />} onClick={this.handleOpenExportClick}>
                Export
              </Button>
            )
          )}
        />

        <Outlet context={{ closePath }} />
        <ExportModal
          isOpen={isExportModalOpen}
          filters={{
            ...advancedSearchFilter,
            propertyId: activeProperty,
            q: tableFilterQuery,
          }}
          onClose={this.handleExportModalClose}
        />
      </Page.Main>
    );
  }
}

const mapStateToProps = ({ bookings, roomTypes, ratePlans, session, properties }) => {
  const { entities } = bookings || { entities: null };
  const options = properties?.options || [];
  const propertyTitles = options.reduce((acc, property) => {
    acc[property.id] = property.title;
    return acc;
  }, {});

  return {
    data: entities ? Object.values(entities) : null,
    roomTypes,
    ratePlans,
    activeGroup: session.activeGroup,
    activeProperty: session.activeProperty,
    propertyTitles,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(BookingsPage)));
