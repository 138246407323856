export default [
  "ari",
  "booking",
  "booking_new",
  "booking_modification",
  "booking_cancellation",
  "sync_error",
  "reservation_request",
  "booking_unmapped_room",
  "booking_unmapped_rate",
  "sync_warning",
  "message",
  "review",
  "alteration_request",
  "inquiry",
  "disconnect_channel",
  "disconnect_listing",
  "rate_error",
  "accepted_reservation",
  "declined_reservation",
  "non_acked_booking",
];
