import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Cascader } from "antd";

import { RateTitle } from "components/rates/title";

import { MappingValue } from "../mapping_value";

import styles from "./styles.module.css";

export const CascaderEditor = ({ isInvalid, mapping, listing, propertiesById, roomTypesById, ratePlansById, onChange }) => {
  const { t } = useTranslation();
  const [isEditorOpened, openEditor] = useState(false);

  const handleChange = (path) => {
    const ratePlanId = path.pop();

    const settings = {
      listing_id: listing.id,
    };

    onChange({
      ratePlanId,
      settings,
    });

    openEditor(false);
  };

  const getRateIdsPath = (ratePlanId) => {
    if (!ratePlanId) {
      return [];
    }

    const rate = ratePlansById[ratePlanId];

    if (!rate) {
      return [];
    }

    return [rate.property_id, rate.room_type_id, rate.id];
  };

  if (!isEditorOpened) {
    return (
      <MappingValue
        isInvalid={isInvalid}
        mapping={mapping}
        propertiesById={propertiesById}
        roomTypesById={roomTypesById}
        ratePlansById={ratePlansById}
        onClick={() => openEditor(true)}
      />
    );
  }

  const options = Object.values(propertiesById).map((property) => ({
    label: property.title,
    value: property.id,
    searchValue: property.title,
    children: Object.values(roomTypesById)
      .filter((roomType) => roomType.property_id === property.id)
      .map((roomType) => ({
        label: roomType.title,
        value: roomType.id,
        searchValue: roomType.title,
        children: Object.values(ratePlansById)
          .filter((ratePlan) => ratePlan.room_type_id === roomType.id)
          .map((ratePlan) => ({
            label: <RateTitle rate={ratePlan} />,
            value: ratePlan.id,
            searchValue: ratePlan.title,
          })),
      })),
  }));

  return (
    <Cascader
      rootClassName={styles.cascader}
      style={{ width: "100%", height: "auto", padding: "4px 0" }}
      options={options}
      placeholder={t("channels_page:form:not_mapped")}
      value={getRateIdsPath(mapping?.ratePlanId)}
      defaultOpen
      autoFocus
      size="small"
      onDropdownVisibleChange={(visible) => {
        if (!visible) {
          openEditor(false);
        }
      }}
      showSearch={{
        filter: (inputValue, path) => {
          return path.some((option) => option.searchValue.toLowerCase().includes(inputValue.toLowerCase()));
        },
      }}
      placement="bottomRight"
      onChange={handleChange}
    />
  );
};
