import { formatDate, parseDate } from "utils/dates";

const READ_ONLY = {
  currency: "USD",
  pricingModel: "nightly_pricing",
};

const normalizeFeeOrTaxToForm = (feeOrTax) => {
  const { applicabilityRule, ...normalizedFeeOrTax } = feeOrTax;

  if (!applicabilityRule) {
    normalizedFeeOrTax.applicabilityRulePresent = false;
    return normalizedFeeOrTax;
  }

  normalizedFeeOrTax.applicabilityRulePresent = true;
  normalizedFeeOrTax.applicabilityRule = {
    type: applicabilityRule.type,
  };

  if (applicabilityRule?.type === "length_of_stay_between") {
    normalizedFeeOrTax.applicabilityRule.minLos = applicabilityRule.minLos;
    normalizedFeeOrTax.applicabilityRule.maxLos = applicabilityRule.maxLos;
  }

  if (applicabilityRule.from || applicabilityRule.until) {
    normalizedFeeOrTax.applicabilityRule.dateRange = [
      parseDate(applicabilityRule.from, { native: false }),
      parseDate(applicabilityRule.until, { native: false }),
    ];
  }

  return normalizedFeeOrTax;
};

export const toForm = (data) => {
  const normalizedData = { ...data };

  if (Array.isArray(normalizedData.fees)) {
    normalizedData.fees = normalizedData.fees.map(normalizeFeeOrTaxToForm);
  }

  if (Array.isArray(normalizedData.taxes)) {
    normalizedData.taxes = normalizedData.taxes.map(normalizeFeeOrTaxToForm);
  }

  return {
    ...normalizedData,
    ...READ_ONLY,
  };
};

const normalizeFeeOrTaxToApi = (feeOrTax) => {
  const { applicabilityRule, applicabilityRulePresent, ...normalizedFeeOrTax } = feeOrTax;

  if (!applicabilityRule || !applicabilityRulePresent) {
    return normalizedFeeOrTax;
  }

  normalizedFeeOrTax.applicabilityRule = {
    type: applicabilityRule.type,
  };

  if (applicabilityRule?.type === "length_of_stay_between") {
    if (applicabilityRule.minLos !== null) {
      normalizedFeeOrTax.applicabilityRule.minLos = applicabilityRule.minLos;
    }

    if (applicabilityRule.maxLos !== null) {
      normalizedFeeOrTax.applicabilityRule.maxLos = applicabilityRule.maxLos;
    }
  } else {
    normalizedFeeOrTax.applicabilityRule.from = formatDate(applicabilityRule.dateRange[0]);
    normalizedFeeOrTax.applicabilityRule.until = formatDate(applicabilityRule.dateRange[1]);
  }

  return normalizedFeeOrTax;
};

export const toApi = (formValue) => {
  const normalizedValue = { ...formValue };

  if (Array.isArray(normalizedValue.fees)) {
    normalizedValue.fees = normalizedValue.fees.map(normalizeFeeOrTaxToApi);
  }

  if (Array.isArray(normalizedValue.taxes)) {
    normalizedValue.taxes = normalizedValue.taxes.map(normalizeFeeOrTaxToApi);
  }

  return normalizedValue;
};
