import { forwardRef } from "react";
import { Checkbox as AntCheckbox } from "antd";

import { AntdFormField } from "./_base/antd_form_field";
import { HookFormInput } from "./_base/hook_form_input";
import { parseProps } from "./_base/parse_props";

export const Checkbox = AntCheckbox;

export const FormCheckbox = forwardRef((props, ref) => {
  const { sharedProps, hookFormInputProps, antdFormFieldProps, inputProps } = parseProps(props);

  return (
    <HookFormInput
      {...hookFormInputProps}
      {...sharedProps}
      render={({ input, field }) => (
        <AntdFormField
          {...sharedProps}
          {...field}
          {...antdFormFieldProps}
        >
          <Checkbox
            {...inputProps}
            {...input}
            checked={input.value}
            ref={ref}
          />
        </AntdFormField>
      )}
    />
  );
});
