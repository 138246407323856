import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  name: yup.string()
    .required(errorMessages.required()),
  type: yup.string()
    .required(errorMessages.required()),
  amount: yup.string()
    .matches(/^\d*\.?\d+$/, errorMessages.number())
    .when("type", {
      is: "flat_tax",
      then: (schema) => schema.required(errorMessages.required()),
      otherwise: (schema) => schema.nullable(),
    }),
  percent: yup.string()
    .matches(/^\d*\.?\d+$/, errorMessages.number())
    .when("type", {
      is: "percentage_tax",
      then: (schema) => schema.required(errorMessages.required()),
      otherwise: (schema) => schema.nullable(),
    }),
  chargeMode: yup.string()
    .required(errorMessages.required()),
  applicabilityRulePresent: yup.boolean(),
  applicabilityRule: yup.object().when("applicabilityRulePresent", {
    is: true,
    then: (schema) => schema.shape({
      type: yup.string()
        .required(errorMessages.required()),
      minLos: yup.number()
        .typeError(errorMessages.number())
        .when("type", {
          is: "length_of_stay_between",
          then: (schema2) => schema2
            .nullable()
            .integer(errorMessages.number)
            .typeError(errorMessages.number())
            .min(0, errorMessages.greaterOrEqual(0)),
          otherwise: (schema2) => schema2.nullable(),
        }),
      maxLos: yup.number()
        .typeError(errorMessages.number())
        .when("type", {
          is: "length_of_stay_between",
          then: (schema2) => schema2
            .nullable()
            .integer(errorMessages.number)
            .typeError(errorMessages.number())
            .min(0, errorMessages.greaterOrEqual(0)),
          otherwise: (schema2) => schema2.nullable(),
        }),
      dateRange: yup.array()
        .when("type", {
          is: "booking_date_in",
          then: (schema2) => schema2
            .of(yup.date().required(errorMessages.required()))
            .required(errorMessages.required()),
          otherwise: (schema2) => schema2.nullable(),
        }),
    }),
    otherwise: (schema2) => schema2.nullable(),
  }),
});
