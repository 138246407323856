import { useState } from "react";

const DRAWER_CLOSE_DURATION = 150;

export default function useDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState(null);

  return {
    isOpen,
    payload,
    open: (openPayload) => {
      setPayload(openPayload);

      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);

      setTimeout(() => {
        setPayload(null);
      }, DRAWER_CLOSE_DURATION);
    },
  };
}
