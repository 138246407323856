import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Row } from "antd";
import store from "store";
import { channelsApiClient } from "store/api_clients/channels";

import currencyOptions from "config/constants/currency_options";

import { BodyContainer, FooterContainer, ScrollableContainer } from "drawers/channex_drawer_v2";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import { HourSelector } from "components/forms/inputs/hook_form/_v2/hour_selector";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { FormCheckbox } from "components/forms/inputs/hook_form/form_checkbox";
import { FormCountrySelect } from "components/forms/inputs/hook_form/form_country_select";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormInputNumber from "components/forms/inputs/hook_form/form_input_number";
import { FormTextarea } from "components/forms/inputs/hook_form/form_textarea";
import Legend from "components/forms/layout/legend/legend";
import { StyledForm } from "components/forms/styled_form";
import useAppForm from "components/hook_form/use_app_form";
import Map from "components/map/map";

import { hopperHomesErrorParser } from "../../../../api/error_parser/hopper_homes_error_parser";

import { Photos } from "./photos";
import { Rooms } from "./rooms";
import { validationSchema } from "./validation_schema";

const { Geocoding } = store;

const DEFAULT_VALUE = {
  currency: "USD",
  countryCode: "US",
  petsAllowed: false,
  smokingAllowed: false,
  partiesAllowed: false,
};

const buildDefaultValues = (listing) => {
  if (!listing) {
    return DEFAULT_VALUE;
  }

  return {
    ...listing,
    currency: "USD",
  };
};

const useGeoCodingMutation = () => {
  return useMutation({
    mutationFn: (fullAddress) => Geocoding.getByAddress(fullAddress),
  });
};

const useAmenities = (channelId) => {
  return useQuery({
    queryFn: () => channelsApiClient.amenities(channelId),
  });
};

export const ListingForm = ({ channelId, listing, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const geoCodingMutation = useGeoCodingMutation();
  const amenitiesQuery = useAmenities(channelId);

  const { handleSubmit, errors, control, watch, setValue } = useAppForm({
    defaultValue: buildDefaultValues(listing),
    submitHandler: async (formValue) => {
      await onSubmit(formValue);
    },
    fieldNames: ["title", "description", "registrationNumber", "sizeSquareFeet", "roomType", "propertyType", "currency", "countryCode", "postalCode", "state", "city", "address", "latitude", "longitude", "maxAdults", "maxChildren", "numberOfBeds", "numberOfBedrooms", "numberOfBathrooms", "amenities", "petsAllowed", "smokingAllowed", "partiesAllowed", "houseRules", "checkInInstructions", "checkInStartTime", "checkInEndTime", "checkOutStartTime", "checkOutEndTime", "photos", "rooms"],
    apiErrorParser: (error) => hopperHomesErrorParser.parse(error.body.errors, t),
    validationSchema,
  });

  const [countryCode, state, city, postalCode, address, latitude, longitude, photos] = watch(["countryCode", "state", "city", "postalCode", "address", "latitude", "longitude", "photos"]);

  const handleGeoCodeAddressClick = useCallback(async () => {
    const fullAddress = [countryCode, state, city, postalCode, address].join(",");

    const { attributes } = await geoCodingMutation.mutateAsync(fullAddress);

    setValue("latitude", attributes.location.lat, { shouldValidate: true });
    setValue("longitude", attributes.location.lon, { shouldValidate: true });
  }, [countryCode, state, city, postalCode, address, setValue, geoCodingMutation]);

  const handleCoordsChange = useCallback(({ lat, lng }) => {
    setValue("latitude", lat, { shouldValidate: true });
    setValue("longitude", lng, { shouldValidate: true });
  }, [setValue]);

  const amenitiesOptions = useMemo(() => {
    if (!amenitiesQuery.data) {
      return [];
    }

    return amenitiesQuery.data.map((amenity) => ({
      value: amenity,
      label: t(`common:dicts:facilities:${amenity}`),
    }));
  }, [amenitiesQuery.data, t]);

  const ROOM_TYPES = [
    { value: "private_room", label: t("listings:dicts:room_type:private_room") },
    { value: "entire_place", label: t("listings:dicts:room_type:entire_place") },
  ];

  const PROPERTY_TYPES = [
    { value: "aparthotel", label: t("listings:dicts:property_type:aparthotel") },
    { value: "apartment", label: t("listings:dicts:property_type:apartment") },
    { value: "barn", label: t("listings:dicts:property_type:barn") },
    { value: "bed_and_breakfast", label: t("listings:dicts:property_type:bed_and_breakfast") },
    { value: "boat", label: t("listings:dicts:property_type:boat") },
    { value: "boutique_hotel", label: t("listings:dicts:property_type:boutique_hotel") },
    { value: "bungalow", label: t("listings:dicts:property_type:bungalow") },
    { value: "bus", label: t("listings:dicts:property_type:bus") },
    { value: "cabin", label: t("listings:dicts:property_type:cabin") },
    { value: "camper_rv", label: t("listings:dicts:property_type:camper_rv") },
    { value: "campsite", label: t("listings:dicts:property_type:campsite") },
    { value: "casa_particular", label: t("listings:dicts:property_type:casa_particular") },
    { value: "castle", label: t("listings:dicts:property_type:castle") },
    { value: "cave", label: t("listings:dicts:property_type:cave") },
    { value: "chalet", label: t("listings:dicts:property_type:chalet") },
    { value: "condo", label: t("listings:dicts:property_type:condo") },
    { value: "cottage", label: t("listings:dicts:property_type:cottage") },
    { value: "dome_house", label: t("listings:dicts:property_type:dome_house") },
    { value: "earth_house", label: t("listings:dicts:property_type:earth_house") },
    { value: "farm_stay", label: t("listings:dicts:property_type:farm_stay") },
    { value: "guest_suite", label: t("listings:dicts:property_type:guest_suite") },
    { value: "guesthouse", label: t("listings:dicts:property_type:guesthouse") },
    { value: "holiday_park", label: t("listings:dicts:property_type:holiday_park") },
    { value: "hostel", label: t("listings:dicts:property_type:hostel") },
    { value: "houseboat", label: t("listings:dicts:property_type:houseboat") },
    { value: "hotel", label: t("listings:dicts:property_type:hotel") },
    { value: "house", label: t("listings:dicts:property_type:house") },
    { value: "hut", label: t("listings:dicts:property_type:hut") },
    { value: "igloo", label: t("listings:dicts:property_type:igloo") },
    { value: "island", label: t("listings:dicts:property_type:island") },
    { value: "lighthouse", label: t("listings:dicts:property_type:lighthouse") },
    { value: "loft", label: t("listings:dicts:property_type:loft") },
    { value: "nature_lodge", label: t("listings:dicts:property_type:nature_lodge") },
    { value: "ranch", label: t("listings:dicts:property_type:ranch") },
    { value: "resort", label: t("listings:dicts:property_type:resort") },
    { value: "riad", label: t("listings:dicts:property_type:riad") },
    { value: "serviced_apartment", label: t("listings:dicts:property_type:serviced_apartment") },
    { value: "shepherd_hut", label: t("listings:dicts:property_type:shepherd_hut") },
    { value: "shipping_container", label: t("listings:dicts:property_type:shipping_container") },
    { value: "tent", label: t("listings:dicts:property_type:tent") },
    { value: "tiny_house", label: t("listings:dicts:property_type:tiny_house") },
    { value: "tipi", label: t("listings:dicts:property_type:tipi") },
    { value: "tower", label: t("listings:dicts:property_type:tower") },
    { value: "town_house", label: t("listings:dicts:property_type:town_house") },
    { value: "train", label: t("listings:dicts:property_type:train") },
    { value: "tree_house", label: t("listings:dicts:property_type:tree_house") },
    { value: "vacation_home", label: t("listings:dicts:property_type:vacation_home") },
    { value: "villa", label: t("listings:dicts:property_type:villa") },
    { value: "windmill", label: t("listings:dicts:property_type:windmill") },
    { value: "yurt", label: t("listings:dicts:property_type:yurt") },
  ];

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <GlobalErrors hookForm errors={errors} />

          <Legend>{t("common:headings:basic_info")}</Legend>

          <FormInput
            name="title"
            label={t("common:terms:title")}
            placeholder={t("common:forms:fields:title:placeholder")}
            errors={errors?.title?.message}
            control={control}
          />

          <FormTextarea
            name="description"
            label={t("common:terms:description")}
            placeholder={t("common:forms:fields:description:placeholder")}
            errors={errors?.description?.message}
            control={control}
          />

          <FormInput
            name="registrationNumber"
            label={t("listings:terms:registration_number")}
            placeholder={t("listings:forms:fields:registration_number:placeholder")}
            errors={errors?.registrationNumber?.message}
            control={control}
          />

          <FormInput
            name="sizeSquareFeet"
            label={t("listings:terms:size_square_feet")}
            placeholder={t("listings:forms:fields:size_square_feet:placeholder")}
            errors={errors?.sizeSquareFeet?.message}
            control={control}
          />

          <FormSelect
            name="roomType"
            label={t("listings:terms:room_type")}
            placeholder={t("common:forms:fields:room_type:placeholder")}
            options={ROOM_TYPES}
            control={control}
          />

          <FormSelect
            name="propertyType"
            label={t("listings:terms:property_type")}
            placeholder={t("listings:forms:fields:property_type:placeholder")}
            options={PROPERTY_TYPES}
            control={control}
          />

          <FormSelect
            name="currency"
            label={t("common:terms:currency")}
            placeholder={t("common:forms:fields:currency:placeholder")}
            disabled
            options={currencyOptions({ only: ["USD"] })}
            control={control}
          />

          <Legend>{t("common:headings:location")}</Legend>

          <FormCountrySelect
            name="countryCode"
            label={t("common:terms:country")}
            placeholder={t("common:forms:fields:country:placeholder")}
            errors={errors?.countryCode?.message}
            disabled
            control={control}
          />

          <FormInput
            name="postalCode"
            label={t("common:terms:postal_code")}
            placeholder={t("common:forms:fields:postal_code:placeholder")}
            errors={errors?.postalCode?.message}
            control={control}
          />

          <FormInput
            name="state"
            label={t("common:terms:state")}
            placeholder={t("common:forms:fields:state:placeholder")}
            errors={errors?.state?.message}
            control={control}
          />

          <FormInput
            name="city"
            label={t("common:terms:city")}
            placeholder={t("common:forms:fields:city:placeholder")}
            errors={errors?.city?.message}
            control={control}
          />

          <FormInput
            name="address"
            label={t("common:terms:address")}
            placeholder={t("common:forms:fields:address:placeholder")}
            errors={errors?.address?.message}
            control={control}
          />

          <Row>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
              <Button
                style={{
                  width: "100%",
                  marginBottom: "8px",
                }}
                type="primary"
                onClick={handleGeoCodeAddressClick}
                icon={<EnvironmentOutlined />}
                loading={geoCodingMutation.isLoading}
              >
                {t("common:actions:find_location")}
              </Button>
            </Col>
          </Row>

          <FormInput
            name="latitude"
            type="number"
            label={t("common:terms:latitude")}
            placeholder={t("common:forms:fields:latitude:placeholder")}
            errors={errors?.latitude?.message}
            control={control}
          />

          <FormInput
            name="longitude"
            type="number"
            label={t("common:terms:longitude")}
            placeholder={t("common:forms:fields:longitude:placeholder")}
            errors={errors?.longitude?.message}
            control={control}
          />

          <Map
            onChangeCoords={handleCoordsChange}
            lat={latitude}
            lng={longitude}
          />

          <Legend>{t("listings:headings:occupancy_settings")}</Legend>

          <FormInputNumber
            name="maxAdults"
            label={t("common:terms:max_adults")}
            placeholder={t("common:forms:fields:max_adults:placeholder")}
            errors={errors?.maxAdults?.message}
            control={control}
          />

          <FormInputNumber
            name="maxChildren"
            label={t("common:terms:max_children")}
            placeholder={t("common:forms:fields:max_children:placeholder")}
            errors={errors?.maxChildren?.message}
            control={control}
          />

          <FormInputNumber
            name="numberOfBeds"
            label={t("common:terms:number_of_beds")}
            placeholder={t("common:forms:fields:number_of_beds:placeholder")}
            errors={errors?.numberOfBeds?.message}
            control={control}
          />

          <FormInputNumber
            name="numberOfBedrooms"
            label={t("common:terms:number_of_bedrooms")}
            placeholder={t("common:forms:fields:number_of_bedrooms:placeholder")}
            errors={errors?.numberOfBedrooms?.message}
            control={control}
          />

          <FormInputNumber
            name="numberOfBathrooms"
            label={t("common:terms:number_of_bathrooms")}
            placeholder={t("common:forms:fields:number_of_bathrooms:placeholder")}
            errors={errors?.numberOfBathrooms?.message}
            control={control}
          />

          <Legend>{t("listings:headings:amenities_and_rules")}</Legend>

          <FormSelect
            name="amenities"
            label={t("common:terms:amenities")}
            placeholder={t("common:forms:fields:amenities:placeholder")}
            options={amenitiesOptions}
            mode="multiple"
            loading={amenitiesQuery.isLoading}
            control={control}
          />

          <FormCheckbox
            name="petsAllowed"
            label={t("common:terms:pets_allowed")}
            control={control}
          />

          <FormCheckbox
            name="smokingAllowed"
            label={t("common:terms:smoking_allowed")}
            control={control}
          />

          <FormCheckbox
            name="partiesAllowed"
            label={t("common:terms:parties_allowed")}
            control={control}
          />

          <FormTextarea
            name="houseRules"
            label={t("common:terms:house_rules")}
            placeholder={t("common:forms:fields:house_rules:placeholder")}
            errors={errors?.houseRules?.message}
            control={control}
          />

          <Legend>{t("listings:headings:check_in_out")}</Legend>

          <FormTextarea
            name="checkInInstructions"
            label={t("common:terms:check_in_instructions")}
            placeholder={t("common:forms:fields:check_in_instructions:placeholder")}
            errors={errors?.checkInInstructions?.message}
            control={control}
          />

          <HourSelector
            name="checkInStartTime"
            label={t("common:terms:check_in_start_time")}
            placeholder={t("common:forms:fields:check_in_start_time:placeholder")}
            control={control}
          />

          <HourSelector
            name="checkInEndTime"
            label={t("common:terms:check_in_end_time")}
            placeholder={t("common:forms:fields:check_in_end_time:placeholder")}
            control={control}
          />

          <HourSelector
            name="checkOutStartTime"
            label={t("common:terms:check_out_start_time")}
            placeholder={t("common:forms:fields:check_out_start_time:placeholder")}
            control={control}
          />

          <HourSelector
            name="checkOutEndTime"
            label={t("common:terms:check_out_end_time")}
            placeholder={t("common:forms:fields:check_out_end_time:placeholder")}
            control={control}
          />

          <Legend>{t("common:headings:photos")}</Legend>
          {photos?.length < 5 && (
            <Alert message={t("listings:errors:required_photos_count", { count: 5 })} type="warning" />
          )}
          <Photos
            name="photos"
            errors={errors?.photos}
            control={control}
          />

          <Rooms
            name="rooms"
            errors={errors?.rooms}
            control={control}
          />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton onClick={handleSubmit} loading={isLoading}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
