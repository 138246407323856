import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import transformMappingsToArray from "../utils/transform_mappings_to_array";
import transformMappingsToObject from "../utils/transform_mappings_to_object";

export const useAutoMapper = ({ onChangeMapping, mappings }) => {
  const [suggestedMappings, setSuggestedMappings] = useState([]);

  const mappingsRef = React.useRef(mappings);
  const suggestedMappingsRef = React.useRef(suggestedMappings);

  useEffect(() => {
    mappingsRef.current = mappings;
  }, [mappings]);

  useEffect(() => {
    suggestedMappingsRef.current = suggestedMappings;
  }, [suggestedMappings]);

  const suggestMappings = ({ ratePlanId, newMapping, mappings: inMappings, mappingOptions, ratePlans }) => {
    if (!mappingOptions.pricing_type === "OBP") {
      return;
    }
    const mappingsAsArray = transformMappingsToArray(inMappings);
    const externalRoom = mappingOptions.rooms.find((room) => room.id === newMapping.room_type_code);
    const externalRate = externalRoom.rates.find((rate) => rate.id === newMapping.rate_plan_code);

    const isFirstMappingForExternalRateInRoom = mappingsAsArray
      .filter((mapping) => mapping.mapping.room_type_code === newMapping.room_type_code)
      .every((mapping) => mapping.mapping.rate_plan_code !== newMapping.rate_plan_code);

    if (!isFirstMappingForExternalRateInRoom) {
      return;
    }

    const isExternalRateOccupancyBased = !!externalRate.occupancies;

    if (!isExternalRateOccupancyBased) {
      return;
    }

    const ratePlan = ratePlans.find((rp) => rp.id === ratePlanId);
    const isRatePlanOccupancyBased = ratePlan.sell_mode === "per_person";
    if (!isRatePlanOccupancyBased) {
      return;
    }

    const isOccupancyMatches = ratePlan.occupancy === newMapping.occupancy;
    if (!isOccupancyMatches) {
      return;
    }

    const ratePlansWithDifferentOccupancies = ratePlans.filter((rp) => {
      const isSameRoom = rp.room_type_id === ratePlan.room_type_id;
      const isSameTitle = rp.title === ratePlan.title;

      return isSameRoom && isSameTitle;
    })
      .sort((a, b) => a.occupancy - b.occupancy);

    if (ratePlansWithDifferentOccupancies.length < 2) {
      return;
    }

    const externalRateOccupancies = externalRate.occupancies;
    if (externalRateOccupancies.length < 2) {
      return;
    }

    const maxExternalRateOcc = Math.max(...externalRateOccupancies);
    const maxRateOcc = Math.max(...ratePlansWithDifferentOccupancies.map((rp) => rp.occupancy));

    const maxOccupancyToSuggest = Math.min(maxExternalRateOcc, maxRateOcc);

    const newSuggestedMappings = Array.from({ length: maxOccupancyToSuggest }, (_el, index) => {
      const targetOccupancy = index + 1;

      // we should skip mapping occupancy used for suggestion
      if (targetOccupancy === newMapping.occupancy) {
        return null;
      }

      const suggestedRatePlan = ratePlansWithDifferentOccupancies.find((rp) => rp.occupancy === targetOccupancy);

      return {
        id: uuidv4(),
        rate_plan_id: suggestedRatePlan.id,
        room_type_code: newMapping.room_type_code,
        rate_plan_code: newMapping.rate_plan_code,
        occupancy: targetOccupancy,
      };
    })
      .filter((mapping) => mapping !== null);

    setSuggestedMappings(newSuggestedMappings);

    return newSuggestedMappings.length;
  };

  const rejectAllSuggestions = () => {
    setSuggestedMappings([]);
  };

  const acceptAllSuggestions = () => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);
    const suggestedMappingsVal = suggestedMappingsRef.current;

    const newSuggestedMappings = suggestedMappingsVal.map(({ rate_plan_id, room_type_code, rate_plan_code, occupancy }) => ({
      ratePlanId: rate_plan_id,
      mapping: {
        rate_plan_code,
        room_type_code,
        occupancy,
      },
    }));

    setSuggestedMappings([]);

    onChangeMapping(transformMappingsToObject([...mappingsAsArray, ...newSuggestedMappings]));
  };

  const rejectSuggestion = (suggestionId) => {
    const newSuggestedMappings = suggestedMappingsRef.current.filter((suggestion) => suggestion.id !== suggestionId);
    setSuggestedMappings(newSuggestedMappings);
  };

  const acceptSuggestion = (suggestionId) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);
    const suggestedMappingsVal = suggestedMappingsRef.current;

    const suggestion = suggestedMappingsVal.find((s) => s.id === suggestionId);

    const newSuggestedMappings = suggestedMappingsRef.current.filter((s) => s.id !== suggestionId);

    setSuggestedMappings(newSuggestedMappings);

    onChangeMapping(
      transformMappingsToObject([
        ...mappingsAsArray,
        {
          ratePlanId: suggestion.rate_plan_id,
          mapping: {
            rate_plan_code: suggestion.rate_plan_code,
            room_type_code: suggestion.room_type_code,
            occupancy: suggestion.occupancy,
          },
        },
      ]),
    );
  };

  const cleanSuggestForMapping = (mapping) => {
    const { room_type_code, rate_plan_code, occupancy } = mapping;
    const newSuggestedMappings = suggestedMappingsRef.current.filter((suggestion) => {
      return !(suggestion.room_type_code === room_type_code && suggestion.rate_plan_code === rate_plan_code && suggestion.occupancy === occupancy);
    });

    setSuggestedMappings(newSuggestedMappings);
  };

  return {
    suggestedMappings,
    suggestMappings,
    acceptAllSuggestions,
    rejectAllSuggestions,
    acceptSuggestion,
    rejectSuggestion,
    cleanSuggestForMapping,
  };
};
