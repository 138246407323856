import React, { useRef } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";

import Gallery from "components/content_module/gallery";
import PhotoCard from "components/content_module/photo_card";
import { PhotoUploader } from "components/content_module/photo_uploader";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import useAppForm from "components/hook_form/use_app_form";

import styles from "./styles.module.css";

const PhotoMetaForm = ({ photo, onClose, onChange }) => {
  const { t } = useTranslation();

  const { handleSubmit, errors, control } = useAppForm({
    defaultValue: photo,
    fieldNames: ["caption"],
    submitHandler: async (formValue) => {
      onChange(formValue);
      onClose();
    },
  });

  return (
    <div>
      <GlobalErrors hookForm errors={errors} />

      <FormInput
        name="caption"
        label={t("photos:terms:caption")}
        placeholder={t("photos:forms:fields:caption:placeholder")}
        errors={errors?.caption?.message}
        control={control}
      />

      <Button type="primary" onClick={handleSubmit}>{t("general:action:save")}</Button>
    </div>
  );
};

const PhotoCaption = ({ photo, onUpdate }) => {
  const { t } = useTranslation();
  const { url, caption } = photo;

  const captionClass = classNames(styles.photoMeta, {
    [styles.photoMetaEmpty]: !caption,
  });

  const displayedCaption = caption || t("photos:terms:caption");
  const metaContent = (
    <PhotoMetaForm
      photo={photo}
      onChange={(values) => onUpdate(url, values)}
    />
  );

  return (
    <PhotoCard.Meta
      editable
      tooltipText={t("photos:messages:click_to_edit")}
      editForm={metaContent}
    >
      <div className={styles.photoMetaContainer}>
        <div className={captionClass}>{displayedCaption}</div>
        <EditOutlined />
      </div>
    </PhotoCard.Meta>
  );
};

const PhotoItem = ({ photo, onDelete, onMetaUpdate }) => {
  const { url } = photo;

  return (
    <PhotoCard
      draggable
      key={`photo-${url}`}
      photo={photo}
      onDelete={() => onDelete(url)}
    >
      <PhotoCaption
        photo={photo}
        onUpdate={onMetaUpdate}
      />
    </PhotoCard>
  );
};

export const Photos = ({ name, control }) => {
  const {
    field: { value = [], onChange },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  // for some reason handlePhotosAdd is memoized in child components and not receives the updated value
  // (with or without useCallback), maybe sort logic memoize components
  // use ref to get the updated value in callbacks
  const valueRef = useRef(value);
  valueRef.current = value;

  const handlePhotosAdd = (newPhotos = []) => {
    const photoObjects = newPhotos.map((url) => ({
      url,
      caption: null,
    }));

    onChange([...valueRef.current, ...photoObjects]);
  };

  const handlePhotoDelete = (urlToDelete) => {
    const photoIndex = valueRef.current.findIndex(({ url }) => url === urlToDelete);
    const photo = valueRef.current[photoIndex];

    if (!photo) {
      return;
    }

    const updatedPhotos = [...valueRef.current];
    updatedPhotos.splice(photoIndex, 1);

    onChange(updatedPhotos);
  };

  const handlePhotoMetaUpdate = (url, newValues) => {
    const updatedPhotos = valueRef.current.map((photo) => (photo.url === url ? { ...photo, ...newValues } : photo));

    onChange(updatedPhotos);
  };

  const handlePhotosReorder = ({ oldIndex, newIndex }) => {
    const newValue = [...valueRef.current];
    const [movedPhoto] = newValue.splice(oldIndex, 1);
    newValue.splice(newIndex, 0, movedPhoto);

    onChange([...newValue]);
  };

  return (
    <Gallery
      uploadButton={
        <PhotoUploader
          multiple
          button={<PhotoCard.UploadButton />}
          onChange={handlePhotosAdd}
        />
      }
      onSortEnd={handlePhotosReorder}
    >
      {value.map((photo) => (
        <PhotoItem
          key={photo.url}
          photo={photo}
          onDelete={handlePhotoDelete}
          onMetaUpdate={handlePhotoMetaUpdate}
        />
      ))}
    </Gallery>
  );
};
