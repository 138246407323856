import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

import styles from "./styles.module.css";

export const AddButton = ({ children, ...rest }) => {
  return (
    <Button
      className={styles.button}
      type="dashed"
      icon={<PlusOutlined />}
      {...rest}
    >
      {children}
    </Button>
  );
};
