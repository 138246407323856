import React from "react";
import { Form } from "antd";
import snakeCase from "lodash/snakeCase";

import getFieldLayout from "../../../utils/get_field_layout";

const FormItem = Form.Item;

export const AntdFormField = ({ children, ...props }) => {
  const {
    name,
    label,
    withLayout = true,
    view,
    error,
  } = props;

  const dataCy = name ? snakeCase(`field_${name.replace(".", "_")}`) : null;

  return (
    <FormItem
      {...getLayoutProps(withLayout, view)}
      {...getValidationProps(error)}
      label={label}
      data-cy={dataCy}
    >
      {children}
    </FormItem>
  );
};

export const getAntdFieldProps = (props) => {
  if (props.error || props.errors) {
    throw new Error("AntdFormField does not accept error or errors props. Errors are handled by react-hook-form.");
  }

  return {
    name: props.name,
    label: props.label,
    withLayout: props.withLayout,
    view: props.view,
  };
};

function getValidationProps(error) {
  if (!error) {
    return {};
  }

  return {
    validateStatus: "error",
    help: <span data-cy="input_error_message">{error.message}</span>,
  };
}

function getLayoutProps(withLayout, view) {
  if (!withLayout) {
    return {};
  }

  return getFieldLayout(view);
}
