import * as yup from "yup";

import errorMessages from "config/constants/errors";

import { validationSchema as feeValidationSchema } from "./fees/validation_schema";
import { validationSchema as taxValidationSchema } from "./taxes/validation_schema";

export const validationSchema = yup.object().shape({
  currency: yup.string()
    .required(errorMessages.required()),
  pricingModel: yup.string()
    .required(errorMessages.required()),
  cancellationPolicy: yup.string().nullable(),
  fees: yup.array().of(
    feeValidationSchema,
  ),
  taxes: yup.array().of(
    taxValidationSchema,
  ),
});
