import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Cascader from "components/forms/inputs/form_cascader";
import FormItem from "components/forms/items/form_item";

import convertToHashmap from "utils/convert_to_hashmap";
import showErrorMessage from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import styles from "./contract_selector.module.css";

const convertContractsToOptions = (hotelId, contracts) => {
  return contracts.map(({ contract_name, contract_sequence }) => ({
    value: `${hotelId},${contract_sequence}`,
    label: `${contract_name} (${contract_sequence})`,
  }));
};

const convertHotelsToOptions = (hotels) => {
  return hotels.map(({ id, title, contracts }) => {
    const children = convertContractsToOptions(id, contracts);

    return {
      value: id,
      label: `${title} (${id})`,
      children,
    };
  });
};

const convertContractsToObject = (hotel) => ({
  ...hotel,
  contracts: convertToHashmap(hotel.contracts, "contract_sequence"),
});

const convertHotelsToObject = (hotels) => {
  const formattedHotels = hotels.map(convertContractsToObject);

  return convertToHashmap(formattedHotels);
};

const updateSettings = (selectedPath, settings, hotelsById) => {
  const [hotelId, contractId] = selectedPath.split(",");

  const selectedHotel = hotelsById[hotelId];
  const selectedContract = selectedHotel.contracts[contractId];
  const { incoming_office_code, contract_sequence, contract_start_date, contract_end_date, price_type } = selectedContract;

  return {
    ...settings,
    hotel_id: Number(hotelId),
    contract_sequence,
    incoming_office_code,
    contract_start_date,
    contract_end_date,
    price_type,
  };
};

const getDefaultValue = (settings) => {
  const { hotel_id, contract_sequence, contract_end_date } = settings || {};

  return hotel_id && contract_sequence
    ? { hotelId: hotel_id, contractSequence: contract_sequence, contractEndDate: contract_end_date }
    : null;
};

export function ContractSelector({ form, connectionSettings, onMappingDetailsLoad }) {
  const { t } = useTranslation();
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const { channel, settings } = form.values;
  const hasOptions = connectionSettings.hotels?.length;
  const isInputDisabled = !hasOptions || loading;
  const defaultValue = getDefaultValue(settings);
  const preselectedValue = defaultValue
    ? `${defaultValue.hotelId},${defaultValue.contractSequence}`
    : null;

  const options = useMemo(() => {
    const { hotels = [] } = connectionSettings;

    return convertHotelsToOptions(hotels);
  }, [connectionSettings]);

  const hotelsById = useMemo(() => {
    const { hotels = [] } = connectionSettings;

    return convertHotelsToObject(hotels);
  }, [connectionSettings]);

  const handleChange = (selectedPath) => {
    const updatedSettings = updateSettings(selectedPath, settings, hotelsById);

    form.setFieldValue("settings", updatedSettings);

    setLoading();

    onMappingDetailsLoad(updatedSettings, channel)
      .catch(showErrorMessage)
      .finally(setLoadingComplete);
  };

  let contractInfo = null;
  if (preselectedValue) {
    contractInfo = (
      <FormItem label={t("channels_page:form:contract_info")}>
        <div className={styles.placeholderContainer}>
          {!options.length && <div>{t("channels_page:form:select_contract_extra")}</div>}
          {defaultValue && (
            <>
              <div>{t("channels_page:form:hotel_id", defaultValue)}</div>
              <div>{t("channels_page:form:contract_sequence", defaultValue)}</div>
              <div>{t("channels_page:form:contract_end_date", defaultValue)}</div>
            </>
          )}
        </div>
      </FormItem>
    );
  }

  return (
    <>
      <Cascader
        label={t("channels_page:form:select_contract")}
        options={options}
        allowClear={false}
        changeOnSelect
        defaultValue={preselectedValue}
        disabled={isInputDisabled}
        onChange={handleChange}
      />
      {contractInfo}
    </>
  );
}
