import React from "react";
import { useController } from "react-hook-form";

export const buildHookFormInput = (InputComponent) => {
  return function HookFormInput({
    name,
    control,
    onFieldChange,
    onChange,
    ...inputProps
  }) {
    const {
      field: { onChange: fieldOnChange, ...fieldProps },
      fieldState,
    } = useController({
      name,
      control,
    });

    if (onChange) {
      throw new Error("onChange prop is not allowed, use onFieldChange instead, it would be called after React Hook Form state update and doesnt change the state");
    }

    const handleChange = (event) => {
      fieldOnChange(event); // Update React Hook Form state
      onFieldChange?.(event); // Call custom handler if provided
    };

    return (
      <InputComponent
        {...fieldProps}
        {...fieldState}
        {...inputProps}
        onChange={handleChange}
      />
    );
  };
};
