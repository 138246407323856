import React from "react";
import { useTranslation } from "react-i18next";

import NoDataPlaceholder from "../../no_data_placeholder";

export const EmptyData = ({ emptyMessage, createMessageText, onCreateClick }) => {
  const { t } = useTranslation();

  return (
    <NoDataPlaceholder
      type="button"
      onClick={onCreateClick}
      emptyMessage={emptyMessage}
      createMessageText={createMessageText || t("general:create_first_before")}
      createMessageActionText={t("general:create_first_link")}
    />
  );
};
