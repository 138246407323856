import React, { useEffect, useState } from "react";
import { Col, Flex, Row } from "antd";
import classnames from "classnames";

import MappingStatus from "components/mapping/mapping_status";

import { MappingDeleteButton } from "./mapping_delete_button";
import MappingRowLoader from "./mapping_row_loader";

import styles from "./mapping_row.module.css";

const ROOM_STYLES = {
  row: styles.roomRow,
  title: styles.roomTitle,
};

const RATE_STYLES = {
  row: styles.rateRow,
  title: styles.rateTitle,
};

export default function MappingRow({
  className,
  type,
  title,
  action,
  loading,
  disabled,
  isMapped,
  isError,
  onDelete,
}) {
  const [activeStyleSet, setActiveStyleSet] = useState(ROOM_STYLES);

  useEffect(() => {
    let styleSet = type === "rate" ? RATE_STYLES : ROOM_STYLES;

    if (disabled) {
      styleSet = { ...styleSet, row: [styleSet.row, styles.disabledRow].join(" ") };
    }

    setActiveStyleSet(styleSet);
  }, [type, disabled]);

  const rowClassName = classnames(activeStyleSet.row, className, isError && styles.mappingRowError);

  return (
    <Row className={rowClassName}>
      <Col span={11} className={activeStyleSet.title}>
        {title}
      </Col>
      <Col span={1}>
        <MappingStatus isError={isError} />
      </Col>
      <Col span={10} className={styles.mappedRateCol}>
        {action}
      </Col>
      <Col span={2}>
        <Flex justify="flex-end">
          {loading ? (
            <MappingRowLoader />
          ) : (
            <MappingDeleteButton disabled={disabled} visible={isMapped} onClick={onDelete} />
          )}
        </Flex>
      </Col>
    </Row>
  );
}
