import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";

import CRUDTopBar from "components/crud_table/crud_top_bar";
import EmptySearchResultPlaceholder from "components/empty_search_result_placeholder";
import NoDataPlaceholder from "components/no_data_placeholder";

import styles from "./tab_table.module.css";

export default function TabTable(props) {
  const { t } = useTranslation();

  const {
    actions,
    loading,
    searchQuery,
    emptyMessage,
    createMessageText,
    dataSource,
    onCreate,
    onSearchChange,
    ...tableProps
  } = props;

  const placeholder = useMemo(() => {
    if (loading || !dataSource || dataSource.length) {
      return null;
    }

    if (searchQuery) {
      return <EmptySearchResultPlaceholder />;
    }

    return (
      <NoDataPlaceholder
        type="button"
        onClick={onCreate}
        emptyMessage={emptyMessage}
        createMessageText={createMessageText || t("general:create_first_before")}
        createMessageActionText={t("general:create_first_link")}
      />
    );
  }, [t, createMessageText, emptyMessage, loading, dataSource, searchQuery, onCreate]);

  return (
    <div className={styles.container}>
      <CRUDTopBar
        borderless
        actions={actions}
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
      />

      <div className={styles.tableContainer}>
        {dataSource?.length ? (
          <Table
            loading={loading}
            rowClassName={styles.tableRow}
            pagination={false}
            showHeader={false}
            dataSource={dataSource}
            {...tableProps}
          />
        ) : (
          placeholder
        )}
      </div>
    </div>
  );
}
