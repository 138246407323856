import { useCallback, useMemo } from "react";

import { transformMappingsToArray } from "../utils/transform_mappings_to_array";
import { transformMappingsToObject } from "../utils/transform_mappings_to_object";

import { buildMappingItems } from "./build_mapping_items";

const buildMappingsByListingId = (mappingsAsArray) => {
  return mappingsAsArray.reduce((acc, mapping) => {
    const { listing_id: listingId } = mapping.settings;

    acc[listingId] = mapping;

    return acc;
  }, {});
};

const buildUnknownMappingOptions = ({ mappingsAsArray, mappingOptions }) => {
  const listings = mappingOptions?.listing_id_dictionary?.values || [];

  const mappingOptionsById = listings.reduce((acc, listing) => {
    acc[listing.id] = { ...listing };
    return acc;
  }, {});

  const unknownMappingOptions = [];

  mappingsAsArray.forEach(({ settings }) => {
    const { listing_id: listingId } = settings;

    if (!mappingOptionsById[listingId]) {
      unknownMappingOptions.push({
        isInvalid: true,
        isRemoved: true,
        id: listingId,
        title: `${listingId}`,
      });
    }
  });

  return {
    listing_id_dictionary: {
      values: unknownMappingOptions,
    },
  };
};

export const useListingMapping = (sourceData) => {
  const {
    mappings,
    properties,
    ratePlans,
    roomTypes,
    onChangeMapping,
  } = sourceData;

  // in channel management for failed requests mappingOptions is set to null
  const mappingOptions = useMemo(() => sourceData.mappingOptions || {}, [sourceData.mappingOptions]);
  const mappingsAsArray = useMemo(() => transformMappingsToArray(mappings), [mappings]);

  const unknownMappingOptions = useMemo(
    () => buildUnknownMappingOptions({ mappingsAsArray, mappingOptions }),
    [mappingsAsArray, mappingOptions],
  );

  const mappingsPerListingId = useMemo(() => buildMappingsByListingId(mappingsAsArray), [mappingsAsArray]);

  const mappingItems = useMemo(
    () => buildMappingItems({ mappingOptions, unknownMappingOptions, mappingsPerListingId, properties, roomTypes, ratePlans }),
    [mappingOptions, unknownMappingOptions, mappingsPerListingId, properties, roomTypes, ratePlans],
  );

  const handleRateMappingChange = useCallback(({ mapping: newMapping, prevMapping }) => {
    let newMappings = mappingsAsArray;

    if (prevMapping) {
      newMappings = mappingsAsArray.map((mapping) => {
        const isMappingMatch = mapping.settings.listing_id === newMapping.settings.listing_id;

        if (isMappingMatch) {
          return newMapping;
        }

        return mapping;
      });
    } else {
      newMappings.push(newMapping);
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, onChangeMapping]);

  const handleRateMappingDelete = useCallback(({ mapping }) => {
    const mappingItemSettings = mapping.settings;

    let newMappings = mappingsAsArray;

    newMappings = newMappings.filter(({ settings }) => {
      const isMappingMatch = settings.listing_id === mappingItemSettings.listing_id;

      return !isMappingMatch;
    });

    const mappingsAsObject = transformMappingsToObject(newMappings);

    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, onChangeMapping]);

  return {
    mappingItems,
    handleRateMappingChange,
    handleRateMappingDelete,
  };
};
