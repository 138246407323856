import _ from "lodash";
import store from "store";

import channelCodes from "config/constants/channels/channel_codes";
import { CONTRACT_SEQUENCE_CHANNELS } from "config/constants/channels/contract_sequence_channels";
import currencyRestrictedChannels from "config/constants/channels/currency_restricted_channels";

import { channelsApiClient } from "../../../store/api_clients/channels";

import getChannelMultiOccupancyStatus from "./get_channel_multi_occupancy_status";

const { Channels, RatePlans, RoomTypes } = store;

const RATES_SELECT_WITH_MAPPING_MODE = "RATES_SELECT_WITH_MAPPING_MODE";
const RATES_SELECT_ONLY_MODE = "RATES_SELECT_ONLY_MODE";

const USER_SOURCE_MAPPING_CHANNEL_CODES = ["VerticalBooking"];

const addMappingSourceAndCredentialsSource = (channel) => {
  return {
    ...channel,
    mappingSource: USER_SOURCE_MAPPING_CHANNEL_CODES.includes(channel.code)
      ? "user"
      : "api",
    credentialsSource: channel.code === channelCodes.AirBNB.code ? "oauth" : "form",
  };
};

const loadAvailableChannels = async () => {
  const response = await Channels.availableToConnect();

  return response.data.map(addMappingSourceAndCredentialsSource);
};

const filterSettingsParams = (settings, rateParams, channelCode) => {
  if (channelCodes.AirBNB.code === channelCode) {
    return _.pick(settings, [...Object.keys(rateParams), "occupancy", "primary_occ"]);
  }

  return _.pick(settings, Object.keys(rateParams));
};

const convertRatePlansToMappings = (ratePlans, rateParams, channelCode) => {
  return ratePlans.reduce((acc, el) => {
    const { rate_plan_id: ratePlanId, settings, id } = el;

    const mappingSettings = rateParams ? filterSettingsParams(settings, rateParams, channelCode) : settings;

    if ([channelCodes.AirBNB.code, channelCodes.HopperHomes.code].includes(channelCode)) {
      mappingSettings.id = id;
    }

    const existingMapping = acc[ratePlanId];

    if (existingMapping) {
      acc[ratePlanId] = Array.isArray(existingMapping)
        ? [...existingMapping, mappingSettings]
        : [existingMapping, mappingSettings];

      return acc;
    }

    acc[ratePlanId] = mappingSettings;
    return acc;
  }, {});
};

const getRatesSelectEnabledValue = (selectedChannel, modelSettings) => {
  let isRatesSelectEnabled = false;

  if (selectedChannel) {
    const hasRateParams = selectedChannel.rate_params !== null;
    const hasMappingMode = selectedChannel.mapping_mode !== null;
    isRatesSelectEnabled = hasRateParams || hasMappingMode;
  }

  if (selectedChannel && modelSettings && selectedChannel.credentialsSource === "oauth") {
    isRatesSelectEnabled = isRatesSelectEnabled && !modelSettings.token_invalid;
  }

  return isRatesSelectEnabled;
};

const getRatesSelectMode = (selectedChannel) => {
  const mappingMode = selectedChannel?.mapping_mode;

  switch (mappingMode) {
    case "listing":
    case "tree":
      return RATES_SELECT_WITH_MAPPING_MODE;
    default:
      return RATES_SELECT_ONLY_MODE;
  }
};

const getPreselectedProperties = (activeProperty, propertiesOptions) => {
  let properties = [];

  if (propertiesOptions.length === 1) {
    properties = [propertiesOptions[0].id];
  }

  if (activeProperty) {
    properties = [activeProperty];
  }

  return properties;
};

const getDefaultGroupIdForNewChannel = ({ activeGroup, activeProperty, groups, propertiesOptions }) => {
  // if some group selected by user - use it
  if (activeGroup) {
    return activeGroup;
  }

  // also some property can be selected by user, if no property selected - use first group from all available groups
  if (!activeProperty) {
    return groups[0].id;
  }

  // get selected property groups and use first one
  const selectedProperty = propertiesOptions.find((el) => el.id === activeProperty);

  // fallback: if property not found - use first group from all available groups
  if (!selectedProperty) {
    return groups[0].id;
  }

  // fallback: if property has no groups - use first group from all available groups
  if (!selectedProperty.group_ids || selectedProperty.group_ids.length === 0) {
    return groups[0].id;
  }

  // use first group from selected property groups
  return selectedProperty.group_ids[0];
};

const buildChannel = (activeGroup, activeProperty, groups, propertiesOptions) => {
  const properties = getPreselectedProperties(activeProperty, propertiesOptions);
  const defaultGroupId = getDefaultGroupIdForNewChannel({ activeGroup, activeProperty, groups, propertiesOptions });

  return {
    group_id: defaultGroupId,
    mappingSource: null,
    properties,
    settings: {
      mappingSettings: {},
    },
    mappings: {},
    known_mappings_list: [],
  };
};

const loadChannel = async (id) => {
  const response = await Channels.find(id);

  return response.data.attributes;
};

const loadRoomTypes = async (propertyIds) => {
  const filter = propertyIds.length > 0 ? { property_id: propertyIds } : {};

  if (!propertyIds.length) {
    return null;
  }

  const roomTypes = await RoomTypes.options(filter);
  return roomTypes;
};

const loadConnectionDetails = async (id, model) => {
  let connectionSettings = {};

  if (id && (currencyRestrictedChannels.includes(model.channel) || CONTRACT_SEQUENCE_CHANNELS.includes(model.channel))) {
    const { settings, channel } = model;

    try {
      const { attributes } = await Channels.getConnectionsDetails({ settings, channel });

      connectionSettings = attributes;
    } catch (error) {
      if (!error.isBadRequest) {
        throw error;
      }
    }
  }

  return connectionSettings;
};

const loadRatePlans = async (model) => {
  const isExistingChannelWithProperty = model.properties.length && model.id;

  let ratePlans = null;

  if (isExistingChannelWithProperty) {
    const propertyIds = model.properties;
    const loadMultiOccupancyPlans = getChannelMultiOccupancyStatus(model.channel);
    const requestArgs = { property_id: propertyIds };

    ratePlans = await RatePlans.options(requestArgs, { multi_occupancy: loadMultiOccupancyPlans });
  }

  return ratePlans;
};

export const loadChannelWithMapping = async (
  id,
  activeGroup,
  activeProperty,
  groups,
  propertiesOptions,
) => {
  let model;
  let selectedChannel = null;
  let availableChannels;

  if (id) {
    model = await loadChannel(id);
    selectedChannel = await channelsApiClient.getChannelAdapterByCode(model.channel);
    selectedChannel = addMappingSourceAndCredentialsSource(selectedChannel);
    availableChannels = [selectedChannel];

    if (model.rate_plans) {
      model.mappings = convertRatePlansToMappings(
        model.rate_plans,
        selectedChannel.rate_params,
        selectedChannel.code,
      );
    }
    model.mappingSource = selectedChannel.mappingSource;
  } else {
    availableChannels = await loadAvailableChannels();
    model = buildChannel(activeGroup, activeProperty, groups, propertiesOptions);
  }

  const ratesSelectMode = getRatesSelectMode(selectedChannel);
  const isRatesSelectEnabled = getRatesSelectEnabledValue(selectedChannel, model.settings);

  const [roomTypes, ratePlans, connectionSettings] = await Promise.all([
    loadRoomTypes(model.properties),
    loadRatePlans(model),
    loadConnectionDetails(id, model),
  ]);

  return {
    id,
    availableChannels,
    selectedChannel,
    isRatesSelectEnabled,
    ratesSelectMode,
    model,
    roomTypes,
    ratePlans,
    connectionSettings,
  };
};
