import { useCallback, useMemo } from "react";

import { buildMappingsPerExternalRoom } from "../../_common/build_mappings_per_external_room";
import { buildUnknownMappingOptions } from "../../_common/build_unknown_mapping_options";
import { transformMappingsToArray } from "../../_common/utils/transform_mappings_to_array";
import { transformMappingsToObject } from "../../_common/utils/transform_mappings_to_object";

import { buildMappingItems } from "./build_mapping_items";

export const useMapping = (sourceData) => {
  const {
    mappings,
    mappingOptions,
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  } = sourceData;

  mappingSettings.rooms = mappingSettings.rooms || {};
  mappingOptions.rooms = mappingOptions.rooms || [];

  const mappingsAsArray = useMemo(() => transformMappingsToArray(mappings), [mappings]);
  const mappingsPerExternalRoom = useMemo(() => buildMappingsPerExternalRoom(mappingsAsArray), [mappingsAsArray]);

  const unknownMappingOptions = useMemo(
    () => buildUnknownMappingOptions({ mappingsAsArray, mappingOptions, mappingSettings }),
    [mappingsAsArray, mappingOptions, mappingSettings],
  );

  const mappingItems = useMemo(
    () => buildMappingItems({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans }),
    [mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans],
  );

  const handleRoomMappingChange = useCallback(({ externalRoom, mapping: newMapping, prevMapping }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: newMapping.roomTypeId,
      },
    };

    // if mapping for room was changed we need to remove all rate mappings for that room
    if (prevMapping) {
      const newMappings = mappingsAsArray.filter((mapping) => {
        return mapping.settings.room_type_code !== externalRoom.id;
      });

      if (newMappings.length !== mappingsAsArray.length) {
        onChangeMapping(transformMappingsToObject(newMappings));
      }
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeMapping, onChangeSettings]);

  const handleRoomMappingDelete = useCallback(({ externalRoom }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: null,
      },
    };

    // remove all rate mapping for that room
    const newMappings = mappingsAsArray.filter((mapping) => {
      return mapping.settings.room_type_code !== externalRoom.id;
    });

    if (newMappings.length !== mappingsAsArray.length) {
      onChangeMapping(transformMappingsToObject(newMappings));
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeSettings, onChangeMapping]);

  const handlePrimaryRateChange = useCallback(({ mapping: newPrimaryRateMapping }) => {
    // room can have only one primary rate
    const newMappings = mappingsAsArray.map((mapping) => {
      // reset is_primary for all rate plans in room
      if (newPrimaryRateMapping.settings.room_type_code === mapping.settings.room_type_code) {
        mapping.settings.is_primary = false;
      }

      // set specified rate as primary
      if (newPrimaryRateMapping.settings.rate_plan_code === mapping.settings.rate_plan_code && newPrimaryRateMapping.settings.room_type_code === mapping.settings.room_type_code) {
        mapping.settings.is_primary = true;
      }

      return mapping;
    });

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, onChangeMapping]);

  const handleRateMappingChange = useCallback(({ mapping: newMapping, prevMapping }) => {
    let newMappings = mappingsAsArray;

    if (prevMapping) {
      newMappings = mappingsAsArray.map((mapping) => {
        if (mapping.settings.rate_plan_code === newMapping.settings.rate_plan_code && mapping.settings.room_type_code === newMapping.settings.room_type_code) {
          return newMapping;
        }

        return mapping;
      });
    } else {
      const mappingsInExternalRoom = Object.values(
        mappingsPerExternalRoom?.[newMapping.settings.room_type_code] || {},
      );
      const isPrimaryExistsInRatesMappings = mappingsInExternalRoom.find((ms) => ms.settings.is_primary);

      if (!isPrimaryExistsInRatesMappings) {
        newMapping.settings.is_primary = true;
      }

      newMappings.push(newMapping);
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingsPerExternalRoom, onChangeMapping]);

  const handleRateMappingDelete = useCallback(({ mapping }) => {
    const mappingItemSettings = mapping.settings;

    const newMappings = mappingsAsArray.filter(({ settings }) => !(settings.rate_plan_code === mappingItemSettings.rate_plan_code && settings.room_type_code === mappingItemSettings.room_type_code));

    const isDeletedPrimaryRate = mapping.settings.is_primary;

    if (isDeletedPrimaryRate) {
      const externalRoomMappedRates = newMappings.filter(({ settings }) => settings.room_type_code === mappingItemSettings.room_type_code);
      if (externalRoomMappedRates.length > 0) {
        const externalRoom = mappingOptions.rooms.find((room) => room.id === mappingItemSettings.room_type_code);
        const externalRoomRates = externalRoom.rates;

        const firstMappedRateInRoom = externalRoomMappedRates
          .sort((a, b) => {
            const ratePlanCodeA = a.settings.rate_plan_code;
            const ratePlanCodeB = b.settings.rate_plan_code;
            const indexA = externalRoomRates.findIndex((item) => item.id === ratePlanCodeA);
            const indexB = externalRoomRates.findIndex((item) => item.id === ratePlanCodeB);
            return indexA - indexB;
          })[0];

        firstMappedRateInRoom.settings.is_primary = true;
      }
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);

    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingOptions, onChangeMapping]);

  return {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handlePrimaryRateChange,
    handleRateMappingChange,
    handleRateMappingDelete,
  };
};
