import React from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Row } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormInputNumber from "components/forms/inputs/hook_form/form_input_number";
import InputGroup from "components/forms/inputs/input_group/input_group";
import Legend from "components/forms/layout/legend/legend";

import { AddButton } from "../../../add_button/add_button";

const RoomConfiguration = ({ index, control, name, onRemove }) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.${index}.beds`,
  });

  const BED_TYPES = [
    { value: "bunk_bed", label: t("listings:dicts:bed_type:bunk_bed") }, // Bunk Bed
    { value: "cal_king", label: t("listings:dicts:bed_type:cal_king") }, // California King
    { value: "crib", label: t("listings:dicts:bed_type:crib") }, // Crib
    { value: "full", label: t("listings:dicts:bed_type:full") }, // Full
    { value: "king", label: t("listings:dicts:bed_type:king") }, // King
    { value: "queen", label: t("listings:dicts:bed_type:queen") }, // Queen
    { value: "sofa_bed", label: t("listings:dicts:bed_type:sofa_bed") }, // Sofa Bed
    { value: "twin", label: t("listings:dicts:bed_type:twin") }, // Twin
    { value: "twin_xl", label: t("listings:dicts:bed_type:twin_xl") }, // Twin XL
  ];

  return (
    <Card
      title={t("listings:terms:room_with_number", { number: index + 1 })}
      extra={
        <Button
          style={{ paddingRight: 0 }}
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => onRemove()}
          danger
        >
          {t("general:action:remove")}
        </Button>
      }
      size="small"
      style={{ marginBottom: "20px" }}
    >
      <FormInput
        label={t("common:terms:name")}
        name={`${name}.${index}.name`}
        control={control}
        placeholder={t("common:forms:fields:name:placeholder")}
      />

      {fields.map((field, bedIndex) => (
        <InputGroup
          key={field.id}
          noBottomMargin
          noLabel={bedIndex !== 0}
          label={t("listings:terms:bed_configurations")}
        >
          <FormSelect
            style={{ width: "100%", marginBottom: 0 }}
            name={`${name}.${index}.beds.${bedIndex}.bedType`}
            placeholder={t("listings:forms:fields:bed_type:placeholder")}
            withLayout={false}
            options={BED_TYPES}
            control={control}
          />
          <FormInputNumber
            grouped
            style={{ marginBottom: 0 }}
            name={`${name}.${index}.beds.${bedIndex}.count`}
            control={control}
            placeholder={t("listings:forms:fields:bed_count:placeholder")}
            min={1}
          />
          <Flex style={{ height: "40px" }} align="center">
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => append({ bedType: null, count: null })}
            />
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => remove(bedIndex)}
              danger
            />
          </Flex>
        </InputGroup>
      ))}

      {fields.length === 0 && (
        <Row>
          <Col {...horizontalFormItemLayout.labelCol} />
          <Col style={{ paddingTop: 4 }} flex="auto">
            <AddButton onClick={() => append({ bedType: null, count: null })}>{t("listings:actions:add_bed")}</AddButton>
          </Col>
        </Row>
      )}
    </Card>
  );
};

const buildNewRoom = () => ({
  name: null,
  beds: [{ bedType: null, count: null }],
});

export const Rooms = ({ name, control }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    // padding bottom prevents margin collapse in the last child in scrollable container
    <div style={{ paddingBottom: 1 }}>
      <Legend>
        {t("listings:headings:rooms")}
      </Legend>

      {fields.map((field, index) => (
        <RoomConfiguration
          name={name}
          key={field.id}
          index={index}
          control={control}
          onRemove={() => remove(index)}
        />
      ))}
      <AddButton
        style={{ marginBottom: 20 }}
        block
        onClick={() => {
          append(buildNewRoom());
          // TODO: scroll to the bottom of the container
        }}
      >
        {t("listings:actions:add_room")}
      </AddButton>
    </div>
  );
};
