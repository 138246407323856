import React, { memo } from "react";
import classNames from "classnames";

import { MappingDeleteButton } from "components/mapping/mapping_row/mapping_delete_button";
import RateTitleWithId from "components/rates/title_with_id/title_with_id";

import { useHover } from "hooks/use_hover";

import { MappingRow } from "../../_common/mapping_row";

import { PrimaryToggle } from "./primary_toggle";

import styles from "../styles.module.css";

export const RateMappingRow = memo(({ mappingItem, mappingSchema, MappingEditor, onPrimaryOccChange, onChange, onDelete }) => {
  const { mapping, ratePlans, externalRate, isInvalid } = mappingItem;

  const [ref, isHovered] = useHover();

  const isMapped = !!mapping?.settings?.rate_plan_code;
  const isReadonly = externalRate.readonly;
  const isParentRoomMapped = externalRate.isParentRoomMapped || false;

  const isOccBased = typeof externalRate.occupancy !== "undefined";
  const isPrimary = mapping?.settings?.primary_occ;

  const ratePlansById = ratePlans.reduce((acc, ratePlan) => {
    acc[ratePlan.id] = ratePlan;
    return acc;
  }, {});

  return (
    <MappingRow
      ref={ref}
      isError={isInvalid}
      showMapping={isParentRoomMapped}
      source={(
        <div className={classNames(styles.column__withActions, styles.column__rate)}>
          <div className={styles.columnContent}>
            <RateTitleWithId type="external" rate={externalRate} showReadOnly />
          </div>
          {isOccBased && (
            <div className={classNames(styles.columnActions)}>
              <PrimaryToggle
                isTogglable={isHovered && isMapped}
                isPrimary={isPrimary}
                isReadonly={isReadonly}
                onClick={() => {
                  if (isPrimary) {
                    return false;
                  }

                  onPrimaryOccChange({ mapping });
                }}
              />
            </div>
          )}
        </div>
      )}
      target={(
        <div className={styles.column__withActions}>
          <div className={styles.columnContent}>
            <MappingEditor
              isInvalid={isInvalid}
              mapping={mapping}
              externalRate={externalRate}
              ratePlansById={ratePlansById}
              mappingSchema={mappingSchema}
              onChange={(newMapping) => {
                onChange({ externalRate, mapping: newMapping, prevMapping: mapping });
              }}
              onDelete={() => onDelete({ mapping })}
            />
          </div>
          <div className={styles.columnActions}>
            <MappingDeleteButton
              visible={!!mapping}
              onClick={() => onDelete({ mapping })}
            />
          </div>
        </div>
      )}
    />
  );
});
