import channelCodes from "config/constants/channels/channel_codes";
import { features } from "config/feature_flags";

import { HopperHomesListingsTab, hopperHomesTabPaneClassName } from "./hopper_homes/tabs/listings_tab";
import { Extras } from "./openshopping/extras";
import { bopperHomesConfig } from "./hopper_homes";

export function getChannelTabs({ channelCode, activeFeatureFlags, t }) {
  const channelTabs = {
    [channelCodes.HopperHomes.code]: {
      listings: {
        TabPaneClassName: hopperHomesTabPaneClassName,
        label: t("listings:headings:listings"),
        enabled: (model) => !!model?.id,
        Component: HopperHomesListingsTab,
      },
    },
  };

  if (activeFeatureFlags[features.EXTRAS_MANAGEMENT]) {
    channelTabs.OpenShopping = {
      extras: {
        label: t("common:terms:extras"),
        enabled: () => true,
        Component: Extras,
      },
    };
  }

  if (!channelCode) {
    return [];
  }

  if (!channelTabs[channelCode]) {
    return [];
  }

  const tabs = Object.entries(channelTabs[channelCode]).map(([key, value]) => {
    return ({
      key,
      ...value,
    });
  });

  return tabs;
}

export const channelsConfig = {
  [channelCodes.HopperHomes.code]: bopperHomesConfig,
};
