import { useTranslation } from "react-i18next";

import { ChannexDrawerV2 } from "drawers/channex_drawer_v2";

import { ListingCheckoutTasks } from "./listing_checkout_tasks";

export const ListingCheckoutTasksDrawer = ({ listing, visible, channelId, onClose }) => {
  const { t } = useTranslation();
  const title = t("channels_page:form:listing:checkout_tasks_drawer:title");

  return (
    <ChannexDrawerV2 title={title} visible={visible} onClose={onClose}>
      <ListingCheckoutTasks
        listing={listing}
        channelId={channelId}
        onFinish={onClose}
      />
    </ChannexDrawerV2>
  );
};
