import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Select } from "antd";
import classNames from "classnames";

import RatePlanTitle from "components/rates/title";

import selectStyles from "./mapping_select.module.css";

const SingleValue = ({ value, options }) => {
  const { t } = useTranslation();

  if (!value) {
    return t("channels_page:form:not_mapped");
  }

  const selectedOption = options.find((rt) => rt.id === value);

  if (!selectedOption) {
    return t("common:terms:unknown");
  }

  return <RatePlanTitle className={selectStyles.rateTitle} rate={selectedOption} />;
};

const ArrayValue = ({ value, options }) => {
  const { t } = useTranslation();

  if (!value.length) {
    return t("channels_page:form:not_mapped");
  }

  const selectedOptions = value.map((ratePlanId) => {
    const ratePlan = options.find((rt) => rt.id === ratePlanId);

    return ratePlan || { id: ratePlanId, title: t("common:terms:unknown") };
  });

  const optionsClass = selectedOptions.length > 1 ? selectStyles.conflictingOptions : "";

  return selectedOptions.map((option, index) => (
    <div className={optionsClass} key={index}>
      <RatePlanTitle className={selectStyles.rateTitle} rate={option} />
    </div>
  ));
};

const SuggestedValue = ({ suggestedRatePlanId, options }) => {
  const { t } = useTranslation();

  const selectedOption = options.find((rt) => rt.id === suggestedRatePlanId);

  if (!selectedOption) {
    return t("common:terms:unknown");
  }

  return (
    <RatePlanTitle className={selectStyles.suggest} rate={selectedOption} />
  );
};

const SelectValue = ({ value, options, suggestedRatePlanId }) => {
  if (suggestedRatePlanId) {
    return <SuggestedValue suggestedRatePlanId={suggestedRatePlanId} options={options} />;
  }

  if (Array.isArray(value)) {
    return <ArrayValue value={value} options={options} />;
  }

  return <SingleValue value={value} options={options} />;
};

const MappingSelect = ({
  value,
  options = [],
  suggestedRatePlanId,
  onChange,
  fullWidth = true,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const sortedOptions = useMemo(() => {
    return [...options].sort((rateA, rateB) => {
      const isTitleEqual = rateA.title.localeCompare(rateB.title);
      if (isTitleEqual) {
        return isTitleEqual;
      }
      return rateA.occupancy - rateB.occupancy;
    });
  }, [options]);

  const toggleSelect = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleSelect = useCallback((selectedValue) => {
    onChange(selectedValue);
    toggleSelect();
  }, [onChange, toggleSelect]);

  const handleBlur = useCallback(() => {
    toggleSelect();
  }, [toggleSelect]);

  const getPopupContainer = useCallback(() => containerRef.current, [containerRef]);

  const selectOptions = useMemo(() => {
    return sortedOptions.map((option) => (
      <Select.Option key={option.id} label={option.title}>
        <RatePlanTitle className={selectStyles.rateTitle} rate={option} />
      </Select.Option>
    ));
  }, [sortedOptions]);

  const selectValue = value || undefined;
  const isUnmapped = (selectValue && selectValue.length === 0) || !selectValue;
  const buttonClass = classNames(selectStyles.button, isOpen ? selectStyles.transparent : null);
  const containerClass = classNames(selectStyles.container, {
    [selectStyles.fullWidth]: fullWidth,
  });

  return (
    <div className={containerClass} ref={containerRef}>
      <Button
        type="link"
        className={buttonClass}
        onClick={toggleSelect}
        danger={isUnmapped}
      >
        <SelectValue
          value={value}
          suggestedRatePlanId={suggestedRatePlanId}
          options={options}
        />
      </Button>
      {isOpen && (
        <Select
          className={selectStyles.select}
          placeholder={t("channels_page:form:not_mapped")}
          value={selectValue}
          defaultOpen
          autoFocus
          getPopupContainer={getPopupContainer}
          size="small"
          showSearch
          onSelect={handleSelect}
          onBlur={handleBlur}
          filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
        >
          {selectOptions}
        </Select>
      )}
    </div>
  );
};

export default MappingSelect;
