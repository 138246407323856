import { useQuery } from "react-query";
import store from "store";

const { Applications } = store;

export default function useApplicationGenerateApiKey({ installation }, options = {}) {
  return useQuery(
    ["application_generate_api_key", installation],
    () => Applications.generateApiKey(installation),
    options,
  );
}
