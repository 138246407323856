import React from "react";

import FormSelect from "components/forms/inputs/formik/form_select";

export default function ({ t, schema, namespace, field, inputRef, handleFieldChange }) {
  const getTranslation = (option) => {
    const translation = t(`channels_page:form:settings_${field}:${option}`);

    if (translation === `channels_page:form:settings_${field}:${option}`) {
      return option;
    }

    return translation;
  };

  const options = schema.options?.map((option) => ({
    value: option,
    representation: getTranslation(option),
  })) || [];

  return (
    <FormSelect
      namespace={namespace}
      options={options}
      name={field}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      onChange={handleFieldChange}
      type={schema.type}
      inputRef={inputRef}
    />
  );
}
