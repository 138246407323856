export const buildRatePlansByRoomTypeId = ({ ratePlans }) => {
  return ratePlans.reduce((acc, ratePlan) => {
    if (Array.isArray(acc[ratePlan.room_type_id])) {
      acc[ratePlan.room_type_id].push(ratePlan);
    } else {
      acc[ratePlan.room_type_id] = [ratePlan];
    }

    return acc;
  }, {});
};
