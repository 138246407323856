import React from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import classNames from "classnames";

import FormInput from "components/forms/inputs/form_input";

import styles from "./input_search.module.css";

export default function InputSearch({ value, total, showTotal = true, searchFullWidth = false, actions, onChange }) {
  const { t } = useTranslation();

  const inputStyles = classNames(styles.input, {
    [styles.searchFullWidth]: searchFullWidth,
  });

  return (
    <>
      <FormInput
        grouped={searchFullWidth}
        className={inputStyles}
        type="text"
        allowClear
        value={value}
        icon={<SearchOutlined />}
        placeholder={t("general:search_field_placeholder")}
        onChange={onChange}
      />
      {showTotal && (
        <div className={styles.total}>
          {`${t("channels_page:form:listing_total_label")}: ${total}`}
        </div>
      )}
      {actions && actions}
    </>
  );
}
