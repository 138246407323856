import React from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";

export const Status = ({ listing }) => {
  const { t } = useTranslation();

  const { published, suspended } = listing;

  if (suspended) {
    return <Tag color="red">{t("channels_page:form:listing:suspended")}</Tag>;
  }

  if (!published) {
    return <Tag color="orange">{t("channels_page:form:listing:unpublished")}</Tag>;
  }

  return (
    <Tag color="green">{t("channels_page:form:listing:published")}</Tag>
  );
};
