import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().required(errorMessages.required()).typeError(errorMessages.required()).min(5, errorMessages.minLength(5)),
  description: yup.string().required(errorMessages.required()).typeError(errorMessages.required()).min(200, errorMessages.minLength(200)),
  propertyType: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  roomType: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  address: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  registrationNumber: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  city: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  state: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  postalCode: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  checkInInstructions: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  houseRules: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  checkInStartTime: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  checkOutEndTime: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
  amenities: yup.array()
    .required(errorMessages.required()).of(
      yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
    ).min(1, errorMessages.required()),
  numberOfBedrooms: yup.number().required(errorMessages.required()).typeError(errorMessages.number),
  numberOfBeds: yup.number().required(errorMessages.required()).typeError(errorMessages.number),
  numberOfBathrooms: yup.number().required(errorMessages.required()).typeError(errorMessages.number),
  maxAdults: yup.number().required(errorMessages.required()).typeError(errorMessages.number),
  latitude: yup.number().typeError(errorMessages.number).required(errorMessages.required()),
  longitude: yup.number().typeError(errorMessages.number).required(errorMessages.required()),

  bedConfigurationOptions: yup.array().of(yup.object().shape({
    bedDescriptions: yup.array().of(yup.object().shape({
      type: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
      size: yup.string().required(errorMessages.required()).typeError(errorMessages.required()),
      count: yup.number(errorMessages.number()).required(errorMessages.required()).typeError(errorMessages.number()),
    })),
  })),
});
