import { useController } from "react-hook-form";

import FormCountrySelectOriginal from "../form_country_select";

export const FormCountrySelect = ({ name, control, ...rest }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  return (
    <FormCountrySelectOriginal
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};
