import React from "react";
import { Typography } from "antd";
import { modal } from "app";
import i18n from "i18next";

const { Text } = Typography;

const Label = ({ icon, label, type }) => {
  const content = icon
    ? <Text>{icon} {label}</Text>
    : <Text>{label}</Text>;

  return (
    <div>
      <Text type={type}>
        {content}
      </Text>
    </div>
  );
};

export const menuItem = (options = {}) => {
  const { t } = i18n;

  const {
    label,
    key = "actions_menu_item",
    icon,
    confirmTitle,
    confirmContent,
    danger = false,
    disabled = false,
    confirm = false,
    onClick,
  } = options;

  const type = danger ? "danger" : null;

  const defaultConfirmTitle = t("general:dialog:confirmation:title");
  const defaultConfirmContent = t("general:dialog:confirmation:content");

  return {
    key,
    disabled,
    onClick: () => {
      if (!confirm) {
        return onClick();
      }

      modal.confirm({
        title: confirmTitle || defaultConfirmTitle,
        content: confirmContent || defaultConfirmContent,
        okButtonProps: {
          "data-cy": "modal_ok",
        },
        onOk: onClick,
      });
    },
    label: <Label icon={icon} label={label} type={type} />,
  };
};
