export const BodyContainer = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
    }}
  >
    {children}
  </div>
);
