import alphabetSort from "utils/alphabet_sort";

export const buildRoomTypeOptionsByExternalRoomId = ({ mappingOptions, unknownMappingOptions, roomTypes, externalRoomsMapping }) => {
  const mappedRoomTypes = Object.values(externalRoomsMapping);
  const mappedExternalRoomIds = Object.keys(externalRoomsMapping);

  const mappingState = {
    unmapped: roomTypes.filter((rt) => !mappedRoomTypes.includes(rt.id)),
    mapped: mappedExternalRoomIds.reduce((acc, mappedExternalRoomId) => {
      if (externalRoomsMapping[mappedExternalRoomId]) {
        acc[mappedExternalRoomId] = roomTypes.find((rt) => rt.id === externalRoomsMapping[mappedExternalRoomId]);
      }

      return acc;
    }, {}),
  };

  const externalRooms = [...mappingOptions.rooms, ...unknownMappingOptions.rooms];

  return externalRooms.reduce((acc, externalRoom) => {
    const options = [...mappingState.unmapped];

    if (mappingState.mapped[externalRoom.id]) {
      options.push(mappingState.mapped[externalRoom.id]);
    }

    acc[externalRoom.id] = options.sort(alphabetSort("title"));

    return acc;
  }, {});
};
