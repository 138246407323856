import { Form } from "antd";

import styles from "./styles.module.css";

export const StyledForm = ({ children, ...props }) => {
  return (
    <Form className={styles.form} {...props}>
      {children}
    </Form>
  );
};
