import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormCheckboxRaw from "components/forms/inputs/form_checkbox";

export function FormCheckbox({ name, control, ...inputProps }) {
  return (
    <Row>
      <Col span={24}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormCheckboxRaw
              hookForm
              defaultValue={field.value}
              {...inputProps}
              {...field}
            />
          )}
        />
      </Col>
    </Row>
  );
}
