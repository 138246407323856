import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BulbOutlined } from "@ant-design/icons";
import { Button, Flex, notification, Space } from "antd";

import Layouts from "components/layouts";
import MappingDirection from "components/mapping/mapping_direction";

import alphabetSort from "utils/alphabet_sort";
import { buildMultiOccupancyRatesList } from "utils/build_multioccupancy_rates_list";

import updatePrimaryOccValues from "./room_mapping/room_rates_mapping/update_primary_occ_values";
import RemovedRates from "./removed_rates";
import RemovedRooms from "./removed_rooms";
import RoomMapping from "./room_mapping";
import { updateReadonlyStatuses } from "./update_readonly_statuses";
import { useAutoMapper } from "./use_auto_mapper";

const OCCUPANCY_BASED_PRICING = "OBP";
const DEFAULT_MAPPING_SETTINGS = {};
const DEFAULT_MAPPING_OPTIONS = {};
const CHANNEL_WITH_REUSED_ROOMS = ["Traveloka"];

const MultiOccupancyMapping = ({
  mappings: propMappings,
  roomTypes,
  mappingSettings: propMappingSettings,
  mappingOptions: propMappingOptions,
  channelCode,
  ratePlans,
  channelTitle,
  onChangeMapping: propOnChangeMapping,
  onChangeSettings,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const automapper = useAutoMapper({ onChangeMapping: propOnChangeMapping, mappings: propMappings });

  const mappingOptions = propMappingOptions || DEFAULT_MAPPING_OPTIONS;
  const mappingSettings = propMappingSettings || DEFAULT_MAPPING_SETTINGS;

  const sortedRooms = useMemo(() => {
    const { rooms } = mappingOptions;

    const newRooms = Array.isArray(rooms) ? rooms.sort(alphabetSort("title")) : [];

    return newRooms.map((room) => {
      const { rates } = room;
      const sortedRates = Array.isArray(rates) ? rates.sort(alphabetSort("title")) : [];
      return { ...room, rates: sortedRates };
    });
  }, [mappingOptions]);

  const unmappedRoomsList = useMemo(() => {
    const { rooms: mappedRooms } = mappingSettings;
    const mappedRoomsEntities = Object.values(mappedRooms || {});

    if (!Array.isArray(roomTypes)) {
      return [];
    }

    return CHANNEL_WITH_REUSED_ROOMS.includes(channelCode)
      ? roomTypes
      : roomTypes.filter((roomType) => {
        return !mappedRoomsEntities.find((roomId) => roomId === roomType.id);
      });
  }, [channelCode, mappingSettings, roomTypes]);

  const removedRooms = useMemo(() => {
    const mappedRooms = mappingSettings.rooms || {};
    const { rooms: optionRooms = [] } = mappingOptions;

    const mappedRoomsIds = Object.keys(mappedRooms || {});

    return mappedRoomsIds
      .filter((roomId) => !optionRooms.some((room) => String(room.id) === roomId))
      .reduce((acc, roomId) => ({ ...acc, [roomId]: mappedRooms[roomId] }), {});
  }, [mappingOptions, mappingSettings]);

  const removedRatesTree = useMemo(() => {
    if (!Array.isArray(sortedRooms)) {
      return {};
    }

    return Object.keys(propMappings || {})
      .reduce((acc, rateId) => {
        const externalRate = propMappings[rateId];

        if (Array.isArray(externalRate)) {
          externalRate.filter(Boolean).forEach((rate) => {
            acc.push({ id: rateId, ...rate });
          });

          if (!externalRate) {
            return acc;
          }

          return acc;
        }

        acc.push({ ...externalRate, id: rateId });
        return acc;
      }, [])
      .filter((externalRate) => {
        const { rate_plan_code, room_type_code, occupancy } = externalRate;
        const mappedRoom = sortedRooms.find((room) => room.id === room_type_code);

        if (!mappedRoom) {
          return true;
        }

        const isMappedRateExist = mappedRoom.rates.some((rate) => {
          const isIdMatches = rate.id === rate_plan_code;
          const hasMultiOccupancies = Array.isArray(rate.occupancies) && rate.occupancies.length;

          const isSingleOccupancyRate = rate.price_1 && occupancy === 1;
          const isMappedRateOccupancyMatches = rate.max_persons === occupancy;

          const isOccupancyMatches = hasMultiOccupancies
            ? rate.occupancies.includes(occupancy)
            : isSingleOccupancyRate || isMappedRateOccupancyMatches;

          return isIdMatches && isOccupancyMatches;
        });

        return !isMappedRateExist;
      })
      .reduce((acc, externalRate) => {
        const channexRate = ratePlans.find((ratePlan) => ratePlan.id === externalRate.id);

        const { room_type_code } = externalRate;
        const { [room_type_code]: removedRoom = [] } = acc;

        const updatedRates = [...removedRoom, { ...externalRate, channexRate }];

        return { ...acc, [room_type_code]: updatedRates };
      }, {});
  }, [propMappings, ratePlans, sortedRooms]);

  const mappings = useMemo(() => {
    const mappingsWithArrayValue = Object.keys(propMappings || {}).reduce((acc, ratePlanId) => {
      const mapping = propMappings[ratePlanId];

      if (!Array.isArray(mapping)) {
        acc[ratePlanId] = [mapping];
        return acc;
      }

      acc[ratePlanId] = mapping;
      return acc;
    }, {});

    const externalRatesPerRoomId = sortedRooms.reduce((acc, room) => {
      const ratesList = buildMultiOccupancyRatesList(room.rates, t);
      return { ...acc, [room.id]: ratesList };
    }, {});

    return updateReadonlyStatuses(mappingsWithArrayValue, externalRatesPerRoomId);
  }, [propMappings, sortedRooms, t]);

  const onChangeMapping = useCallback((value) => {
    propOnChangeMapping(updatePrimaryOccValues(value));
  }, [propOnChangeMapping]);

  const handleAddMapping = useCallback((ratePlanId, newMapping) => {
    // when user explicitly maps rate plan (from suggest or other rate plan)
    // we should erase suggestion for this mapping, because user made his choice and we should not suggest it again
    automapper.cleanSuggestForMapping(newMapping);

    const suggestsCount = automapper.suggestMappings({
      ratePlanId,
      newMapping,
      mappings: propMappings,
      mappingOptions: propMappingOptions,
      roomTypes,
      ratePlans,
    });

    if (suggestsCount > 0) {
      notificationApi.info({
        icon: <BulbOutlined />,
        key: "mapping-suggestions",
        message: t("channels:terms:automapper"),
        placement: "bottomRight",
        description: (
          <Space direction="vertical">
            <div>
              {
                t("channels:messages:automapper_suggest", { count: suggestsCount }) // Automapper has suggested {suggestsCount} new mappings based on occupancy. Would you like to accept them?
              }
            </div>
            <Flex justify="flex-end" gap={8}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  automapper.acceptAllSuggestions();
                  notificationApi.destroy("mapping-suggestions");
                }}
              >
                {t("common:actions:accept")}
              </Button>
              <Button
                danger
                size="small"
                onClick={() => {
                  automapper.rejectAllSuggestions();
                  notificationApi.destroy("mapping-suggestions");
                }}
              >
                {t("common:actions:reject")}
              </Button>
            </Flex>
          </Space>
        ),
        duration: 5,
        showProgress: true,
        pauseOnHover: true,
      });
    } else {
      notificationApi.destroy("mapping-suggestions");
    }
  }, [automapper, notificationApi, propMappings, propMappingOptions, roomTypes, ratePlans, t]);

  const isOccupancyBased = mappingOptions.pricing_type === OCCUPANCY_BASED_PRICING;

  return (
    <Layouts.WithRefresh onRefresh={onRefresh}>
      <MappingDirection channelTitle={channelTitle} reverse />
      {sortedRooms.map((room) => (
        <RoomMapping
          key={room.id}
          mappings={mappings}
          room={room}
          roomTypes={roomTypes}
          unmappedRooms={unmappedRoomsList}
          ratePlans={ratePlans}
          isOccupancyBased={isOccupancyBased}
          mappingSettings={mappingSettings}
          mappingOptions={mappingOptions}
          automapper={automapper}
          onChangeMapping={onChangeMapping}
          onChangeSettings={onChangeSettings}
          onAddMapping={handleAddMapping}
        />
      ))}
      <RemovedRooms
        removedRooms={removedRooms}
        mappingSettings={mappingSettings}
        roomTypes={roomTypes}
        onChangeSettings={onChangeSettings}
      />
      <RemovedRates
        ratesTree={removedRatesTree}
        mappings={mappings}
        onChangeMapping={onChangeMapping}
      />
      {contextHolder}
    </Layouts.WithRefresh>
  );
};

MultiOccupancyMapping.MAPPING_TYPE = "ExternalRoomRate";

export default MultiOccupancyMapping;
