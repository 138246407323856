import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DisconnectOutlined, DownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Modal, Table } from "antd";
import store from "store";
import { getUserApiKeysList } from "store/storage/selectors/user_api_keys_selector";

import NoDataPlaceholder from "components/no_data_placeholder";

import alphabetSort from "utils/alphabet_sort";
import dayjs from "utils/dayjs";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import ManageApiKeyPropertiesModal from "../manage_api_key_properties_modal";
import UserApiKeysCreateModal from "../user_api_keys_create_modal";

import generalStyles from "../../../styles/general.module.css";
import styles from "./user_api_keys.module.css";

const { UserApiKeys } = store;
const ACTION_COLUMNS_WIDTH = 200;
const TABLE_ROW_KEY = "id";

const DATE_FORMAT = "formats:date_time_with_seconds";

export default function UserApiKeysTable() {
  const { t } = useTranslation();
  const userApiKeys = useSelector(getUserApiKeysList);
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(true);
  const [isCreateModalOpen, setCreateModalOpen, setCreateModalClose] = useBoolState(false);
  const [
    isManagePropertiesModalOpen,
    setManagePropertiesModalOpen,
    setManagePropertiesModalClose,
  ] = useBoolState(false);
  const [managedAPIKeyID, setManagedAPIKeyID] = useState(null);

  const loadApiKeys = useCallback(() => {
    setTrueLoading();

    UserApiKeys.list()
      .catch((error) => showErrorMessageFromResponse(error))
      .finally(setFalseLoading);
  }, [setTrueLoading, setFalseLoading]);

  const onWithdraw = useCallback((keyId) => {
    return () => UserApiKeys.withdraw(keyId);
  }, []);

  const handleWithdraw = useCallback(
    (keyId) => {
      Modal.confirm({
        title: t("user_api_keys:withdraw_confirmation:title"),
        content: (
          <div data-cy="modal_content">{t("user_api_keys:withdraw_confirmation:description")}</div>
        ),
        onOk: onWithdraw(keyId),
      });
    },
    [t, onWithdraw],
  );

  const handleManageProperties = useCallback(
    (keyId) => {
      setManagedAPIKeyID(keyId);
      setManagePropertiesModalOpen();
    },
    [setManagedAPIKeyID, setManagePropertiesModalOpen],
  );

  const handleManagePropertiesClose = useCallback(() => {
    setManagePropertiesModalClose();
    setTimeout(() => {
      setManagedAPIKeyID(null);
    }, 300);
  }, [setManagedAPIKeyID, setManagePropertiesModalClose]);

  const handleCreateApiKey = useCallback((val) => {
    return UserApiKeys.create(val);
  }, []);

  const handleSaveManagedProperties = useCallback(
    (val) => {
      return UserApiKeys.update(val.userApiKey.id, val).then((_result) => {
        handleManagePropertiesClose();
      });
    },
    [handleManagePropertiesClose],
  );

  useEffect(
    function initialLoading() {
      loadApiKeys();
    },
    [loadApiKeys],
  );
  const renderStatus = useCallback((val) => {
    const color = val ? "red" : "green";
    return <Badge color={color} />;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: t("user_api_keys:table_header:title"),
        key: "title",
        dataIndex: "title",
        sorter: alphabetSort("title"),
      },
      {
        title: t("user_api_keys:table_header:api_key"),
        key: "key",
        dataIndex: "key",
      },
      {
        title: t("user_api_keys:table_header:properties"),
        key: "isRestricted",
        dataIndex: "isRestricted",
        align: "center",
        render: (isRestricted) => (
          isRestricted ? t("user_api_keys:table_content:only_selected") : t("user_api_keys:table_content:all")
        ),
      },
      {
        title: t("user_api_keys:table_header:is_withdrawn"),
        key: "isWithdrawn",
        dataIndex: "isWithdrawn",
        align: "center",
        render: (value) => renderStatus(value),
      },
      {
        title: t("user_api_keys:table_header:inserted_at"),
        key: "insertedAt",
        dataIndex: "insertedAt",
        render: (value) => dayjs(value).format(t(DATE_FORMAT)),
      },
      {
        title: t("general:actions"),
        key: "actions",
        align: "right",
        width: ACTION_COLUMNS_WIDTH,
        render: (_value, record) => {
          const items = [];

          if (record.isRestricted) {
            items.push({
              key: "user_api_keys_table_actions_manage_properties",
              disabled: record.isWithdrawn,
              onClick: () => handleManageProperties(record.id),
              label: (
                <div>
                  <DisconnectOutlined /> {t("user_api_keys:table_actions:manage_properties")}
                </div>
              ),
            });
          }

          items.push({
            key: "user_api_keys_table_actions_withdraw",
            disabled: record.isWithdrawn,
            onClick: () => handleWithdraw(record.id),
            label: (
              <div>
                <DisconnectOutlined /> {t("user_api_keys:table_actions:withdraw")}
              </div>
            ),
          });

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a
                data-cy="crud_entry_actions_menu"
                className={generalStyles.actionsToggle}
                onClick={(e) => e.preventDefault()}
              >
                {t("general:actions")} <DownOutlined />
              </a>
            </Dropdown>
          );
        },
      },
    ];
  }, [t, handleManageProperties, handleWithdraw, renderStatus]);

  const isVisibleTable = userApiKeys?.length || loading;

  return (
    <main>
      <div className={styles.header}>
        <p className={styles.title}>{t("user_api_keys:header_title")}</p>
        <Button icon={<PlusCircleOutlined />} type="link" onClick={setCreateModalOpen}>
          {t("user_api_keys:create_btn")}
        </Button>
      </div>
      <div className={styles.tableContainer}>
        {isVisibleTable ? (
          <Table
            loading={loading}
            rowClassName={styles.tableRow}
            pagination={false}
            dataSource={userApiKeys}
            columns={columns}
            rowKey={TABLE_ROW_KEY}
          />
        ) : (
          <NoDataPlaceholder
            type="button"
            onClick={setCreateModalOpen}
            emptyMessage={t("user_api_keys:empty_message")}
            createMessageText={t("general:create_first_before")}
            createMessageActionText={t("general:create_first_link")}
          />
        )}
      </div>
      <UserApiKeysCreateModal
        visible={isCreateModalOpen}
        onCreate={handleCreateApiKey}
        onClose={setCreateModalClose}
      />

      {managedAPIKeyID !== null ? (
        <ManageApiKeyPropertiesModal
          apiKeyID={managedAPIKeyID}
          visible={isManagePropertiesModalOpen}
          onSave={handleSaveManagedProperties}
          onClose={handleManagePropertiesClose}
        />
      ) : null}
    </main>
  );
}
