import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";
import { features } from "config/feature_flags";

import { Actions } from "components/menu/actions";
import { removeMenuItem } from "components/menu/items/remove";

import useFeatures from "hooks/use_features";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import DeletePropertyModal from "../delete_property_button/delete_property_modal";

export default function PropertyActions({ property, onDelete }) {
  const { t } = useTranslation();
  const { id } = property;
  const appMode = useSelector(getAppMode);
  const { userAppRoutes } = useRouting();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatures();

  const isEmbeddedMode = appMode === APP_MODES.HEADLESS;

  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);
  const handleDeleteClick = () => {
    toggleModal();
  };

  const actionItems = [{
    key: "edit",
    onClick: () => navigate(pathBuilder(userAppRoutes.properties.edit, { propertyId: id })),
    label: t("general:action:edit"),
  }, {
    key: "logs",
    onClick: () => navigate(pathBuilder(userAppRoutes.tasks, { propertyId: id })),
    label: t("general:action:logs"),
  }];

  if (isFeatureEnabled(features.EXTRAS_MANAGEMENT)) {
    actionItems.push({
      key: "manage_extras",
      onClick: () => navigate(pathBuilder(userAppRoutes.properties.manageExtras, { propertyId: id })),
      label: t("property_extras:action:extras"),
    });
  }

  if (!isEmbeddedMode) {
    actionItems.push({ type: "divider" });
    actionItems.push(removeMenuItem({
      icon: false,
      confirm: false,
      onRemove: handleDeleteClick,
    }));
  }

  return (
    <>
      <Actions items={actionItems} />
      {!isEmbeddedMode && (
        <DeletePropertyModal
          property={property}
          visible={isModalOpen}
          onConfirm={onDelete}
          onClose={toggleModal}
        />
      )}
    </>
  );
}
