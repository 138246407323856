import React from "react";

import FormInput from "components/forms/inputs/formik/form_input";

export default function ({ t, schema, namespace, field, inputRef, disabled, handleFieldChange }) {
  return (
    <FormInput
      namespace={namespace}
      name={field}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      type={schema.type}
      inputRef={inputRef}
      disabled={disabled}
      onChange={handleFieldChange}
    />
  );
}
