import React, { Component } from "react";
import { FastField, Field } from "formik";
import _ from "lodash";

import FormInput from "components/forms/inputs/form_input";

class FormikFormInput extends Component {
  handleFieldChange = (value, field, form) => {
    const { onChange, type } = this.props;

    if (type) {
      const defaultParser = (fieldValue) => fieldValue;
      const parsers = {
        number: parseFloat,
      };

      const parser = parsers[type] || defaultParser;

      value = parser(value);
    }

    if (onChange) {
      return onChange(value, field, form);
    }

    return form.setFieldValue(field.name, value);
  };

  render() {
    const { name, namespace, fast = false } = this.props;

    const fieldName = namespace ? `${namespace}.${name}` : name;

    const FieldComponent = fast ? FastField : Field;

    return (
      <FieldComponent name={fieldName}>
        {({ field, form }) => {
          const isTouched = _.get(form.touched, name, false);

          return (
            <FormInput
              {...this.props}
              {...field}
              errors={isTouched && (form.errors[name] || form.status?.errors[name])}
              onChange={(value) => {
                this.handleFieldChange(value, field, form);
                form.setStatus({
                  ...(form.status || {}),
                  errors: _.omit(form.status?.errors, [name]),
                });
              }}
              onBlur={() => form.setFieldTouched(name, true)}
            />
          );
        }}
      </FieldComponent>
    );
  }
}

export default FormikFormInput;
