import { QueryError } from "components/errors/query_error";

import { Container } from "./container";

export const Error = () => {
  return (
    <Container itemHeight={300}>
      <QueryError />
    </Container>
  );
};
