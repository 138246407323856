import AGOIcon from "static/channel_icons/agoda.svg";
import ABBIcon from "static/channel_icons/airbnb.svg";
import ORSIcon from "static/channel_icons/atlantis-ors.svg";
import BDCIcon from "static/channel_icons/booking.svg";
import CTPIcon from "static/channel_icons/ctrip.svg";
import DDCIcon from "static/channel_icons/despegar.svg";
import DDAIcon from "static/channel_icons/dida-travel.svg";
import EXPIcon from "static/channel_icons/expedia.svg";
import GHAIcon from "static/channel_icons/google.svg";
import HOHIcon from "static/channel_icons/hostelhop.svg";
import HWLIcon from "static/channel_icons/hostelworld.svg";
import HBDIcon from "static/channel_icons/hotelbeds.svg";
import HTLIcon from "static/channel_icons/hotels-com.svg";
import HTNIcon from "static/channel_icons/hoteltonight.svg";
import MVRIcon from "static/channel_icons/moverii.svg";
import RAKIcon from "static/channel_icons/rakuten.svg";
import RSRIcon from "static/channel_icons/reserva.svg";
import ZDGIcon from "static/channel_icons/seminyak-villas.svg";
import SLRIcon from "static/channel_icons/sleep_rest.svg";
import TKTIcon from "static/channel_icons/tiket.svg";
import TRKIcon from "static/channel_icons/traveloka.svg";
import TRIIcon from "static/channel_icons/tripla.svg";

export default {
  AirBNB: {
    code: "AirBNB",
    shortCode: "ABB",
    title: "Airbnb",
    icon: ABBIcon,
    color: "#e0565b",
    supportMessages: true,
  },
  Agoda: {
    code: "Agoda",
    shortCode: "AGO",
    title: "Agoda",
    icon: AGOIcon,
    color: "#f59e22",
    supportMessages: false,
  },
  BookingCom: {
    code: "BookingCom",
    shortCode: "BDC",
    title: "Booking.com",
    icon: BDCIcon,
    color: "#0c3b7c",
    supportMessages: true,
  },
  BookingEngine: {
    code: "BookingEngine",
    shortCode: "IBE",
    title: "Booking Engine",
    icon: null,
    color: null,
    supportMessages: false,
  },
  ChannexBookingEngine: {
    code: "BookingEngine",
    shortCode: "CBE",
    title: "Booking Engine",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Expedia: {
    code: "Expedia",
    shortCode: "EXP",
    title: "Expedia",
    icon: EXPIcon,
    color: "#072f54",
    supportMessages: true,
  },
  HotelsCom: {
    code: "HotelsCom",
    shortCode: "HTL",
    title: "Hotels.com",
    icon: HTLIcon,
    color: "#ee2842",
    supportMessages: false,
  },
  Hostelworld: {
    code: "Hostelworld",
    shortCode: "HWL",
    title: "Hostelworld",
    icon: HWLIcon,
    color: "#f25621",
    supportMessages: false,
  },
  GoogleHotelAds: {
    code: "GoogleHotelAds",
    shortCode: "GHA",
    title: "Google Hotel Ads",
    icon: GHAIcon,
    color: null,
    supportMessages: false,
  },
  GoogleMyHotelShop: {
    code: "GoogleMyHotelShop",
    shortCode: "MHS",
    title: "Google via My Hotel Shop",
    icon: null,
    color: null,
    supportMessages: false,
  },
  CTrip: {
    code: "CTrip",
    shortCode: "CTP",
    title: "CTrip",
    icon: CTPIcon,
    color: "#3773ba",
    supportMessages: false,
  },
  Rakuten: {
    code: "Rakuten",
    shortCode: "RAK",
    title: "Rakuten",
    icon: RAKIcon,
    color: "#bf0000",
    supportMessages: false,
  },
  HotelTonight: {
    code: "HotelTonight",
    shortCode: "HTN",
    title: "HotelTonight",
    icon: HTNIcon,
    color: "#2d1641",
    supportMessages: false,
  },
  Traveloka: {
    code: "Traveloka",
    shortCode: "TRK",
    title: "Traveloka",
    icon: TRKIcon,
    color: null,
    supportMessages: false,
  },
  VerticalBooking: {
    code: "VerticalBooking",
    shortCode: "VB",
    title: "Vertical Booking",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Wubook: {
    code: "Wubook",
    shortCode: "WBK",
    title: "Wubook",
    icon: null,
    color: null,
    supportMessages: false,
  },
  OpenChannel: {
    code: "OpenChannel",
    shortCode: "OC",
    title: "Open Channel",
    icon: null,
    color: null,
    supportMessages: false,
  },
  RoomCloud: {
    code: "RoomCloud",
    shortCode: "RC",
    title: "RoomCloud",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HyperGuest: {
    code: "HyperGuest",
    shortCode: "HG",
    title: "HyperGuest",
    icon: null,
    color: null,
    supportMessages: false,
  },
  LocalOTA: {
    code: "LocalOTA",
    shortCode: "LO",
    title: "LocalOTA",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Garbage: {
    code: "Garbage",
    title: "Garbage",
    icon: null,
    color: null,
    shortCode: null,
    supportMessages: false,
  },
  GoogleHotelARI: {
    code: "GoogleHotelARI",
    title: "GoogleHotelARI",
    icon: null,
    color: null,
    shortCode: "GHA",
    supportMessages: false,
  },
  OpenShopping: {
    code: "OpenShopping",
    shortCode: "OSA",
    title: "Instant Booking Page",
    icon: null,
    color: null,
    supportMessages: false,
  },
  OpenGDS: {
    code: "OpenGDS",
    shortCode: "GDS",
    title: "OpenGDS",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HeyIceland: {
    code: "HeyIcalend",
    shortCode: "HIC",
    title: "Hey Iceland",
    icon: null,
    color: null,
    supportMessages: false,
  },
  CultBooking: {
    code: "CultBooking",
    shortCode: "CTZ",
    title: "CultBooking",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Cultbooking: {
    code: "Cultbooking",
    shortCode: "CTZ",
    title: "CultBooking",
    icon: null,
    color: null,
    supportMessages: false,
  },
  TheSquare: {
    code: "TheSquare",
    shortCode: "TSQ",
    title: "The Square",
    icon: null,
    color: null,
    supportMessages: false,
  },
  AdvertisingOnline: {
    code: "AdvertisingOnline",
    shortCode: "ADO",
    title: "Advertising Online",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Hotelbeds: {
    code: "Hotelbeds",
    shortCode: "HBD",
    title: "Hotelbeds",
    icon: HBDIcon,
    color: null,
    supportMessages: false,
  },
  MemberButton: {
    code: "MemberButton",
    shortCode: "MMB",
    title: "MemberButton",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HelpingHotels: {
    code: "HelpingHotels",
    shortCode: "HHT",
    title: "Helping Hotels",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Reserva: {
    code: "Reserva",
    shortCode: "RSR",
    title: "Reserva",
    icon: RSRIcon,
    color: null,
    supportMessages: false,
  },
  ReservaCars: {
    code: "ReservaCars",
    shortCode: "RSC",
    title: "ReservaCars",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Despegar: {
    code: "Despegar",
    shortCode: "DDC",
    title: "Despegar",
    icon: DDCIcon,
    color: null,
    supportMessages: false,
  },
  AbodeConnect: {
    code: "AbodeConnect",
    shortCode: "ACT",
    title: "Abode Connect",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Webrooms: {
    code: "Webrooms",
    shortCode: "WBR",
    title: "Webrooms",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Inntopia: {
    code: "Inntopia",
    shortCode: "ITP",
    title: "Inntopia",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Travia: {
    code: "Travia",
    shortCode: "TVA",
    title: "Travia",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Bakuun: {
    code: "Bakuun",
    shortCode: "BKN",
    title: "Bakuun",
    icon: null,
    color: null,
    supportMessages: false,
  },
  stayforRewards: {
    code: "stayforRewards",
    shortCode: "SFR",
    title: "stayforRewards",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Spot2Nite: {
    code: "Spot2Nite",
    shortCode: "S2N",
    title: "Spot2Nite",
    icon: null,
    color: null,
    supportMessages: false,
  },
  VRBO: {
    code: "VRBO",
    shortCode: "VRB",
    title: "Homeaway / VRBO",
    icon: null,
    color: null,
    supportMessages: false,
  },
  ChannexExpress: {
    code: "ChannexExpress",
    shortCode: "CEX",
    title: "Channex Express",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Tripla: {
    code: "Tripla",
    shortCode: "TRI",
    title: "Tripla",
    icon: TRIIcon,
    color: null,
    supportMessages: false,
  },
  OTASync: {
    code: "OTASync",
    shortCode: "OSC",
    title: "OTASync",
    icon: null,
    color: null,
    supportMessages: false,
  },
  MitchellCorp: {
    code: "MitchellCorp",
    shortCode: "MCP",
    title: "Mitchell Corp",
    icon: null,
    color: null,
    supportMessages: false,
  },
  TiketCom: {
    code: "TiketCom",
    shortCode: "TKT",
    title: "Tiket.com",
    icon: TKTIcon,
    color: "fedd00",
    supportMessages: false,
  },
  MGBedbank: {
    code: "MGBedbank",
    shortCode: "MGK",
    title: "MG Bedbank",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Feratel: {
    code: "Feratel",
    shortCode: "FER",
    title: "Feratel",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Travelgatex: {
    code: "Travelgatex",
    shortCode: "TGX",
    title: "TravelGateX",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Goibibo: {
    code: "Goibibo",
    shortCode: "GMT",
    title: "Make My Trip",
    icon: null,
    color: null,
    supportMessages: false,
  },
  DreamIreland: {
    code: "DreamIreland",
    shortCode: "DMI",
    title: "Dream Ireland",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Unknown: {
    code: "Unknown",
    shortCode: "UNK",
    title: "Unknown",
    icon: null,
    color: "#aaa",
    supportMessages: false,
  },
  HRS: {
    code: "HRS",
    shortCode: "HRS",
    title: "HRS",
    icon: null,
    color: null,
    supportMessages: false,
  },
  OmniHotelier: {
    code: "OmniHotelier",
    shortCode: "OBE",
    title: "OmniHotelier",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Guestaps: {
    code: "Guestaps",
    shortCode: "GAS",
    title: "GuestPro",
    icon: null,
    color: null,
    supportMessages: false,
  },
  AtlantisORS: {
    code: "AtlantisORS",
    shortCode: "ORS",
    title: "Atlantis ORS",
    icon: ORSIcon,
    color: "#1c3b6f",
    supportMessages: false,
  },
  Hipcamp: {
    code: "Hipcamp",
    shortCode: "HPC",
    title: "Hipcamp",
    icon: null,
    color: null,
    supportMessages: false,
  },
  ACEBookingEngine: {
    code: "ACEBookingEngine",
    shortCode: "ACE",
    title: "ACE Booking Engine",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Wihp: {
    code: "Wihp",
    title: "Wihp",
    icon: null,
    color: null,
    shortCode: "WHP",
    supportMessages: false,
  },
  PriceTravel: {
    code: "PriceTravel",
    title: "PriceTravel",
    icon: null,
    color: null,
    shortCode: "PTL",
    supportMessages: false,
  },
  Lido: {
    code: "Lido",
    shortCode: "LDO",
    title: "HRS Australia / Lido",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HotelNetwork: {
    code: "HotelNetwork",
    title: "The Hotel Network",
    icon: null,
    color: null,
    shortCode: "THN",
    supportMessages: false,
  },
  Getaroom: {
    code: "Getaroom",
    title: "Getaroom",
    icon: null,
    color: null,
    shortCode: "GAM",
    supportMessages: false,
  },
  PegiPegi: {
    code: "PegiPegi",
    shortCode: "PGI",
    title: "PegiPegi",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Ostrovok: {
    code: "Ostrovok",
    shortCode: "OVK",
    title: "Emerging Travel Group",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HotelPoint: {
    code: "HotelPoint",
    shortCode: "HPT",
    title: "Hotel Point",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Szallas: {
    code: "Szallas",
    shortCode: "SZS",
    title: "Szallas",
    icon: null,
    color: null,
    supportMessages: false,
  },
  MrAndMrsSmith: {
    code: "MrAndMrsSmith",
    shortCode: "MMS",
    title: "Mr & Mrs Smith",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Wink: {
    code: "Wink",
    shortCode: "WNK",
    title: "Wink",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Webready: {
    code: "Webready",
    shortCode: "WRD",
    title: "Webready",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Hookusbookus: {
    code: "Hookusbookus",
    shortCode: "HBS",
    title: "Hookusbookus",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Bookeasy: {
    code: "Bookeasy",
    title: "Bookeasy",
    icon: null,
    color: null,
    shortCode: "BKE",
    supportMessages: false,
  },
  Hopper: {
    code: "Hopper",
    shortCode: "HOP",
    title: "Hopper",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HopperHomes: {
    code: "HopperHomes",
    shortCode: "HOPH",
    title: "Hopper Homes",
    icon: null,
    color: null,
    supportMessages: false,
  },
  RoomBeast: {
    code: "RoomBeast",
    shortCode: "RMB",
    title: "RoomBeast",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Reconline: {
    code: "Reconline",
    shortCode: "RCL",
    title: "Reconline",
    icon: null,
    color: null,
    supportMessages: false,
  },
  STC: {
    code: "STC",
    shortCode: "STC",
    title: "Switzerland Travel Centre",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Roibos: {
    code: "Roibos",
    shortCode: "RBS",
    title: "Roibos",
    icon: null,
    color: null,
    supportMessages: false,
  },
  HostelHop: {
    code: "HostelHop",
    shortCode: "HOH",
    title: "Hostel Hop",
    icon: HOHIcon,
    color: null,
    supportMessages: false,
  },
  BookOutdoors: {
    code: "BookOutdoors",
    shortCode: "BOD",
    title: "BookOutdoors",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Hoterip: {
    code: "Hoterip",
    shortCode: "HTP",
    title: "Hoterip",
    icon: null,
    color: null,
    supportMessages: false,
  },
  DidaTravel: {
    code: "DidaTravel",
    shortCode: "DDA",
    title: "DidaTravel",
    icon: DDAIcon,
    color: "e70a48",
    supportMessages: false,
  },
  TheTPMGroup: {
    code: "TheTPMGroup",
    shortCode: "TPM",
    title: "The TPM Group",
    icon: null,
    color: null,
    supportMessages: false,
  },
  SleepRest: {
    code: "SleepRest",
    shortCode: "SLR",
    title: "SleepRest",
    icon: SLRIcon,
    color: "1c5dd8",
    supportMessages: false,
  },
  Moverii: {
    code: "Moverii",
    shortCode: "MVR",
    title: "Moverii",
    icon: MVRIcon,
    color: "ffffff",
    supportMessages: false,
  },
  TabletHotels: {
    code: "TabletHotels",
    shortCode: "TBH",
    title: "TabletHotels",
    icon: null,
    color: null,
    supportMessages: false,
  },
  SeminyakVillas: {
    code: "SeminyakVillas",
    shortCode: "ZDG",
    title: "seminyak.villas",
    icon: ZDGIcon,
    color: null,
    supportMessages: false,
  },
  SissaeLiving: {
    code: "SissaeLiving",
    shortCode: "SLC",
    title: "Sissae Living",
    icon: null,
    color: null,
    supportMessages: false,
  },
  B2BGlobal: {
    code: "B2BGlobal",
    shortCode: "GLB",
    title: "B2B Global",
    icon: null,
    color: null,
    supportMessages: false,
  },
  RVPARKGURU: {
    code: "RVPARKGURU",
    shortCode: "RPG",
    title: "RVPARKGURU",
    icon: null,
    color: null,
    supportMessages: false,
  },
  Bookola: {
    code: "Bookola",
    shortCode: "BKL",
    title: "Bookola",
    icon: null,
    color: null,
    supportMessages: false,
  },
  DespegarV2: {
    code: "DespegarV2",
    shortCode: "DDC",
    title: "DespegarV2",
    icon: DDCIcon,
    color: null,
    supportMessages: false,
  },
};
