const CURRENCY_RESTRICTED_CHANNELS = [
  "Agoda",
  "BookingCom",
  "Despegar",
  "Expedia",
  "Goibibo",
  "HyperGuest",
  "Inntopia",
  "MGBedbank",
  "TicketCom",
  "Travelgatex",
];

export default CURRENCY_RESTRICTED_CHANNELS;
