import React, { memo } from "react";
import _ from "lodash";

import { MappingDeleteButton } from "components/mapping/mapping_row/mapping_delete_button";

import { MappingRow } from "../mapping_row";

import styles from "../styles.module.css";

export const ListingMappingRow = memo(({ mappingItem, mappingSchema, MappingEditor, onChange, onDelete }) => {
  const { mapping, properties, roomTypes, ratePlans, listing, isInvalid } = mappingItem;

  const propertiesById = _.keyBy(properties, "id");
  const roomTypesById = _.keyBy(roomTypes, "id");
  const ratePlansById = _.keyBy(ratePlans, "id");

  return (
    <MappingRow
      isError={isInvalid}
      showMapping
      source={(
        <div>
          {listing.title}
        </div>
      )}
      target={(
        <div className={styles.column__withActions}>
          <div className={styles.columnContent}>
            <MappingEditor
              isInvalid={isInvalid}
              mapping={mapping}
              listing={listing}
              propertiesById={propertiesById}
              roomTypesById={roomTypesById}
              ratePlansById={ratePlansById}
              mappingSchema={mappingSchema}
              onChange={(newMapping) => {
                onChange({ listing, mapping: newMapping, prevMapping: mapping });
              }}
              onDelete={() => onDelete({ mapping })}
            />
          </div>
          <div className={styles.columnActions}>
            <MappingDeleteButton
              visible={!!mapping}
              onClick={() => onDelete({ mapping })}
            />
          </div>
        </div>
      )}
    />
  );
});
