import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Space } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import dayjs from "utils/dayjs";

import AssignBillingAccountModal from "./components/assign_billing_account_modal";

import ErrorImage from "static/too-many-properties.svg";
import propertiesSubPageStyles from "./properties_sub_page.module.css";
import generalStyles from "styles/general.module.css";

const { AdminProperties } = store;
const DROPDOWN_TRIGGER = ["click"];

class PropertiesSubPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    columnsToHide: 0,
    tableQuery: "",
    error: null,
    showAssignBillingAccountModal: null,
    filter: {},
  };

  tableRef = React.createRef();

  actions = (_text, record) => {
    const { t, routes, navigate } = this.props;

    const items = [{
      key: "crud_admin_entry_edit_action",
      onClick: () => navigate(pathBuilder(routes.userAppRoutes.admin.properties.edit, { propertyId: record.id })),
      label: (
        <div data-cy="crud_admin_entry_edit_action">
          {t("general:action:edit")}
        </div>
      ),
    }, {
      key: "properties_sub_page_actions:assign_billing_account",
      onClick: this.onAssignBillingAccount(record.id, record.billing_account_id),
      label: (
        <div>
          {t("properties_sub_page:actions:assign_billing_account")}
        </div>
      ),
    }];

    if (record.property_type_group !== "hotel") {
      items.push({
        key: "properties_sub_page:actions_set_as_hotel",
        onClick: this.onSetAsHotel(record.id),
        label: (
          <div>
            {t("properties_sub_page:actions:set_as_hotel")}
          </div>
        ),
      });
    }

    if (record.property_type_group !== "vacation_rental") {
      items.push({
        key: "properties_sub_page:actions_set_as_vacation_rental",
        onClick: this.onSetAsVacationRental(record.id),
        label: (
          <div>
            {t("properties_sub_page:actions:set_as_vacation_rental")}
          </div>
        ),
      });
    }

    return (
      <Dropdown
        placement="bottomRight"
        menu={{ items }}
        trigger={DROPDOWN_TRIGGER}
      >
        <a
          data-cy="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(event) => event.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  loadData = (query, pagination, order) => {
    const { filter } = this.state;
    const queryFilter = { ...filter, title: { has: query } };

    return AdminProperties.list(queryFilter, pagination, order).then(
      (response) => response,
      (error) => {
        this.setState({ error: error.errors.code });
        return error;
      },
    );
  };

  onAssignBillingAccount = (propertyId, billingAccountId) => {
    return () => {
      this.setState({
        showAssignBillingAccountModal: {
          propertyId,
          billingAccountId,
        },
      });
    };
  };

  onSetAsHotel = (propertyId) => {
    return () => {
      AdminProperties.update({
        id: propertyId,
        property_type_group: "hotel",
      });
    };
  };

  onSetAsVacationRental = (propertyId) => {
    return () => {
      AdminProperties.update({
        id: propertyId,
        property_type_group: "vacation_rental",
      });
    };
  };

  onCloseAssignBillingAccount = () => {
    return () => {
      this.setState({
        showAssignBillingAccountModal: null,
      });
    };
  };

  onSaveAssignBillingAccount = () => {
    return (data) => {
      AdminProperties.update({
        id: data.propertyId,
        billing_account_id: data.billingAccountId,
      }).then(() => {
        this.setState({
          showAssignBillingAccountModal: null,
        });
      });
    };
  };

  columns = () => {
    const { t, routes } = this.props;

    let dataColumns = [
      {
        title: t("properties_sub_page:columns:title"),
        dataIndex: "title",
        key: "title",
        sorter: true,
      },
      {
        title: t("properties_sub_page:columns:property_type"),
        dataIndex: "property_type_group",
        key: "property_type_group",
        sorter: true,
        render: (value) => {
          return t(`properties_sub_page:property_types:${value}`);
        },
      },
      {
        title: t("properties_sub_page:columns:inserted_at"),
        dataIndex: "inserted_at",
        key: "inserted_at",
        sorter: true,
        render: (value, _entity) => {
          return dayjs(value).format("YYYY-MM-DD");
        },
      },
      {
        title: t("properties_sub_page:columns:owner_name"),
        dataIndex: "owner.name",
        key: "owner.name",
        sorter: true,
        render: (_value, entity) => {
          return entity?.owner?.name;
        },
        ...this.getColumnSearchProps("owner.name"),
      },
      {
        title: t("properties_sub_page:columns:owner_email"),
        dataIndex: "owner.email",
        key: "owner.email",
        sorter: true,
        render: (_value, entity) => {
          return entity?.owner?.email;
        },
        ...this.getColumnSearchProps("owner.email"),
      },
      {
        title: t("properties_sub_page:columns:billing_account"),
        dataIndex: "billing_account.company_name",
        key: "billing_account.company_name",
        sorter: true,
        render: (_value, entity) => {
          const billing_account = entity.relationships.billing_account?.data?.attributes;
          let value = null;

          if (billing_account) {
            value = (
              <Link
                to={pathBuilder(routes.userAppRoutes.admin.properties.billing_account_edit, {
                  billingAccountId: billing_account.id,
                })}
                data-cy="crud_admin_billing_account_edit_action"
              >
                {billing_account.company_name}
              </Link>
            );
          }

          return value;
        },
        ...this.getColumnSearchProps("billing_account.company_name"),
      },
    ];

    const actionColumns = [
      {
        className: propertiesSubPageStyles.columnActions,
        title: t("properties_sub_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions,
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("bookings_page:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { routes } = this.props;

    const { tableQuery } = this.state;
    const basePath = pathBuilder(routes.userAppRoutes.admin.properties);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  getColumnSearchProps = (dataIndex) => {
    const { filter } = this.state;
    const { t } = this.props;
    const value = filter[dataIndex];

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("general:crud_table:filter:search")}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("general:crud_table:filter:search")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              {t("general:crud_table:filter:reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              {t("general:crud_table:filter:filter")}
            </Button>
          </Space>
        </div>
      ),
      filteredValue: value,
    };
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    const { filter } = this.state;
    filter[dataIndex] = selectedKeys[0];
    this.setState({ filter });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    const { filter } = this.state;
    delete filter[dataIndex];
    this.setState({ filter });
    clearFilters();
  };

  render() {
    const { data, t } = this.props;
    const closePath = this.getClosePath();
    const { error, showAssignBillingAccountModal } = this.state;

    if (error) {
      return <Page.ErrorMessage text={t(`users_sub_page:errors:${error}`)} icon={ErrorImage} />;
    }

    return (
      <>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(4)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(3)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable.Container>
          <CRUDTable
            data={data}
            componentRef={this.tableRef}
            emptyMessage={this.emptyMessage()}
            showCreateMessage={false}
            onTablePramsChange={this.handleTableQueryChange}
            columns={this.columns}
            loadData={this.loadData}
            defaultOrder={{ title: "asc" }}
            showTableWithoutResults
            internalLoading
          />
        </CRUDTable.Container>

        <Outlet context={{ closePath }} />

        {showAssignBillingAccountModal && (
          <AssignBillingAccountModal
            propertyId={showAssignBillingAccountModal.propertyId}
            billingAccountId={showAssignBillingAccountModal.billingAccountId}
            onSave={this.onSaveAssignBillingAccount()}
            onClose={this.onCloseAssignBillingAccount()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ adminProperties }) => {
  const { entities } = adminProperties || { entities: null };

  return {
    data: entities ? Object.values(entities) : null,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(PropertiesSubPage)));
