import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Tooltip } from "antd";

import ChannexPopover from "components/channex_popover";

import styles from "./photo_card_meta.module.css";

class PhotoCardMeta extends Component {
  state = {
    popoverIsOpen: false,
  };

  togglePopover = () => {
    this.setState(({ popoverIsOpen }) => ({
      popoverIsOpen: !popoverIsOpen,
    }));
  };

  renderMeta = () => {
    const { t, children, loading } = this.props;

    if (loading) {
      const descriptionClassName = [styles.description, styles.descriptionEmpty].join(" ");

      return <div className={descriptionClassName}>{t("general:loading")}</div>;
    }

    return <div className={styles.description}>{children}</div>;
  };

  renderEditableMeta = () => {
    const { tooltipText, editForm } = this.props;
    const { popoverIsOpen } = this.state;

    const updatedForm = React.cloneElement(editForm, { onClose: this.togglePopover });

    return (
      <ChannexPopover open={popoverIsOpen} content={updatedForm}>
        <Tooltip title={tooltipText}>
          <div onClick={this.togglePopover}>{this.renderMeta()}</div>
        </Tooltip>
      </ChannexPopover>
    );
  };

  render() {
    const { editable } = this.props;

    return editable ? this.renderEditableMeta() : this.renderMeta();
  }
}

export default withTranslation()(PhotoCardMeta);
