import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import classifyApiErrors from "utils/classify_api_errors";
import { nullifyEmptyValues } from "utils/nullify_empty_values";
import channexApiErrorParser from "utils/parse_api_errors";

export default function useAppForm(options) {
  const {
    validationSchema,
    defaultValue,
    fieldNames = [],
    apiErrorParser,
    submitHandler = () => {},
    resetAfterSubmit = false,
    nullifyEmptyFields = false,
    ensureFieldsPresent = false,
    errors,
    shouldUnregister = false,
    toForm = (v) => v,
    fromForm = (v) => v,
  } = options;

  const {
    handleSubmit: originalHandleSubmit,
    formState,
    clearErrors,
    setError,
    control,
    resetField,
    setValue,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: toForm(defaultValue),
    errors,
    shouldUnregister,
  });

  const handleSubmit = () => {
    clearErrors();

    originalHandleSubmit(async (values) => {
      try {
        let normalizedValues = values;

        if (nullifyEmptyFields) {
          normalizedValues = nullifyEmptyValues(values, nullifyEmptyFields);
        }

        if (ensureFieldsPresent) {
          fieldNames.forEach((fieldName) => {
            if (!_.get(normalizedValues, fieldName)) {
              _.set(normalizedValues, fieldName, null);
            }
          });
        }

        normalizedValues = fromForm(normalizedValues);

        await submitHandler(normalizedValues);

        if (resetAfterSubmit) {
          reset();
        }
      } catch (error) {
        if (!error.isValidationError) {
          throw error;
        }

        let parsedErrors;

        if (apiErrorParser) {
          parsedErrors = apiErrorParser(error);
        } else {
          parsedErrors = channexApiErrorParser(error, { camelCaseFields: true });
        }

        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, fieldNames);

        if (Object.keys(globalErrors).length !== 0) {
          setError("root.global", {
            errors: globalErrors,
          });
        }

        Object.entries(formErrors).forEach(([fieldName, errorMessage]) => {
          setError(fieldName, {
            type: "api",
            message: errorMessage,
          });
        });
      }
    })();
  };

  return {
    handleSubmit,
    setValue,
    errors: formState.errors,
    dirtyFields: formState.dirtyFields,
    isValid: formState.isValid,
    clearErrors,
    setError,
    control,
    resetField,
    watch,
    reset,
    trigger,
  };
}
