import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Switch } from "antd";

import styles from "./mapping_list_settings.module.css";
import generalStyles from "styles/general.module.css";

class MappingListSettings extends Component {
  handleToggleMappingModeClick = () => {
    const { mappingSettings, onChangeSettings } = this.props;

    onChangeSettings({
      ...mappingSettings,
      reverseMapping: !mappingSettings.reverseMapping,
    });
  };

  buildMenu = () => {
    const { mappingSettings } = this.props;
    const { reverseMapping } = mappingSettings || {};

    return (
      <Menu>
        <div className={styles.menuItem}>
          <Switch
            size="small"
            checked={reverseMapping}
            onClick={this.handleToggleMappingModeClick}
          />
          &nbsp;Flip View
        </div>
      </Menu>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.settingsContainer}>
        <Dropdown overlay={this.buildMenu()} trigger={["click"]}>
          <a
            className={generalStyles.actionsToggle}
            href="#actions"
            onClick={(event) => event.preventDefault()}
          >
            <SettingOutlined className={styles.settingsIcon} />
            {t("channels_page:form:settings")} <DownOutlined />
          </a>
        </Dropdown>
      </div>
    );
  }
}

export default withTranslation()(MappingListSettings);
