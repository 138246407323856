import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "antd";

import { ReactQueryCrudTable } from "components/crud_table/react_query";
import { Actions } from "components/menu/actions";
import { removeMenuItem } from "components/menu/items/remove";

import useDrawer from "hooks/use_drawer";

import { CreateDrawer } from "./create_drawer/create_drawer";
import { EditDrawer } from "./edit_drawer/edit_drawer";
import { useAdminWlPartners } from "./use_admin_wl_partners";
import { useDeleteAction } from "./use_delete_action";

export function WhiteLabelPartnersSubPage() {
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState();
  const { isOpen: isCreateDrawerOpen, open: openCreateDrawer, close: closeCreateDrawer } = useDrawer();
  const { payload: editDrawerPayload, isOpen: isEditDrawerOpen, open: openEditDrawer, close: closeEditDrawer } = useDrawer();
  const { mutateAsync: deleteAction } = useDeleteAction();

  const query = useAdminWlPartners(tableParams);

  const handleEditClick = (record) => () => {
    openEditDrawer(record);
  };

  const columns = () => {
    return [{
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
    }, {
      title: "Contact",
      dataIndex: "contact_email",
      key: "contact_email",
      render: (_value, record) => {
        const name = record.contactName;
        const email = record.contactEmail;

        let result = name;

        if (email) {
          result = `${result} (${email})`;
        }

        return result;
      },
    }, {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      sorter: true,
      render: (value) => {
        return <Badge status={value === "active" ? "success" : "error"} />;
      },
    }, {
      title: "Actions",
      key: "action",
      align: "right",
      width: 100,
      render: (_value, record) => (
        <Actions
          items={[{
            key: "edit",
            onClick: handleEditClick(record),
            label: t("general:action:edit"),
          },
          removeMenuItem({
            onRemove: () => deleteAction({ id: record.id }),
          }),
          ]}
        />
      ),
    }];
  };

  return (
    <>
      <ReactQueryCrudTable
        columns={columns}
        query={query}
        onCreateClick={openCreateDrawer}
        onTableParamsChange={setTableParams}
      />
      <CreateDrawer
        visible={isCreateDrawerOpen}
        onClose={closeCreateDrawer}
      />
      <EditDrawer
        visible={isEditDrawerOpen}
        whiteLabelPartner={editDrawerPayload}
        onClose={closeEditDrawer}
      />
    </>
  );
}
