import React from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormInputNumberRaw from "components/forms/inputs/form_input_number";

export default function FormInputNumber({ name, label, errors, control, grouped, ...props }) {
  const input = (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormInputNumberRaw
          grouped={grouped}
          hookForm
          label={label}
          errors={errors}
          {...props}
          {...field}
        />
      )}
    />
  );

  if (grouped) {
    return input;
  }

  return (
    <Row>
      <Col span={24}>
        {input}
      </Col>
    </Row>
  );
}
